import * as types from '../../../../actions/action-types'


const initialState = {
  SearchResultData: {
    rows: [],
    search_title: '',
    filter: '',
    activePage: 1,
    totalPages: -1,
    itemsPerPage: 9,
    search_options: [],
    in_progress: false,
  }
}


const SearchResultReducer = function(state=initialState, action) {
  switch(action.type) {

    case types.SET_SEARCH_RESULT_ROWS:
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          rows: action.data
        })
      });

    case types.SET_SEARCH_RESULT_TITLE:
      // console.log(action.data)
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          search_title: action.data
        })
      });

    case types.SET_SEARCH_RESULT_FILTER:
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          filter: action.data
        })
      });

    case types.SET_SEARCH_RESULT_ACTIVE_PAGE:
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          activePage: action.data
        })
      });

    case types.SET_SEARCH_RESULT_TOTAL_PAGES:
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          totalPages: action.data
        })
      });

    case types.SET_SEARCH_QUICK_SEARCH_OPTIONS:
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          search_options: action.data
        })
      });

    case types.SET_SEARCH_RESULT_IN_PROGRESS:
      return Object.assign({}, state, {
        SearchResultData: Object.assign({}, state.SearchResultData, {
          in_progress: action.data
        })
      });

    default: return state;
  }
}


export default SearchResultReducer;
