import React, { Component } from 'react';

import { Dropdown, Icon, Image, List, Menu } from 'semantic-ui-react'


const style = {
  // position: 'fixed',
  backgroundColor: '#002a3a',
  // top: '0px',
  // left: '0px',
  width: '100%',
  zIndex: 100
}

class MobileOrderStepsView extends Component {

  render () {

    const bag = this.props.items.filter(item => item.step === 'basket');
    if (bag.length === 1) {
      var quantity= `(${bag['0'].description})`
    } else {
      quantity= '';
    }

    return (
      <Menu borderless style={style} icon={'labeled'} >
        <Dropdown item icon={'bars'} simple color={'white'}
          style={{backgroundColor: 'white'}}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.props.menuClick('')} >
              <Icon name={'bars'} />
              {'Стартовое меню'}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.props.menuClick('search')}>
              <Icon name={'search'} />
              {'Поиск по прайсу'}</Dropdown.Item>
            <Dropdown.Item onClick={this.props.menuClick('catalogue1')}>
              <Icon name={'truck'} />
              {'Оригинальные запчасти'}</Dropdown.Item>
            <Dropdown.Item onClick={this.props.menuClick('catalogue2')}>
              <Icon name={'wrench'} />
              {'Каталоги ТО'}</Dropdown.Item>
            <Dropdown.Item onClick={this.props.menuClick('basket')}>
              <Icon name={'shopping basket'} />
              {'Корзина'}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.props.menuClick('orderstate')}>
              <Icon name={'info circle'} />
              {'Проверка заказа'}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item>
          <Image style={{cursor: 'pointer'}} src='/images/logo.png' size='small' onClick={this.props.menuClick('')} />
        </Menu.Item>
        <Menu.Item>
          <List>
            <List.Item >
              <List.Content>
                <div style={{color: '#ffffff', textAlign: 'left'}}>
                  <Icon style={{color: '#ffffff'}} name='phone' position={'left'} />
                  {'+7 (910) 164-46-56'}
                </div>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <div style={{color: '#ffffff', textAlign: 'left'}}>
                  <Icon style={{color: '#ffffff'}} name='envelope' position={'left'} />
                  <a style={{color: '#ffffff'}} href={'mailto:texkomplus71@yandex.ru'}>{'texkomplus71@yandex.ru'}</a>
                </div>
              </List.Content>
            </List.Item>
          </List>
        </Menu.Item>

        <Menu.Menu position='right' icon='labeled' >
          <Menu.Item style={{color: '#ffffff'}}
              onClick={this.props.menuClick('basket')} >
            <Icon position={'top'} size={'big'} name={'shopping basket'} />
            {quantity === '(0 поз.)' ? 'Корзина' : `Оформить ${quantity}`}
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )
  }
}


export default MobileOrderStepsView;
