import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid } from 'semantic-ui-react'

import GroupList from './groupelist/container';
// import SearchResult from './searchresult/container';
import SearchResult from '../categorysearch/searchresult/container';

import store from '../../../store';
import * as ws from '../../../api/connections';
import {  setSearchResultRows,
          setSearchResultTotalPages,
          setSearchResultActivePage } from '../categorysearch/searchresult/actions';


class GroupSearch extends Component {

  componentDidMount = () => {
    ws.doSend({command: 'getDetailGroups'});
    ws.doSend({
      command: 'getAdditionalGroups',
      params: {}
    });
    store.dispatch(setSearchResultRows([]));
    store.dispatch(setSearchResultTotalPages(1));
    store.dispatch(setSearchResultActivePage(1));
  }

  render () {
    return (
      <div style={{height: this.props.height}}>
        <Grid divided>
          <Grid.Column
            width={// eslint-disable-next-line
              this.props.data.hide_group_list && 1 || 4}>
            <GroupList visible={!this.props.data.hide_group_list} />
          </Grid.Column>
          <Grid.Column
            width={// eslint-disable-next-line
              this.props.data.hide_group_list && 15 || 12}>
            <SearchResult height={38} />
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
  }
}

export default connect(mapStateToProps)(GroupSearch);
