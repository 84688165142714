import React from 'react';
import { connect } from 'react-redux';

import { Tab } from 'semantic-ui-react';

// import TehkomSearch from '../catalogue/tehkomsearch/container';

import CategorySearch from './categorysearch/container';
import GroupSearch from './groupsearch/container';
import FullSearch from './fullsearch/container';

import SearchView from './component';

import store from '../../store';
import { setAppSearchActiveIndex } from '../app/actions/actions';


const panes = [
  {menuItem: 'Поиск по всему прайсу', render: () => {}},
  {menuItem: 'Быстрый поиск по моделям', render: () => {}, active: true},
  {menuItem: 'Поиск по группам прайса', render: () => {}}
];


class Search extends React.Component {

  componentDidMount() {
  }

  makeContent = panes => panes.map(pane => {
    switch (pane.menuItem) {
      case 'Поиск по всему прайсу':
        pane.render = () => <Tab.Pane attached={false}><div><FullSearch /></div></Tab.Pane>
        break;
      case 'Быстрый поиск по моделям':
        pane.render = () => <Tab.Pane attached={false}><CategorySearch /></Tab.Pane>
        break;
      case 'Поиск по группам прайса':
        pane.render = () => <Tab.Pane attached={false}><GroupSearch /></Tab.Pane>
        break;
      default: break;
    }
    return pane;
  });

  _onTabChange = (e, {activeIndex}) => {
    store.dispatch(setAppSearchActiveIndex(activeIndex));
  };

  render() {
    return (
      <SearchView
        panes={this.makeContent(panes)}
        activeIndex={this.props.data.search_active_index}
        onTabChange={this._onTabChange} />
    );
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
  }
}

export default connect(mapStateToProps)(Search);

