import * as types from '../../actions/action-types';


export function setModalViewerOpen(data) {
  return {
    type: types.SET_MODAL_VIEWER_OPEN,
    data
  };
}


export function setModalViewerFilename(data) {
  return {
    type: types.SET_MODAL_VIEWER_FILE_NAME,
    data
  };
}


export function setModalViewerTitle(data) {
  return {
    type: types.SET_MODAL_VIEWER_TITLE,
    data
  };
}
