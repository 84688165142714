import React, { Component } from 'react';
import { connect } from 'react-redux';

import ServicesView from './component';
import * as ws from '../../../api/connections';
// import store from '../../../store';


class Services extends Component {

  componentDidMount = () => {
    ws.doSend({
      command: 'getSettingsParamsValue',
      params: {
        params: ['payment_online', 'payment_offline',
                 'email_notify', 'mailing_list',
                 'delivery_pickup', 'delivery_delivery', 'delivery_company',
                 'chat_enable', ],
      }
    });
  };

  _onServiceChange = service => (e, {checked}) => {
    if (service === 'email') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'email_notify',
            value: `${checked}`,
          }
        }
      })
    }

    if (service === 'payment_online') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'payment_online',
            value: `${checked}`,
          }
        }
      })
    }

    if (service === 'payment_offline') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'payment_offline',
            value: `${checked}`,
          }
        }
      })
    }

    if (service === 'delivery_pickup') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'delivery_pickup',
            value: `${checked}`,
          }
        }
      })
    }

    if (service === 'delivery_delivery') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'delivery_delivery',
            value: `${checked}`,
          }
        }
      })
    }

    if (service === 'delivery_company') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'delivery_company',
            value: `${checked}`,
          }
        }
      })
    }

    if (service === 'chat_enable') {
      ws.doSend({
        command: 'setSettingsParamValue',
        params: {
          parameter: {
            name: 'chat_enable',
            value: `${checked}`,
          }
        }
      })
    }
  };

  _saveMailingList = value => () => {
    ws.doSend({
      command: 'setSettingsParamValue',
      params: {
        parameter: {
          name: 'mailing_list',
          value: value,
        }
       }
    });
  };

  render () {
    return (
      <ServicesView params={this.props.data.params}
        onServiceChange={this._onServiceChange}
        saveMailingList={this._saveMailingList} />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData
  }
}


export default connect(mapStateToProps)(Services);
