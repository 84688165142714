import React, { Component } from 'react';
import { connect } from 'react-redux';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import * as ws from '../../api/connections';
import store from '../../store';
import { setOrderOrder } from './actions';

import CheckOrderView from './component';


class CheckOrder extends Component {

  componentDidMount() {
    if (this.props.uuid && this.props.email) {
      store.dispatch(setOrderOrder({details: {items: []}}));
      ws.doSend({
        command: 'getOrderState',
        params: {
          order: this.state.order,
          email: this.state.email
        }
      })
    }
  }

  state = {
    disable_button: this.props.data.captcha_protect ? true : false,
    order: this.props.uuid,
    email: this.props.email,
  }

  verifyCallback = response => {
    this.setState({disable_button: false})
  };

  onOrderChange = name => (e, {value}) => {
    const t = this.state;
    t[name] = value;
    this.setState(t);
  };

  onActionClick = () => {
    store.dispatch(setOrderOrder({details: {items: []}}));
    ws.doSend({
      command: 'getOrderState',
      params: {
        order: this.state.order,
        email: this.state.email
      }
    })
  };

  _disableDel = () => {
    const { order } = this.props.data;
    if (!order) {
      return true
    }
    if (!order.state || ('created', 'picked').indexOf(order.state) === -1) {
      return true
    }
    return false;
  };

  _disablePrint = () => !this.props.data.order.state;

  disableOrder = () => {
    ws.doSend({
      command: 'setOrderState',
      params: {
        order: this.props.data.order.order_uuid,
        email: this.state.email,
      }
    })
    setTimeout(() => {
      ws.doSend({
        command: 'getOrderState',
        params: {
          order: this.state.order,
          email: this.state.email,
        }
      })
    }, 1000)
  };

  printOrder = () => {
    html2canvas(document.querySelector('#orderForPrint'))
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage({imageData: imgData, x: 5, y: 5, w: 200, h: 80});
        pdf.save("print.pdf");
    });
  };

  render () {
    return (
      <CheckOrderView
        disable_button={this.state.disable_button}
        order={this.state.order}
        email={this.state.email}

        captcha_protect={this.props.data.captcha_protect}

        onOrderChange={this.onOrderChange}
        onActionClick={this.onActionClick}
        verifyCallback={this.verifyCallback}

        state_options={this.props.data_app.order_states}
        disable_del={this._disableDel()}
        disable_print={this._disablePrint()}
        disableOrder={this.disableOrder}
        printOrder={this.printOrder}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.CheckOrderState.CheckOrderData,
    data_app: store.AdminAppState.AdminAppData,
  }
}


export default connect(mapStateToProps)(CheckOrder);
