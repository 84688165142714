import React, { Component } from 'react';

import Acat from './acat/container';
import CatalogsParts from './catalogs-parts/container';
import GetCatNet from './getcat.net/container';
// import TehkomSearch from './tehkomsearch/container';


class Catalogue extends Component {
  render () {
    const { content } = this.props;

    switch(content) {
      case 'Acat':
        return <div><Acat /></div>
      case 'GetCatNet':
        return <div><GetCatNet /></div>
      case 'CatalogsParts':
        return <div><CatalogsParts /></div>
      default: return <div>Не выбрано</div>
    }
  }
}


export default Catalogue;
