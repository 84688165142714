import React, { useContext, useEffect, useRef, useState, } from "react";

import { Image, PageHeader, Tabs } from 'antd';

import FirstTab from './tabs/first_tab';

const { TabPane } = Tabs;


function callback(key) {
  console.log(key);
}


const OzonAdminPanelView = props => {
  return (
    <PageHeader
      title={'TEHKOM+'}
      subTitle={'Подготовка товаров для выгрузки в OZON'}

      extra={[
        <Image width={100} preview={false} src='/images/logo-ozon.webp' />
      ]}
    >

      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab={'Товары'} key={'1'}>
          <FirstTab />
        </TabPane>
      </Tabs>

    </PageHeader>
  );
}


export default OzonAdminPanelView;
