import React, { useContext, useEffect, useRef, useState, } from "react";

import OzonAdminPanelView from './component';


const OzonAdminPanel = () => {
  return (
    <OzonAdminPanelView />
  );
}

export default OzonAdminPanel;