import store from '../store';
import { handler } from './handlers';


var socket;
var tasks = [];
var socket_closed = true;


export function openConnection() {
  const url = 'wss://ws.tehkom-avto.ru/api/v1/caihet8kahrouWahch3fexooC0faihee/'
  // const url = 'wss://ws_test_shop.tmnhy.su/api/v1/caihet8kahrouWahch3fexooC0faihee/'

  if (socket_closed) {
    console.log('Попытка открыть сокет')
    socket_closed = false;
    socket = new WebSocket(url);

    socket.onopen = function(event) { onOpen(event) };
    socket.onclose = function(event) { onClose(event) };
    socket.onmessage = function(event) { onMessage(event) };
    socket.onerror = function(event) { onError(event) };
  }
}

function onOpen(event) {
  console.log('onOpen:', 'cоединение открыто');
  setTimeout(send, 500);
}

function onClose(event) {
  if (event.wasClean) {
    console.log('onClose:', 'соединение закрыто чисто');
  } else {
    console.log('onClose:', 'обрыв соединения');
  };
  socket_closed = true;
}

function onMessage(event) {
  const data = JSON.parse(event.data);

  handler(data);
  if (tasks.length > 0) {
    setTimeout(send, 100);
    // send();
  } else {
    socket.send('close me');
  }
}

function onError(event) {
  console.log('OnError:', 'ошибка соединения');
  socket_closed = true;
}


function send() {
  if ((socket) && (socket.readyState === 1)) {
    var t = tasks.shift();
    if (t) {
      socket.send(t);
    }
  } else {
    openConnection();
  }
}


export function doSend(message) {
  message.token = store.getState().AppState.AppData.token;
  if (!message.params) {
    message.params = {}
  }

  // console.log(message)
  tasks.push(JSON.stringify(message));
  send();
}
