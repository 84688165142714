import * as types from '../../../../actions/action-types'


const initialState = {
  GroupListData: {
    items: [],
    filter: ''
  }
}


const GroupListReducer = function(state=initialState, action) {
  switch(action.type) {

    case types.SET_SEARCH_GROUP_LIST_ITEMS:
      return Object.assign({}, state, {
        GroupListData: Object.assign({}, state.GroupListData, {
          items: action.data
        })
      });

    case types.SET_SEARCH_GROUP_LIST_FILTER:
      return Object.assign({}, state, {
        GroupListData: Object.assign({}, state.GroupListData, {
          filter: action.data
        })
      });

    default: return state;
  }
}


export default GroupListReducer;
