import React, { Component } from 'react';
import { connect } from 'react-redux';

import CrossesView from './component';
import * as ws from '../../../api/connections';
import store from '../../../store';

import { setAdminCrosses } from '../actions';


class Crosses extends Component {

  componentDidMount = () => {
  };

  componentWillUnmount = () => {
    store.dispatch(setAdminCrosses([]));
  };

  _onSearchClick = item => {
    store.dispatch(setAdminCrosses([]));
    if (item) {
      ws.doSend({
        command: 'getCustomCrossesByItem',
        params: {
          item
        }
      });
    }
  }

  _onSaveCrosses = crosses => {
    const item = this.props.data.crosses[0];
    item.crosses = crosses;
    ws.doSend({
      command: 'updateCustomCrosses',
      params: {
        item
      }
    });
  };

  render () {
    return (
      <CrossesView
        item={this.props.data.crosses.length === 1 ? this.props.data.crosses[0] : null}
        onSearchClick={this._onSearchClick}
        onSaveCrosses={this._onSaveCrosses}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData
  }
}


export default connect(mapStateToProps)(Crosses);
