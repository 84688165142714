import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import * as serviceWorker from './registerServiceWorker';

import routes from './routes';
import store from './store';

import { checkLocalStorage, init_focus_event } from './api/functions';


checkLocalStorage();
init_focus_event();

ReactDOM.render(
  <Provider store={store}>{routes}</Provider>, document.getElementById('root')
);

serviceWorker.unregister();
