import React, { Component } from 'react';

import { Button, Card, Image } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';
import DatepickerButton from '../../misc/datepickerbutton/container';


class ReportsView extends Component {


  render () {
    const { items, startDate, endDate, changeDate = () => {} } = this.props;

    return (
      <Card.Group>
        {
          items.map((item, i) => (
            <Card key={i} style={{minWidth: '370px'}}>
            <Card.Content>
              <Image floated={'right'} size={'mini'} src={item.image} />
              <Card.Header>{'Продажи'}</Card.Header>
              <Card.Meta>{'Отчёт по позициям'}</Card.Meta>
              <Card.Description>
                {'Товарные позиции, проданные за период'}
              </Card.Description>
            </Card.Content>

            <Card.Content extra>
              <div>
                <DatePicker
                  customInput={<DatepickerButton />}
                  selected={startDate}
                  dateFormat='DD MMM, YYYY'
                  onChange={changeDate('start')}
                  locale={'ru'} />

                &nbsp;&nbsp;

                <DatePicker
                  customInput={<DatepickerButton />}
                  selected={endDate}
                  dateFormat='DD MMM, YYYY'
                  onChange={changeDate('end')}
                  locale={'ru'} />
              </div>
            </Card.Content>

            <Card.Content extra>
              <div className='ui one buttons'>
                <Button basic color='green' onClick={item.onClick}
                        disabled={item.link === 'calc'}
                        loading={item.link === 'calc'} >
                  {'Сформировать'}
                </Button>
              </div>
            </Card.Content>
          </Card>
          ))
        }
      </Card.Group>
    )
  }
}


export default ReportsView;
