import React, { Component } from 'react';
import { connect } from 'react-redux';

import SettingsView from './component';
import * as ws from '../../../api/connections';
import store from '../../../store';
import { setAdminPriceType, setAdminDeliveryPriceType } from '../actions';


class Settings extends Component {

  componentDidMount = () => {
    ws.doSend({
      command: 'getSettingsCategories',
      params: {
        auth: this.props.data.auth
      }
    });
    ws.doSend({
      command: 'getSettingsGroups',
      params: {}
    });
    ws.doSend({
      command: 'getSettingsPriceType',
      params: {}
    });
    ws.doSend({
      command: 'getSettingsDeliveryPriceType',
      params: {}
    });
    ws.doSend({
      command: 'getAdditionalGroups',
      params: {}
    });
  };

  onChangeVisible = (name, item) => (e, {checked}) => {
    if (name === 'categories') {
      ws.doSend({
        command: 'updateSettingsCategories',
        params: {
          name: item.name,
          visible: checked
        }
      });
    } else if (name === 'groups') {
      ws.doSend({
        command: 'updateSettingsGroups',
        params: {
          name: item.name,
          visible: checked
        }
      });
    }
  };

  _setPriceType = (e, {value}) => {
    store.dispatch(setAdminPriceType(value));
    ws.doSend({
      command: 'setSettingsPriceType',
      params: {value}
    });
  };

  _setDeliveryPriceType = (e, {value}) => {
    store.dispatch(setAdminDeliveryPriceType(value));
    ws.doSend({
      command: 'setSettingsDeliveryPriceType',
      params: {value}
    });
  };

  _addAddGroup = name => () => {
    if (!name) { return };
    ws.doSend({
      command: 'addAdditionalGroup',
      params: {name}
    });
  };

  _updateAddGroup = item => () => {
    ws.doSend({
      command: 'updateAdditionalGroup',
      params: {
        id: item.id,
        is_model: !item.is_model
      }
    });
  };

  _delAddGroup = item => () => {
    ws.doSend({
      command: 'delAdditionalGroup',
      params: {id: item.id}
    });
  };

  _addGroupOptions = () => [...[{
      key: 0,
      text: 'Нет группы',
      value: 0,
      is_model: false
    }], ...this.props.data.add_groups.map(item => ({
      key: item.id,
      text: item.name,
      value: item.id,
      is_model: item.is_model
  }))];

  _changeAddGroup = group => (e, {value}) => {
    ws.doSend({
      command: 'updAdditionalGroup',
      params: {
        group: group.id,
        add_group: value
      }
    });
  };

  _changeAddCategory = category => (e, {value}) => {
    ws.doSend({
      command: 'updAdditionalCategory',
      params: {
        category: category.attribut,
        add_category: value
      }
    });
  };

  render () {
    return (
      <SettingsView
        categories={this.props.data.categories}
        groups={this.props.data.groups}

        onChangeVisible={this.onChangeVisible}

        price_type={this.props.data.price_type}
        onPriceChange={this._setPriceType}

        delivery_price_type={this.props.data.delivery_price_type}
        onDeliveryPriceChange={this._setDeliveryPriceType}

        add_groups={this.props.data.add_groups}
        addAddGroup={this._addAddGroup}
        updateAddGroup={this._updateAddGroup}
        delAddGroup={this._delAddGroup}

        addGroupOptions={this._addGroupOptions()}
        changeAddGroup={this._changeAddGroup}
        changeAddCategory={this._changeAddCategory}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData
  }
}


export default connect(mapStateToProps)(Settings);
