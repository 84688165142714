import * as types from '../../../actions/action-types'


export function setBasketItems(data) {
  return {
    type: types.SET_BASKET_ITEMS,
    data
  }
}


export function setBasketPayment(data) {
  return {
    type: types.SET_BASKET_PAYMENT,
    data
  }
}


export function setBasketDelivery(data) {
  return {
    type: types.SET_BASKET_DELIVERY,
    data
  }
}


export function setBasketMapOpen(data) {
  return {
    type: types.SET_BASKET_MAP_OPEN,
    data
  }
}


export function setBasketMapObjects(data) {
  return {
    type: types.SET_BASKET_MAP_OBJECTS,
    data
  }
}


export function setBasketMapSelectedPoint(data) {
  return {
    type: types.SET_BASKET_MAP_SELECT_POINT,
    data
  }
}


export function setBasketConfirmOpen(data) {
  return {
    type: types.SET_BASKET_CONFIRM_OPEN,
    data
  }
}


export function setBasketConfirmState(data) {
  return {
    type: types.SET_BASKET_CONFIRM_STATE,
    data
  }
}


export function setBasketConfirmOrder(data) {
  return {
    type: types.SET_BASKET_CONFIRM_ORDER,
    data
  }
}


export function setBasketDeliveryAddress(data) {
  return {
    type: types.SET_BASKET_DELIVERY_ADDRESS,
    data
  }
}
