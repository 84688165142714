import React, { Component } from 'react';

import { Button, Card, Checkbox, Grid, Icon, Image, List, Table } from 'semantic-ui-react'


class OrderTableView extends Component {

  _countFormatter = row => row.count ? row.count.toFixed(0) : '--';
  _priceFormatter = row => row.price ? row.price.toFixed(2) : '--.--';
  _summFormatter = row => row.price ? (row.price * row.count).toFixed(2) : '--.--';

  _rowColor = row => {
    if (row.checked) {
      return '#E0F7FA'
    } else if (row.trashed) {
      return '#FCE4EC'
    } else {
      return 'white'
    }
  }

  _renderRow = (row, i) => {
    return (
        <Table.Row key={i} style={{backgroundColor: this._rowColor(row)}}>
          <Table.Cell textAlign={'center'}>{i + 1}</Table.Cell>
          <Table.Cell textAlign={'right'}>{row.article}</Table.Cell>
          <Table.Cell textAlign={'right'}>{row.item}</Table.Cell>
          <Table.Cell textAlign={'left'}>{row.name}</Table.Cell>
          <Table.Cell textAlign={'left'}>{row.producer}</Table.Cell>
          <Table.Cell textAlign={'right'}>{this._priceFormatter(row)}</Table.Cell>
          <Table.Cell textAlign={'right'}>{this._countFormatter(row)}</Table.Cell>
          <Table.Cell textAlign={'right'}>{row.unit || '-'}</Table.Cell>
          <Table.Cell textAlign={'right'}>{this._summFormatter(row)}</Table.Cell>
          <Table.Cell textAlign={'center'}>
            <Checkbox checked={row.checked} disabled={true} />
          </Table.Cell>
          <Table.Cell textAlign={'center'}>
            <Checkbox checked={row.trashed} disabled={true} />
          </Table.Cell>
        </Table.Row>
    )
  };

  _allPrices = () => this.props.rows.filter(item => item.checked).reduce((a, c) => a + c.count * c.price, 0);

  _payment_state = state => {
    switch (state) {
      case 0: return 'Ожидает';
      case 1: return 'Оплачено';
      case 2: return 'Возврат';

      default: return 'Ожидает';
    }
  };

  _deliveryIcon = delivery => {
    if (delivery === 'company') {
      return 'truck';
    } else if (delivery === 'pickup') {
      return 'map marker';
    } else if (delivery === 'delivery') {
      return 'shipping fast';
    } else {
      return '';
    }
  }

  _deliveryContent = item => {
    const delivery = item.details.selected_delivery
    if (delivery === 'company') {
      return item.details.selected_company ? `Транспортная компания. ${item.details.selected_company.cdek ? 'СДЭК' : ''} ${item.details.selected_company.russianpost ? 'Почта России' : ''} ${item.details.delivery_address}` : `Транспортная компания. <br />${item.details.delivery_address}`;
    } else if (delivery === 'pickup') {
      return `Самовывоз. ${item.details.delivery_address}`;
    } else if (delivery === 'delivery') {
      return `До двери. ${item.details.delivery_address}`;
    } else {
      return '';
    }
  }

  _selectedCompany = order => {
    if (!order.details.selected_company) {
      return '';
    } else if (order.details.selected_company.cdek === true) {
      return <label> СДЭК <a target="_blank" rel="noopener noreferrer" href="https://cdek.ru/tracking">https://cdek.ru/tracking</a></label>;
    } else if (order.details.selected_company.russianpost === true) {
      return <label> Почта России <a target="_blank" rel="noopener noreferrer" href="https://www.pochta.ru/tracking">https://www.pochta.ru/tracking</a></label>;
    }
  }

  // <Button basic icon={'money'} color={'teal'} content={'Оплатить'} />

  render () {
    const { rows, order_number, created, order_state,
            payment_state, payment_type, payment_url,
            order } = this.props;

    return (
      <div style={{display: rows.length > 0 ? 'block' : 'None'}}
           id={'orderForPrint'} >
        <Grid>
          <Grid.Row columns={5} >

            <Grid.Column width={3}>
              <List>
                <List.Item
                  icon='calendar'
                  header={'Дата и время заказа'}
                  content={created} />
              </List>
            </Grid.Column>

            <Grid.Column width={3}>
              <List>
                <List.Item
                  icon='check'
                  header={'Номер заказа'}
                  content={order_number} />
              </List>
            </Grid.Column>

            <Grid.Column width={3}>
              <List>
                <List.Item
                  icon='money'
                  header={'Способ оплаты'}
                  content={`${payment_type}: ${this._payment_state(payment_state)}`} />
              </List>
            </Grid.Column>

            <Grid.Column width={4}>
              <List>
                <List.Item
                  icon={this._deliveryIcon(order.details.selected_delivery)}
                  header={'Способ доставки'}
                  content={this._deliveryContent(order)} />
              </List>
            </Grid.Column>

            <Grid.Column width={3}>
              <List>
                <List.Item
                  header={order.details.track_number || ''}
                  content={this._selectedCompany(order)} />
              </List>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            { (payment_type === 'Предоплата (онлайн)' && payment_state === 0 && payment_url !== null && ['picked', 'ready'].indexOf(order_state.value) !== -1)
              // eslint-disable-next-line
              &&  <Grid.Column width={10}>
                    <Card raised color='teal' >
                      <Card.Content>
                        <Image src={'images/cards.png'} />
                      </Card.Content>
                      <Card.Content extra>
                        <Button basic icon={'money'} color={'teal'} floated={'right'} >
                          <a href={payment_url}>{'Оплатить'}</a>
                        </Button>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
              // eslint-disable-next-line
              || <Grid.Column width={10} />
            }
          </Grid.Row>

        </Grid>
        <Table compact >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign={'center'}></Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{'Артикул'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{'Код'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{'Наименование'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{'Производитель'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{'Цена'} {<Icon name='ruble'/>}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'} width={'2'}>{'Количество'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{''}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'} width={'2'}>{'Сумма'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'} ><Icon name='clipboard check' color={'teal'} /></Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'} ><Icon name='trash' color={'pink'} /></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row, i) => this._renderRow(row, i))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan='5' textAlign={'right'}>
                <span>ИТОГО <b>{`${rows.filter(item => item.checked).length} поз.`}</b> на сумму:</span>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='2'  textAlign={'right'}>
                <b>{`${this._allPrices().toFixed(2)} руб.`}</b>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='4'>
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell style={{backgroundColor: '#E0F7FA'}}>
                <Icon name='clipboard check' color={'teal'} />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={'10'} style={{backgroundColor: '#E0F7FA'}}>
                {'наличие позиции подтверждено'}
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell style={{backgroundColor: '#FCE4EC'}}>
                <Icon name='trash' color={'pink'} />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={'10'} style={{backgroundColor: '#FCE4EC'}}>
                {'данная позиция временно отсутствует'}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    )
  }
}


export default OrderTableView;
