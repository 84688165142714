import React, { Component } from 'react';

import { Grid } from 'semantic-ui-react'

import SearchEngine from './searchengine/container';
import SearchResult from '../categorysearch/searchresult/container';

import store from '../../../store';
import * as ws from '../../../api/connections';
import {  setSearchResultRows,
          setSearchResultTotalPages,
          setSearchResultActivePage } from '../categorysearch/searchresult/actions';


class FullSearch extends Component {

  componentDidMount = () => {
    ws.doSend({command: 'getDetailAttributes'});
    // store.dispatch(setSearchResultRows([]));
    // store.dispatch(setSearchResultTotalPages(1));
    // store.dispatch(setSearchResultActivePage(1));
  };

  componentWillUnmount = () => {
    store.dispatch(setSearchResultRows([]));
    store.dispatch(setSearchResultTotalPages(1));
    store.dispatch(setSearchResultActivePage(1));
  };

  render () {
    return (

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <SearchEngine />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SearchResult height={38} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

    )
  }
}


export default FullSearch;
