import * as types from '../../../actions/action-types'


const initialState = {
  AppData: {
    token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NjE5MjM0MDAuMCwicm9sZSI6Imd1ZXN0Iiwic3ViIjoiVGVoa29tYXZ0byBjYXRhbG9nIn0.lXXTuHuGnn6nYYYJovikqdKm_zi1LLTMMWFRh8zmAmCpYtxJxq_Sg3wANfL3G_qoD8oWV8oU2oqkQL8Lb9NbS9cPe5fN2DTQT1kwVlFVOSaY9rvvd-yChFe36wmJN8DOJEMFrebhwDmPwXZ5125G7BwSp6iTSPbNtgZqvotCbPYr15iCiXkeHd8nGCJhU3qnWRxfgCiJCiufvPJINQYJ1w8t6lCAxqeLg8lI3Nw9cABt75Rv0oml1ls1awhvDZF_xlFlODquP2UeOpvjh1PUYYwwRcwGCL3MMxyvug3TqQggm2ziZuZOeeRO8jMJY72iN56mzR7_C1aXIWsIGfqKcQ',
    localStorageKey: 'beeT7yughei4eMo7',
    version: '2020.11.11.01',
    dimmed: false,
    global_menu: true,
    global_menu_items: [
      { title: 'ВАЗ', buttons: true, },
      { title: 'ГАЗ', buttons: true, },
      { title: 'УАЗ', buttons: true, },
      { title: 'Иномарки', buttons: true, },
      { title: 'АКБ - Щётки - Лампы', buttons: false, },
      { title: 'Автокрепёж', buttons: false, },
      { title: 'Масла - Химия - Аксессуары', buttons: false, },
    ],
    catalogue_acat_ext: '',
    search_active_index: 0,
    global_menu_popup_open: false,
    hide_attribute_list: false,
    hide_group_list: false,
  }
}


const AppReducer = function(state=initialState, action) {
  switch(action.type) {
    case types.SET_APP_DIMMED:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          dimmed: action.data
        })
      });

    case types.SET_APP_GLOBAL_MENU:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          global_menu: action.data
        })
      });

    case types.SET_APP_GLOBAL_MENU_ITEMS:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          global_menu_items: action.data
        })
      });

    case types.SET_APP_ACAT_EXT:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          catalogue_acat_ext: action.data
        })
      });

    case types.SET_APP_SEARCH_ACTIVE_INDEX:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          search_active_index: action.data
        })
      });

    case types.SET_APP_GMENU_POPUP_OPEN:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          global_menu_popup_open: action.data
        })
      });

    case types.SET_APP_HIDE_ATTR_LIST:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          hide_attribute_list: action.data
        })
      });

    case types.SET_APP_HIDE_GROUP_LIST:
      return Object.assign({}, state, {
        AppData: Object.assign({}, state.AppData, {
          hide_group_list: action.data
        })
      });

    default: return state;
  }
}


export default AppReducer;
