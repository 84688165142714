import * as types from '../../actions/action-types'


export function setRulesPaymentOpen(data) {
  return {
    type: types.SET_RULES_PAYMENT_OPEN,
    data
  }
}


export function setRulesPaymentText(data) {
  return {
    type: types.SET_RULES_PAYMENT_TEXT,
    data
  }
}