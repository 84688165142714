import React, { Component } from 'react';
import { connect } from 'react-redux';

import ym from 'react-yandex-metrika';

import * as ws from '../../api/connections';
import store from '../../store';

import BasketView from './component';

import { getItemsForBasket, setItemsToStorage,
         deleteItemFromBasket } from '../../api/functions';
import { setBasketItems, setBasketConfirmOpen,
         setBasketConfirmState } from './actions';
import { setRulesOpen } from '../rules/actions';


const validateEmail = email => {
  // eslint-disable-next-line
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}


class Basket extends Component {

  state = {
    paymentValue: 'offline',
    deliveryValue: 'pickup',
    activePayment: -1,
    activeDelivery: -1,

    price_renew_alert: false,

    confirm_rules: false,

    name: '',
    email: '',
    phone: '+7',
    comment: '',
    post_index: '',
    delivery_point: '',
    delivery_address: '',

    name_error: true,
    email_error: true,
    phone_error: true,
    post_index_error: true,

    map_open: false,
    selected_point: null,

    map_center: [54.193122, 37.617348],
    map_zoom: 12,

    cdek_check: false,
    russianpost_check: false,
  }

  componentDidMount () {
    const items = getItemsForBasket().filter(item => item !== null);
    store.dispatch(setBasketItems(items));

    ws.doSend({
      command: 'getSettingsParamsValue',
      params: {
        params: ['payment_online', 'payment_offline',
                 'delivery_pickup', 'delivery_delivery', 'delivery_company', ],
      }
    });

    this._renewPrice(items, this.state.deliveryValue);
  }

  _renewPrice = (items, value) => {
    ws.doSend({
      command: 'renewPrice',
      params: {
        items: items.map(item => item.item),
        delivery: value,
      }
    });
    this.setState({price_renew_alert: true});
    setTimeout(() => {this.setState({price_renew_alert: false});}, 3000);
  }

  paymentChange = (e, { value }) => {
    if (value === 'offline' && this.state.deliveryValue === 'pickup') {
      this.setState({ paymentValue: value });
    } else if (value === 'online') {
      this.setState({ paymentValue: value });
    }
  }

  deliveryChange = (e, { value }) => {
    if (value !== this.state.deliveryValue) {
      this._renewPrice(this.props.data.items, value);
    }
    if (value === 'company' || value === 'delivery') {
      this.setState({
        deliveryValue: value,
        paymentValue: 'online',
        delivery_point: '',
        delivery_address: '',
      });
    } else {
      this.setState({
        deliveryValue: value,
        delivery_point: '',
        delivery_address: '',
      });
    }
  }

  paymentClick = (e, titleProps) => {
    const { index } = titleProps
    const { activePayment } = this.state
    const newIndex = activePayment === index ? -1 : index

    this.setState({ activePayment: newIndex })
  }

  deliveryClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeDelivery } = this.state
    const newIndex = activeDelivery === index ? -1 : index

    this.setState({ activeDelivery: newIndex })
  }

  currentDelivery = () => {
    switch (this.state.deliveryValue) {
      case 'pickup':
        return 'самовывоз из магазина';
      case 'delivery':
        return 'до двери по Туле';
      case 'company':
        return 'транспортная компания';
      default:  break;
    }
  }

  _forPayment = () => this.props.data.items.reduce((a, c) => a + c.count * c.price, 0);

  deliveryItems = () => {
    const { delivery_pickup, delivery_delivery, delivery_company,
            payment_online } = this.props.params_data.params;
    return this.props.data.delivery_items.map((item, i) => {
      if (item.key === 'pickup' && delivery_pickup === 'true') {
        item.disabled =  false;
      } else if (item.key === 'delivery' && delivery_delivery === 'true') {
        if (payment_online === 'true' && this._forPayment() >= 1000) {
          item.disabled = false;
        }
      } else if (item.key === 'company' && delivery_company === 'true') {
        item.disabled = false;
      }

      return item;
    });
  }

  paymentItems = () => {
    const { payment_offline, payment_online } = this.props.params_data.params;
    return this.props.data.payment_items.map((item, i) => {
      if (item.key === 'offline' && payment_offline === 'true') {
        item.disabled =  false;
      } else if (item.key === 'online' && payment_online === 'true') {
        item.disabled =  false;
      }

      return item;
    });
  }

  deleteItem = item => {
    deleteItemFromBasket(item);

    const items = getItemsForBasket().filter(item => item !== null);
    store.dispatch(setBasketItems(items));
  }

  checkItem = item => () => {
    store.dispatch(setBasketItems(
      this.props.data.items.map((it) => {
        if (it.item === item.item) {
          it.checked = !it.checked;
        }
        return it;
      })
    ));
  }

  checkAll = () => {
    if (this.props.data.items.length === this.props.data.items.filter(item => item.checked).length) {
      store.dispatch(setBasketItems(
        this.props.data.items.map(item => {
          item.checked = false;
          return item;
        })
      ));
    } else {
      store.dispatch(setBasketItems(
        this.props.data.items.map(item => {
          item.checked = true;
          return item;
        })
      ));
    }
  }

  deleteChecked = () => {
    this.props.data.items.filter(item => item.checked).forEach(item => {
      deleteItemFromBasket(item);
    })

    const items = getItemsForBasket().filter(item => item !== null);
    store.dispatch(setBasketItems(items));
  }

  incCount = item => () => {
    setItemsToStorage(this.props.data.items.map(it => {
      if (it.item === item.item) {
        if (it.count + 1 <= it.quantity_real) {
          it.count = it.count + 1;
        }
      }
      return item;
    }));

    const items = getItemsForBasket().filter(item => item !== null);
    store.dispatch(setBasketItems(items));
  }

  decCount = item => () => {
    setItemsToStorage(this.props.data.items.map(it => {
      if (it.item === item.item) {
        if (it.count - 1 >= 1) {
          it.count = it.count - 1;
        }
      }
      return item;
    }));

    const items = getItemsForBasket().filter(item => item !== null);
    store.dispatch(setBasketItems(items));
  }

  changeCount = item => (e, {value}) => {
    const val = parseInt(value, 10);
    if (val) {
      setItemsToStorage(this.props.data.items.map(it => {
        if (it.item === item.item) {
          if (val <= it.quantity_real) {
            it.count = val;
          }
        }
        return item;
      }));

      const items = getItemsForBasket().filter(item => item !== null);
      store.dispatch(setBasketItems(items));
    }
  }

  changeConfirmRules = () => {
    if (!this.state.confirm_rules) {
      store.dispatch(setRulesOpen(true));
    } else {
      this.setState({confirm_rules: false});
    }
  }

  iAgreeRules = () => {
    this.setState({confirm_rules: true});
    store.dispatch(setRulesOpen(false));
  };

  iDisagreeRules = () => {
    this.setState({confirm_rules: false});
    store.dispatch(setRulesOpen(false));
  };

  openMap = () => {
    this.setState({map_open: true});
  }

  closeMap = () => {
    this.setState({map_open: false});
  }

  mapObjectClick = e => {
    const target = e.get('target');
    const key = target.properties.get('iconContent');
    this.props.data.map_objects.forEach(item => {
      if (key === item.properties.iconContent) {
        this.setState({center: item.geometry.coordinates, zoom: 16});
        this.setState({
          selected_point: item.key,
          delivery_address: item.properties.hintContent
        });
      }
    })
  };

  mapListItemClick = key => {
    this.props.data.map_objects.forEach(item => {
      if (key === item.key) {
        this.setState({
          map_center: item.geometry.coordinates,
          map_zoom: 16,
          selected_point: item.key,
          delivery_address: item.properties.hintContent,
        });
      }
    });
  };

  setDeliveryAddressFromMap = address => {
    this.setState({
      delivery_address: address,
    });
  }

  onContactInfoChange = name => (e, {value}) => {

    if (name === 'name') {
      if (value.length < 3) {
        this.setState({
          name: value,
          name_error: true,
        });
      } else {
        this.setState({
          name: value,
          name_error: false,
        });
      }
    }

    if (name === 'email') {
      if (validateEmail(value)) {
        this.setState({
          email: value,
          email_error: false,
        });
      } else {
        this.setState({
          email: value,
          email_error: true,
        });
      }
    }

    if (name === 'phone') {
      if (value.substring(0, 1) === '+' ) {
        var temp = value.slice(1);
      } else {
        temp = value;
      }

      temp = temp.split(' ').join('');
      temp = temp.split('-').join('');
      temp = [ temp.substring(0, 1), temp.substring(1, 4),
               temp.substring(4, 7),
               temp.substring(7, 9), temp.substring(9, 11)]

      if (temp.join('') === `${parseInt(temp.join(''), 10)}`) {
        this.setState({
          phone: '+' + temp.filter(item => item.length > 0).join(' '),
        })
      }

      this.setState({
        phone_error: !(temp.join('').length === 11),
      });
    }

    if (name === 'post_index') {
      if ((value.length <=6) && (value === `${parseInt(value, 10)}`)) {
        this.setState({
          post_index: value,
        });
      }

      if ((value.length === 6) && (value === `${parseInt(value, 10)}`)) {
        this.setState({
          post_index_error: false,
        });
      }
       else {
        this.setState({
          post_index_error: true,
        });
      }

    }

    if (name === 'comment') {
      this.setState({comment: value});
    }

    if ((name === 'address') && (this.state.deliveryValue !== 'pickup')) {
      this.setState({delivery_address: value});
    }
  }

  validRecipient = () => (this.state.name.length && this.state.email.length && this.state.phone.length)
    && (!this.state.name_error && !this.state.email_error && !this.state.phone_error);

  validAddress = () => {
    if (this.state.deliveryValue === 'company') {
      return (this.state.post_index.length === 6 && this.state.delivery_address.length !== 0);
    } else {
      return this.state.delivery_address.length !== 0;
    }
  }


  companyChange = company => () => {
    if (company === 'cdek') {
      this.setState({cdek_check: !this.state.cdek_check});
    }

    if (company === 'russianpost') {
      this.setState({russianpost_check: !this.state.russianpost_check});
    }
  }

  releaseOrder = () => {
    store.dispatch(setBasketConfirmOpen(true));
    store.dispatch(setBasketConfirmState('blocked'));
    setTimeout(() => {store.dispatch(setBasketConfirmState('error'))}, 10000);

    const order = {
      personal: {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        comment: this.state.comment
      },
      items: this.props.data.items.map(item => {
        item.trashed = false;
        item.checked = false;
        return item;
      }),
      delivery_point: this.state.selected_point,
      delivery_address: this.state.post_index ? `${this.state.post_index}, ${this.state.delivery_address}` : this.state.delivery_address,
      selected_delivery: this.state.deliveryValue,
      selected_payment: this.state.paymentValue,
      selected_company: {cdek: this.state.cdek_check, russianpost: this.state.russianpost_check},
    }
    ws.doSend({
      command: 'setNewOrder',
      params: {
        order: order
      }
    });

    ym('reachGoal','order');
  };


  render () {

    return (
      <BasketView
        activeDelivery={this.state.activeDelivery}
        activePayment={this.state.activePayment}
        paymentValue={this.state.paymentValue}
        deliveryValue={this.state.deliveryValue}

        paymentChange={this.paymentChange}
        deliveryChange={this.deliveryChange}
        paymentClick={this.paymentClick}
        deliveryClick={this.deliveryClick}
        currentDelivery={this.currentDelivery}

        delivery_items={this.deliveryItems()}
        payment_items={this.paymentItems()}

        for_payment={this._forPayment()}

        items={this.props.data.items}
        deleteItem={this.deleteItem}
        checkItem={this.checkItem}
        checkAll={this.checkAll}
        deleteChecked={this.deleteChecked}
        incCount={this.incCount}
        decCount={this.decCount}
        changeCount={this.changeCount}

        price_renew_alert={this.state.price_renew_alert}

        confirm_rules={this.state.confirm_rules}
        changeConfirmRules={this.changeConfirmRules}
        iAgreeRules={this.iAgreeRules}
        iDisagreeRules={this.iDisagreeRules}

        name={this.state.name}
        email={this.state.email}
        phone={this.state.phone}
        comment={this.state.comment}
        delivery_point={this.state.delivery_point}
        delivery_address={this.state.delivery_address}
        post_index={this.state.post_index}

        map_open={this.state.map_open}
        selected_point={this.state.selected_point}
        openMap={this.openMap}
        closeMap={this.closeMap}
        points={this.props.data.map_objects}
        map_center={this.state.map_center}
        map_zoom={this.state.map_zoom}
        mapObjectClick={this.mapObjectClick}
        mapListItemClick={this.mapListItemClick}

        onContactInfoChange={this.onContactInfoChange}

        name_error={this.state.name_error}
        email_error={this.state.email_error}
        phone_error={this.state.phone_error}
        post_index_error={this.state.post_index_error}

        valid_recipient={this.validRecipient()}
        valid_address={this.validAddress()}

        setDeliveryAddressFromMap={this.setDeliveryAddressFromMap}
        cdek_check={this.state.cdek_check}
        russianpost_check={this.state.russianpost_check}
        companyChange={this.companyChange}

        releaseOrder={this.releaseOrder}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.BasketState.BasketData,
    params_data: store.AdminAppState.AdminAppData,
  }
}


export default connect(mapStateToProps)(Basket);
