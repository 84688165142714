import React, { Component } from 'react';
import { connect } from 'react-redux';


class Acat extends Component {

  _onLoad = () => {
    document.getElementById('Acat').style.height=window.innerHeight+"px";
  }

  render () {
    return (
      <div>
        <iframe title='ACAT online' id='Acat'
                src={`https://texkomplus.acat.online${this.props.data.catalogue_acat_ext}`}
                width='100%' allowFullScreen
                frameBorder='0'
                onLoad={this._onLoad} >
        </iframe>
      </div>
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
  }
}

export default connect(mapStateToProps)(Acat);

