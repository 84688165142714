import React, { Component } from 'react';

import { Checkbox, Icon, Table } from 'semantic-ui-react'


class OrderItemsTable extends Component {

  _checked = item => {
    if (this.props.selected[`${item.article}~${item.item}`]) {
      return this.props.selected[`${item.article}~${item.item}`].checked;
    } else {
      return item.checked;
    }
  };

  _trashed = item => {
    if (this.props.selected[`${item.article}~${item.item}`]) {
      return this.props.selected[`${item.article}~${item.item}`].trashed;
    } else {
      return item.trashed;
    }
  };

  _rowColor = item => {
    if (this._checked(item)) {
      return '#E0F7FA'
    } else if (this._trashed(item)) {
      return '#FCE4EC'
    } else {
      return 'white'
    }
  }

  _renderRow = (item, i) => (
    <Table.Row key={i} style={{backgroundColor: this._rowColor(item)}} >
      <Table.Cell textAlign={'right'}>{i + 1}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.article}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.item}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.name}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.producer}</Table.Cell>
      <Table.Cell textAlign={'right'}>{item.count}</Table.Cell>
      <Table.Cell textAlign={'right'}>{item.unit || '-'}</Table.Cell>
      <Table.Cell textAlign={'right'}>{item.price.toFixed(2)}</Table.Cell>
      <Table.Cell textAlign={'right'}>{(item.count * item.price).toFixed(2)}</Table.Cell>
      <Table.Cell textAlign={'center'}>
        <Checkbox checked={this._checked(item)}
                  onChange={this.props.itemChecked('checked', `${item.article}~${item.item}`)} /></Table.Cell>
      <Table.Cell textAlign={'center'}>
        <Checkbox checked={this._trashed(item)}
                  onChange={this.props.itemChecked('trashed', `${item.article}~${item.item}`)} /></Table.Cell>
    </Table.Row>
  );

  render () {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign={'center'} width={1}></Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Артикул'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Код'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Наименование'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Производитель'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Кол-во'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{''}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Цена'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} >{'Сумма'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} ><Icon name='clipboard check' color={'teal'}/></Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} ><Icon name='trash' color={'pink'} /></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.rows.map(this._renderRow)}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan='6' textAlign={'right'}>
              <span>ИТОГО <b>{`${this.props.rows.filter((item, i) => this._checked(item)).length} поз.`}</b> на сумму:</span>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan='3'  textAlign={'right'}>
              {this.props.rows.filter((item, i) => this._checked(item)).reduce((a, c) => a + c.count * c.price, 0).toFixed(2)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
}


export default OrderItemsTable;
