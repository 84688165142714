import React, { Component } from 'react';
import { connect } from 'react-redux';

import GroupListView from './component';

import store from '../../../../store';
import * as ws from '../../../../api/connections';
import {  setSearchGroupListFilter } from './actions';
import {  setSearchResultRows, setSearchResultTitle,
          setSearchResultFilter } from '../searchresult/actions';
import { setSearchResultInProgress } from '../../categorysearch/searchresult/actions';
import { setAdminAppSettingsAddGroups } from '../../../admin/actions';
import { setAppHideGroupList } from '../../../app/actions/actions';


class GroupList extends Component {

  componentWillUnmount = () => {
    store.dispatch(setSearchGroupListFilter(''));
    store.dispatch(setSearchResultTitle(''));

    store.dispatch(setAppHideGroupList(false));
  };

  _filter = () => {
    const group = this.props.add_data.add_groups
      .filter(item => !item.is_model)
      .map(item => ({
        id: item.id,
        group_name: item.name,
        additional: true,
        open: item.open,
        add_group: 0,
      }));

    var items = [];
    group.forEach(group => {
      items.push(group);
      if (group.open) {
        items = [...items,
                 ...this.props.data.items.filter(item => item.add_group === group.id)]
      }
    });
    return [
      ...items,
      ...this.props.data.items.filter(item => item.add_group === 0)
    ].filter(item => this.props.data.filter === '' || item.group_name.toUpperCase().indexOf(this.props.data.filter) !== -1);
  };

  _onItemClick = ({id, group_name, additional}) => {
    store.dispatch(setSearchResultRows([]));
    store.dispatch(setSearchResultTitle(group_name));
    store.dispatch(setSearchResultFilter(''));

    if (additional) {
      store.dispatch(setAdminAppSettingsAddGroups(
        this.props.add_data.add_groups.map(item => {
          if (item.id === id) {
            item.open = !item.open
          }
          return item;
        })
      ));
    } else {
      store.dispatch(setAppHideGroupList(true));

      store.dispatch(setSearchResultInProgress(true));
      setTimeout(() => {setSearchResultInProgress(false)}, 30000);
      ws.doSend({
        command: 'getDetailsByGroup',
        params: {
          ids: [id],
          group: group_name
        }
      });
    }
  };

  onFilterChange = (e, {value}) => {
    store.dispatch(setSearchGroupListFilter(value.toUpperCase()))
  };

  _showList = () => {
    store.dispatch(setAppHideGroupList(false));
  };

  render () {
    return (
      <GroupListView
        items={this._filter()}

        onFilterChange={this.onFilterChange}
        onItemClick={this._onItemClick}

        visible={this.props.visible}
        showList={this._showList}
      />
    )
  }
}


const mapStateToProps = store => ({
    data: store.GroupListState.GroupListData,
    add_data: store.AdminAppState.AdminAppData
})

export default connect(mapStateToProps)(GroupList);

