import React, { useContext, useEffect, useRef, useState, } from "react";

import { Empty } from 'antd';


const FirstTab = props => {
  return (
    <Empty description={'Нет данных'} />
  );
}


export default FirstTab;
