import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FooterView from './component';

import store from '../../store';
import { setModalViewerOpen } from '../modal_viewer/actions';
import { setModalReviewOpen } from '../review/actions';
import { setRulesOpen } from '../rules/actions';
import { setRulesPaymentOpen } from '../rules_payment/actions';


class AppFooter extends Component {

  _onHelpClick = () => {
    store.dispatch(setModalViewerOpen(true));
  };

  _onInfoClick = action => () => {
    store.dispatch(setRulesPaymentOpen(true));
    this.props.history.push(action);
  };

  _disagreeHandler = () => {
    store.dispatch(setRulesOpen(false));
    this.props.history.push('/');
  };

  _onReviewClick = () => {
    console.log('Review')
    store.dispatch(setModalReviewOpen(true));
  };

  render () {
    return (
      <FooterView
        onHelpClick={this._onHelpClick}

        showRules={store.getState().OrderStepsState.OrderStepsData.active !== 'basket'}
        onInfoClick={this._onInfoClick} onReviewClick={this._onReviewClick}
        disagreeHandler={this._disagreeHandler} />
    )
  }
}


export default withRouter(AppFooter);
