import React, { Component } from 'react';

import { Header, Icon, Pagination, Popup, Table } from 'semantic-ui-react'


class OrdersTable extends Component {

  _itemState = state => {
    const options = this.props.state_options.filter(item => item.value === `${state}`);
    if (options.length === 1) {
      return options[0].text;
    } else {
      return '';
    }
  };

  _positive = item => item.details.selected_payment === 'online'
    &&  item.payment_state === 1;

  _negative = item => item.details.selected_payment === 'online'
    &&  item.payment_state !== 1;

  _renderRow = (item, i) => (
    <Table.Row key={i} style={{backgroundColor: this._positive(item) ? '#B2DFDB' : this._negative(item) ? '#F8BBD0' : 'white'}}>
      <Table.Cell collapsing textAlign={'right'}>{(this.props.activePage - 1) * this.props.perPage + i + 1}</Table.Cell>

      <Table.Cell collapsing textAlign={'left'}>
        <Header as='h5'>
          <Header.Content>
            {item.created}
            <Header.Subheader>
              {`${item.details.order}`}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell textAlign={'left'}>
        <Header as='h5'>
          <Icon name='user' size={'mini'} />
          <Header.Content>
            {item.details.personal.name}
            <Header.Subheader>
              {item.details.personal.email}
              {item.details.personal.phone.length > 2 ? <span><br />{item.details.personal.phone}</span> : ''}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell textAlign={'left'}>
        <Header as='h5'>
          <Icon name='list' size={'tiny'} />
          <Header.Content>
            {`Позиций: ${item.details.items.reduce((a, c) => a + c.count, 0)}`}
            <Header.Subheader>{item.details.delivery_point ? this.props.delivery_point(item.details.delivery_point) : item.details.delivery_address}</Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell textAlign={'left'}>{this._itemState(item.state)}</Table.Cell>

      <Table.Cell textAlign={'left'}>
        <Header as='h5'>
          <Header.Content>
            {`${this._payment_state(item.payment_state)}`}
            <Header.Subheader>
              {this._payment_type(item.details)}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell textAlign={'right'}>
        <Header as='h5'>
          <Header.Content>
            {item.details.items.filter(item => item.checked).reduce((a, c) => a + c.price * c.count, 0).toFixed(2)}
            <Header.Subheader>
              {item.details.items.reduce((a, c) => a + c.price * c.count, 0).toFixed(2)}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell collapsing textAlign={'center'}><Icon link name='edit' color='teal' onClick={this.props.onRowClick(item)} /></Table.Cell>
      <Table.Cell collapsing textAlign={'center'}>{this._deliveryItem(item)}</Table.Cell>
    </Table.Row>
  );

  _deliveryIcon = delivery => {
    if (delivery === 'company') {
      return 'truck';
    } else if (delivery === 'pickup') {
      return 'map marker';
    } else if (delivery === 'delivery') {
      return 'shipping fast';
    } else {
      return '';
    }
  }

  _deliveryContent = item => {
    const delivery = item.details.selected_delivery
    if (delivery === 'company') {
      return item.details.selected_company ? `Транспортная компания ${item.details.selected_company.cdek ? 'СДЭК' : ''} ${item.details.selected_company.russianpost ? 'Почта России' : ''}` : `Транспортная компания`;
    } else if (delivery === 'pickup') {
      return 'Самовывоз';
    } else if (delivery === 'delivery') {
      return 'До двери';
    } else {
      return '';
    }
  }

  _deliveryItem = item => (
    <Popup content={this._deliveryContent(item)} trigger={<Icon name={this._deliveryIcon(item.details.selected_delivery)} />} />
  );

  _payment_type = details => details.selected_payment && details.selected_payment === 'online'
    // eslint-disable-next-line
    && 'Предоплата (онлайн)'
    // eslint-disable-next-line
    || 'При получении';

  _payment_state = state => {
    switch (state) {
      case 0: return 'Ожидает';
      case 1: return 'Оплачено';
      case 2: return 'Возврат';

      default: return 'Ожидает';
    }
  };


  render () {
    const { rows } = this.props;

    return (
      <div>
        <Table compact size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign={'center'}></Table.HeaderCell>
              <Table.HeaderCell textAlign={'left'}>{'Создан'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'left'}>{'Персона'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'left'}>{'Заказ'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'left'}>{'Состояние'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>{'Оплата'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'right'}>{'Сумма, руб.'}</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}></Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map(this._renderRow)}
          </Table.Body>
        </Table>
        {
          (this.props.totalPages > 1) &&
            <Pagination
              activePage={this.props.activePage}
              totalPages={this.props.totalPages}
              onPageChange={this.props.changePage}

              ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
              firstItem={{content: <Icon name='angle double left'/>, icon: true}}
              lastItem={{content: <Icon name='angle double right'/>, icon: true}}
              prevItem={{content: <Icon name='angle left'/>, icon: true}}
              nextItem={{content: <Icon name='angle right'/>, icon: true}}
            />
        }
      </div>
    )
  }
}


export default OrdersTable;
