import React, { Component } from 'react';

import {
  Container, // Dimmer, Loader, Segment
} from 'semantic-ui-react'

// import OrderSteps from '../ordersteps/container';

const style = {
  minHeight: '48em',
}


class AppView extends Component {

  render () {
    const { content } = this.props;

    return (
      <div style={style}>
        <Container>
          {content}
        </Container>
      </div>
    )
  }
}


export default AppView;
