import React, { Component, createRef } from 'react';

import { Button, Checkbox, Form, Grid, Header, Icon, List, Modal } from 'semantic-ui-react';

import { GeoObject, YMaps, Map, SearchControl, Placemark } from 'react-yandex-maps';


class DeliveryMap extends Component {

  state = {
    center: [54.193122, 37.617348],
    geometry: [0, 0],
    ymaps: null,
    caption: null,
  }

  _renderObject = (item, i) => (
    <GeoObject key={i}
               geometry={item.geometry} properties={item.properties} options={item.options}
               onClick={this.props.mapObjectClick} />
  );

  searchControlRef = createRef(null);

  onMapClick = e => {
    this.setState({center: e.get('coords'), geometry: e.get('coords')});
    var th = this;
    this.state.ymaps.geocode(this.state.geometry).then(function (res) {
      var firstGeoObject = res.geoObjects.get(0);
      th.setState({caption: firstGeoObject.getAddressLine()});
      th.props.setDeliveryAddressFromMap(firstGeoObject.getAddressLine());
    });
  };

  onResultShow = () => {
    if (this.searchControlRef.current) {
      var coords = this.searchControlRef.current.getResultsArray()[0].geometry._coordinates;

      this.setState({center: coords, geometry: coords});

      var th = this;
      this.state.ymaps.geocode(coords).then(function (res) {
        var firstGeoObject = res.geoObjects.get(0);
        th.setState({caption: firstGeoObject.getAddressLine()});
        th.props.setDeliveryAddressFromMap(firstGeoObject.getAddressLine());
      });

      this.searchControlRef.current.clear();
    }
  };

  _renderListItem = (item, i) => (
    <List.Item as='a' key={i} onClick={() => this.props.mapListItemClick(item.key)} >
      <Icon name='marker'  size='large' color={this.props.selected_point === item.key ? 'blue' : 'grey'} />
      <List.Content>
        <List.Header>{item.properties.iconContent}</List.Header>
        <List.Description>
          {item.properties.hintContent}
        </List.Description>
      </List.Content>
    </List.Item>
  );

  render () {

    const { map_open, points,
            map_center, map_zoom } = this.props;
    const { onClose, onContactInfoChange } = this.props;
    const { name, phone, email, comment, post_index, delivery_address } = this.props;
    const { name_error, email_error, phone_error, post_index_error } = this.props;
    const { delivery_value } = this.props;
    const { cdek_check, russianpost_check, companyChange } = this.props;

    return (
      <Modal open={map_open} closeIcon
        dimmer={'inverted'} size={'large'} onClose={onClose}
      >

        <Header icon={'map marker alternate'} content={'Выбор адреса доставки'} />

        <Modal.Content>
          <Grid>
            <Grid.Row columns={2} >
              <Grid.Column width={8}>
                <Form>
                  <Form.Input fluid
                            label={'Имя получателя'}
                            name={'name'} type={'text'}
                            value={name} onChange={onContactInfoChange('name')}
                            placeholder={'Имя, например: Кузнецов Василий'}
                            error={name_error}
                  />

                  <Form.Group widths='equal'>

                    <Form.Input fluid width={8}
                                label={'E-mail'}
                                name={'email'} type={'text'}
                                value={email} onChange={onContactInfoChange('email')}
                                error={email_error} />
                    <Form.Input fluid width={8}
                                label={'Телефон'}
                                name={'phone'} type={'text'}
                                value={phone} onChange={onContactInfoChange('phone')}
                                error={phone_error} />
                  </Form.Group>
                </Form>
              </Grid.Column>

              <Grid.Column width={8}>
                <Form>
                  <Form.TextArea label={'Комментарий к заказу'} placeholder={'Дополнительная информация к заказу'}
                         value={comment} onChange={onContactInfoChange('comment') }
                  />
                  <Form.Group widths='equal'>
                    {(delivery_value === 'company')
                      &&  <Form.Input fluid width={4}
                                label={'Почтовый индекс'}
                                name={'post_index'} type={'text'}
                                value={post_index} onChange={onContactInfoChange('post_index')}
                                error={post_index_error} />}
                    <Form.Input fluid width={12}
                                label={'Адрес'}
                                value={delivery_address}
                                name={'address'} type={'text'}
                                onChange={onContactInfoChange('address')} />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {(delivery_value === 'pickup')
              // eslint-disable-next-line
              &&  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column width={10}>
                        <YMaps>
                          <Map width={'100%'} height={'25em'}
                               state={{ center: map_center, zoom: map_zoom }} >
                            { points.map(this._renderObject) }
                          </Map>
                        </YMaps>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <Header as={'h3'} content={'Пункты выдачи заказов, г. Тула'} />
                        <List divided relaxed selection>
                          {points.map(this._renderListItem)}
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
              // eslint-disable-next-line
              ||  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column width={10}>
                        <YMaps query={{apikey: '21e6219a-51bc-4f8e-93d3-f64dae1c76aa'}} >
                          <Map  modules={['geocode']}
                                width={'100%'} height={'35em'}
                                state={{ center: this.state.center, zoom: 16 }}
                                onClick={this.onMapClick}
                                onLoad={ymaps => {this.setState({ymaps: ymaps})}} >
                            <SearchControl
                              instanceRef={this.searchControlRef}
                              onResultShow={this.onResultShow} />
                            <Placemark geometry={this.state.geometry} properties={{iconCaption: this.state.caption || 'ищем адрес...' }} />
                          </Map>
                        </YMaps>
                      </Grid.Column>

                      <Grid.Column width={6}>
                        {(delivery_value === 'delivery')
                          // eslint-disable-next-line
                          &&  <p>
                                <span>
                                  • Укажите в соответствующем поле адрес доставки или выберите его на карте. Наш специалист свяжется с вами для уточнения деталей доставки.
                                </span>
                              </p>
                          // eslint-disable-next-line
                          ||  <div>
                                <p>
                                  <span>
                                    • Доставка по Российской Федерации осуществляется в ближайший от вас офис транспортной компании
                                  </span>
                                </p>

                                <p>
                                  <span>
                                    • стоимость услуг транспортной компании от 300 руб., <strong>не входит в первоначальную стоимость заказа</strong>
                                  </span>
                                </p>

                                <p>
                                  <span>
                                    • укажите адрес доставки или выберите его на карте, обязательно укажите почтовый индекс и наш специалист свяжется с вами для уточнения деталей и стоимости доставки
                                  </span>
                                </p>


                                  <span>
                                    Укажите услуги какой транспортной компании вы предпочитаете: <br /><br />
                                    <Checkbox checked={cdek_check} label={<label> СДЭК <a target="_blank" rel="noopener noreferrer" href="https://cdek.ru/offices">https://cdek.ru/offices</a></label>}  onChange={companyChange('cdek')} /> <br /><br />
                                    <Checkbox checked={russianpost_check} label={<label> Почта России <a target="_blank" rel="noopener noreferrer" href="https://www.pochta.ru/offices/">https://www.pochta.ru/offices/</a></label>} onChange={companyChange('russianpost')} />
                                  </span>

                              </div>
                        }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
          }



        </Modal.Content>

        <Modal.Actions>
          <Button primary onClick={onClose} >
            {'Выбрать'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}


export default DeliveryMap;