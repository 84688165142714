import * as types from '../../../actions/action-types'


export function setAppDimmedState(data) {
  return {
    type: types.SET_APP_DIMMED,
    data
  }
}

export function setOrderStepsActiveStep(data) {
  return {
    type: types.SET_ORDERSTEPS_ACTIVE_STEP,
    data
  }
}

export function setOrderStepsDisableOrder(data) {
  return {
    type: types.SET_ORDERSTEPS_DISABLE_ORDER,
    data
  }
}


export function setOrderStepsItems(data) {
  return {
    type: types.SET_ORDERSTEPS_ITEMS,
    data
  }
}


export function setOrderStepsDisabled(data) {
  return {
    type: types.SET_ORDERSTEPS_DISABLED,
    data
  }
}
