import * as types from '../../../actions/action-types'


const initialState = {
  OrderStepsData: {
    items: [
      { key: 0, disabled: false, active: false,
        link: true, onClick: () => {},
        icon: 'search', title: 'Поиск', description: 'Поиск по прайсу',
        step: 'search' },
      { key: 1, disabled: false, active: false,
        link: true, onClick: () => {},
        icon: 'truck', title: 'Каталоги', description: 'Оригинальные запчасти',
        step: 'catalogue1' },
      { key: 2, disabled: false, active: false,
        link: true, onClick: () => {},
        icon: 'wrench', title: 'Каталоги', description: 'ТО',
        step: 'catalogue2' },
      { key: 3, disabled: false, active: false,
        link: true, onClick: () => {},
        icon: 'shopping basket', title: 'Корзина', description: '0 поз.',
        step: 'basket' },
      { key: 4, disabled: false, active: false,
        link: true, onClick: () => {},
        icon: 'info circle', title: 'Заказ', description: 'Проверка состояния',
        step: 'orderstate' },
    ],

    active: 'menu',
    disabled: ['basket', 'order'],
  }
}


const OrderStepsReducer = function(state=initialState, action) {
  switch(action.type) {
    case types.SET_ORDERSTEPS_ACTIVE_STEP:
      return Object.assign({}, state, {
        OrderStepsData: Object.assign({}, state.OrderStepsData, {
          active: action.data
        })
      });

    case types.SET_ORDERSTEPS_DISABLE_ORDER:
      return Object.assign({}, state, {
        OrderStepsData: Object.assign({}, state.OrderStepsData, {
          disable_for_order: action.data
        })
      });

    case types.SET_ORDERSTEPS_ITEMS:
      return Object.assign({}, state, {
        OrderStepsData: Object.assign({}, state.OrderStepsData, {
          items: action.data
        })
      });

    case types.SET_ORDERSTEPS_DISABLED:
      return Object.assign({}, state, {
        OrderStepsData: Object.assign({}, state.OrderStepsData, {
          disabled: action.data
        })
      });

    default: return state;
  }
}


export default OrderStepsReducer;
