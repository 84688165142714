import React, { Component } from 'react';

import { Checkbox, Container, Dropdown, Form, Grid,
         Icon, Input, Table } from 'semantic-ui-react'


const priceOptions = [
  { key: 'price', value: 'price', text: 'SALE_PRICE' },
  { key: 'price_1', value: 'price_1', text: 'PRICE_1' },
  { key: 'price_2', value: 'price_2', text: 'PRICE_2' },
  { key: 'price_3', value: 'price_3', text: 'PRICE_3' },
  { key: 'price_4', value: 'price_4', text: 'PRICE_4' },
  { key: 'price_5', value: 'price_5', text: 'PRICE_5' },
  { key: 'price_6', value: 'price_6', text: 'PRICE_6' },
  { key: 'price_7', value: 'price_7', text: 'PRICE_7' },
  { key: 'price_8', value: 'price_8', text: 'PRICE_8' },
  { key: 'price_9', value: 'price_9', text: 'PRICE_9' },
]


class SettingsView extends Component {

  state = {
    addGroup: ''
  }

  _renderCategory = (item, i) => (
    <Table.Row key={i} >
      <Table.Cell textAlign={'right'}>{i + 1}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.name}</Table.Cell>
      <Table.Cell textAlign={'center'}>
        <Checkbox slider checked={item.visible} onChange={this.props.onChangeVisible('categories', item)} />
      </Table.Cell>
      <Table.Cell textAlign={'left'} >
        <Dropdown placeholder='Доп. группа' search selection options={this.props.addGroupOptions.filter(item => item.is_model)}
                  onChange={this.props.changeAddCategory(item)}
                  defaultValue={item.add_category}
                  />
      </Table.Cell>
    </Table.Row>)

  _renderGroup = (item, i) => (
    <Table.Row key={i} >
      <Table.Cell textAlign={'right'}>{i + 1}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.name}</Table.Cell>
      <Table.Cell textAlign={'center'}>
        <Checkbox slider checked={item.visible} onChange={this.props.onChangeVisible('groups', item)} />
      </Table.Cell>
      <Table.Cell textAlign={'left'}>
        <Dropdown placeholder='Доп. группа' search selection options={this.props.addGroupOptions.filter(item => !item.is_model)}
                  onChange={this.props.changeAddGroup(item)}
                  defaultValue={item.add_group}
                  />
      </Table.Cell>
    </Table.Row>)

  _renderAddGroup = (item, i) => (
    <Table.Row key={i} >
      <Table.Cell textAlign={'right'}>{i + 1}</Table.Cell>
      <Table.Cell textAlign={'left'}>{item.name}</Table.Cell>
      <Table.Cell textAlign={'center'}>
        <Checkbox slider checked={item.is_model} onChange={this.props.updateAddGroup(item)} />
      </Table.Cell>
      <Table.Cell textAlign={'center'}>
        <Icon link name='close' onClick={this.props.delAddGroup(item)} />
      </Table.Cell>
    </Table.Row>)

  render () {
    return (
      <Container>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={5}>
              <Input
                action={{ color: 'teal', icon: 'plus', onClick: this.props.addAddGroup(this.state.addGroup) }}
                actionPosition='right'
                placeholder='Добавить группировку...'
                onChange={(e, {value}) => {this.setState({addGroup: value})}}
              />
              <Table compact={'very'} >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign={'center'} width={1}>{'#'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} >{'Группа прайса'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} >{'Модель'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} width={1}>{''}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.add_groups.map(this._renderAddGroup)}
                </Table.Body>
              </Table>
            </Grid.Column>

            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Field>
                    <label>Цена (самовывоз)</label>
                    <Dropdown
                      value={this.props.price_type}
                      disabled={!this.props.price_type}
                      onChange={this.props.onPriceChange}
                      placeholder='Категория цен'
                      search
                      selection
                      options={priceOptions}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Цена (доставка)</label>
                    <Dropdown
                      value={this.props.delivery_price_type}
                      disabled={!this.props.delivery_price_type}
                      onChange={this.props.onDeliveryPriceChange}
                      placeholder='Категория цен'
                      search
                      selection
                      options={priceOptions}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <Table compact={'very'} >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign={'center'} width={1}>{'#'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} >{'Модель'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} width={1}>{<Icon name='eye' />}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'}>{''}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.categories.map(this._renderCategory)}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table compact={'very'} >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign={'center'} width={1}>{'#'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} >{'Группа прайса'}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} width={1}>{<Icon name='eye' />}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'left'} >{''}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.groups.map(this._renderGroup)}
                </Table.Body>
              </Table>
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}


export default SettingsView;
