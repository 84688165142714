import React, { Component } from 'react';

import { Button, Divider, Icon, Header, Message, Modal } from 'semantic-ui-react'

import ResultTable from './resulttable/container';

import { storeItemToBasket } from '../../../api/functions';


class TehkomSearchView extends Component {

  _message = () => {
    switch (this.props.state) {
      case 'search':
        return  <Message info>
                  <Icon name='info circle' size='big' />
                  Идёт поиск в прайс-листе. Пожалуйста, подождите.
                </Message>
      case 'result':
        return  <Message warning>
                  <Icon name='warning circle' size='big' />
                  Поиск завершён. Совпадения с оригинальным номером не найдены.
                </Message>
      case 'error':
        return  <Message error>
                  <Icon name='warning circle' size='big' />
                  Произошла ошибка. Повторите запрос позднее.
                </Message>
      default: return <div />
    }
  };

  _prepare = () => this.props.result.map(item => {
    if ((this.props.article !== item.article) && (this.props.article !== item.werk_article)) {
      item.just_about = 1;
    } else {
      item.just_about = 0;
    }
    return item;
  }).sort((a, b) => a.just_about - b.just_about);

  render () {
    const { is_open, article, result, similar } = this.props;
    const { onClickClose, goToBasket } = this.props;

    const style = {
      position: 'absolute',
      top: '30px',
    }

    return (
      <Modal open={is_open} style={style}>
        <Header icon='search' content={`Поиск по артикулу: ${article}`} />
        <Modal.Content scrolling>
          {
            result.length
            ? <ResultTable rows={this._prepare()} onClick={storeItemToBasket} t={'result'} />
            : this._message()
          }
          {this._prepare().length > 0
            &&  <Message size={'tiny'}>
                  <Icon size={'small'} name='asterisk' />
                  {'Цены указаны с учётом самовывоза заказа из пункта выдачи, при выборе другого способа доставки стоимость заказа может быть увеличена'}
                </Message>}
          {this._prepare().filter(item => item.just_about).length > 0
            &&  <Message size={'tiny'}>
                  <Icon size={'small'} name='asterisk' />
                  <Icon size={'small'} name='asterisk' />
                  {'Результат поиска содержит позиции не полностью совпадающие с запросом. Вы можете уточнить соответствие по тел. +7(910)16-44-656.'}
                </Message>}
          {
            similar.length
            ? <div>
                <Divider />
                <Header as='h3'
                  icon='question circle'
                  content={// eslint-disable-next-line
                    this.props.adv_crosses && 'Аналоги (заменители) для артикула' || 'Возможно вы искали'} />
                <ResultTable rows={similar} onClick={storeItemToBasket} t={'similar'} />
              </div>
            : <div />
          }
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' inverted onClick={goToBasket}>
            <Icon name='clipboard check' /> {'Перейти к корзине'}
          </Button>
          <Button color='green' inverted onClick={onClickClose}>
            <Icon name='arrow down cart' /> {'Продолжить покупки'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}


export default TehkomSearchView;
