import React, { Component } from 'react';

import { Header, Icon } from 'semantic-ui-react'

import OrdersForm from '../forms/ordersform'
import OrdersTable from '../tables/orderstable'

import ModalOrderEditor from './modalordereditor';


class OrdersView extends Component {

  render () {
    return (
      <div>
        <Header as='h4' color={'grey'} >
          <Icon name='cog' />
          <Header.Content>
            {'Заказы'}
            <Header.Subheader>
              {'Список заказов'}
            </Header.Subheader>
          </Header.Content>
        </Header>

        <OrdersForm
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          changeDate={this.props.changeDate}
          state_options={this.props.state_options}
          current_state_filter={this.props.current_state_filter}
          text_filter={this.props.text_filter}
          current_point_filter={this.props.current_point_filter}
          changeFilter={this.props.changeFilter}
          point_options={this.props.point_options}
          payment_options={this.props.payment_options}
          current_payment_filter={this.props.current_payment_filter}
          delivery_options={this.props.delivery_options}
          current_delivery_filter={this.props.current_delivery_filter}

          onExportClick={this.props.onTableExportClick}
          export_disabled={this.props.orders.length === 0} />

        <OrdersTable
          rows={this.props.orders}
          activePage={this.props.activePage}
          perPage={this.props.perPage}
          totalPages={this.props.totalPages}
          changePage={this.props.changePage}

          delivery_point={this.props.delivery_point}

          onRowClick={this.props.onOrderClick}
          state_options={this.props.state_options}
        />

        <ModalOrderEditor
          open={this.props.open_editor}
          onClose={this.props.onCloseEditor}
          item={this.props.selected_order}
          selected={this.props.selected}
          itemChecked={this.props.itemChecked}

          state_options={this.props.state_options}
          stateChange={this.props.stateChange}

          onPrintClick={this.props.onPrintClick}

          delivery_point={this.props.deliveryPoint}

          selected_payment_value={this.props.selected_payment_value}
          selected_delivery_value={this.props.selected_delivery_value}
          selected_delivery_address={this.props.selected_delivery_address}
          selected_cdek={this.props.selected_cdek}
          selected_russianpost={this.props.selected_russianpost}
          selected_check_delivery_payment={this.props.selected_check_delivery_payment}
          delivery_payment={this.props.delivery_payment}
          track_number={this.props.track_number}

          selectedChangeOptions={this.props.selectedChangeOptions}
          companyChange={this.props.companyChange}
        />

      </div>
    )
  }
}


export default OrdersView;
