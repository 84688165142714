import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppView from './component';
import * as ws from '../../api/connections';
import store from '../../store';
import { setTehkomSearchArticle, setTehkomSearchBrand,
         setTehkomSearchCrosses } from '../catalogue/tehkomsearch/actions';
import { setOrderStepsActiveStep } from '../ordersteps/actions/actions';

import Catalogue from '../catalogue/container';
import Search from '../search/container';
import Basket from '../new_basket/container';
import CheckOrder from '../checkorder/container';
import GlobalMenu from '../global_menu/container';


class App extends Component {

  componentDidMount() {
    ws.doSend({command: 'getVersion'});
    ws.doSend({
      command: 'getSettingsParamsValue',
      params: {
        params: ['chat_enable', ],
      },
    });

    /*
    if (this.props.article || this.props.brand || this.props.crosses) {
      store.dispatch(setOrderStepsActiveStep('search'));
    };
    */

    if (this.props.article) {
      store.dispatch(setTehkomSearchArticle(this.props.article));
    }
    if (this.props.brand) {
      store.dispatch(setTehkomSearchBrand(this.props.brand));
    }
    if (this.props.crosses) {
      store.dispatch(setTehkomSearchCrosses(this.props.crosses));
    }

    if (this.props.uuid) {
      store.dispatch(setOrderStepsActiveStep('orderstate'));
    }
    ws.doSend({
      command: 'getOrderStates',
      params: {}
    })


  };

  componentDidUpdate (prevProps) {
    const pParams = prevProps.params_data.params;
    const params = this.props.params_data.params;

    // Jivo
    if (pParams.chat_enable !== params.chat_enable) {
      var jv = document.getElementById('jivo-chat');
      if (!jv) {
        jv = document.createElement('script');
        jv.id = 'jivo-chat';
      }

      if (params.chat_enable === 'true') {
        jv.src = `//code-ya.jivosite.com/widget/k9FhNyd8dq`;
        jv.async = true;
      } else {
        jv.src = '';
      }

      document.getElementsByTagName('head')[0].appendChild(jv);
    }
  };

  _component = active => {
    switch(active) {
      case 'catalogue1':
        return <Catalogue content='Acat' fullscreen={'true'} />
      case 'catalogue2':
        // return <Catalogue content='CatalogsParts' fullscreen={'true'} />
        return <Catalogue content='GetCatNet' fullscreen={'true'} />
      case 'search':
        return <Search />
      case 'basket':
        return <Basket />
      case 'orderstate':
        return <CheckOrder uuid={this.props.uuid} email={this.props.email} />

        default: return <GlobalMenu />;
      }
    };

  // eslint-disable-next-line
  _fullscreen = () => this.props.active.indexOf('catalogue') !== -1 && true || false;

  render () {
    const { dimmed } = this.props.data;
    return (
      <AppView dimmed={dimmed} content={this._component(this.props.active)}
               fullscreen={this._fullscreen()}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
    active: store.OrderStepsState.OrderStepsData.active,
    params_data: store.AdminAppState.AdminAppData,
  }
}


export default connect(mapStateToProps)(App);
