import React, { Component } from 'react';

import { Step } from 'semantic-ui-react'


class OrderStepsView extends Component {

  render () {
    const { items } = this.props;

    return (
        <Step.Group items={items} />
    )
  }
}


export default OrderStepsView;
