import React, { Component } from 'react';

import { YMInitializer } from 'react-yandex-metrika';

import { isMobile, withOrientationChange } from 'react-device-detect';

import { Button, Grid, Header, Icon, Modal } from 'semantic-ui-react';

import App from '../../containers/app/container';
import AppHeader from '../../containers/header/container';
import AppFooter from '../../containers/footer/container';
import ModalViewer from '../../containers/modal_viewer/container';
import ModalReview from '../../containers/review/container';

import { setOrderStepsActiveStep } from '../../containers/ordersteps/actions/actions';
import { setSearchAttrStartGroup } from '../../containers/search/categorysearch/attributelist/actions';
import { setAppSearchActiveIndex } from '../../containers/app/actions/actions';
import { setTehkomSearchNeedCloseWindow } from '../../containers/catalogue/tehkomsearch/actions';

import store from '../../store';


const style = {
  backgroundColor: '#f1f5f9',
  overflowX: 'visible',
}


class MainLayout extends Component {

  state = {
    orientattion_warning: false,
  }

  componentDidMount() {

    this.setState({
      orientattion_warning: isMobile,
    });

    // Прямые ссылки на категории
    if (this.props.location.pathname.indexOf('/direct') !== -1) {
      const title = this.props.location.pathname.split('/direct/')[1];

      if (['ВАЗ', 'ГАЗ', 'УАЗ', 'Иномарки'].indexOf(title) !== -1) {
        switch (title) {
          case 'ВАЗ': store.dispatch(setSearchAttrStartGroup(16)); break;
          case 'ГАЗ': store.dispatch(setSearchAttrStartGroup(26)); break;
          case 'УАЗ': store.dispatch(setSearchAttrStartGroup(27)); break;
          case 'Иномарки': store.dispatch(setSearchAttrStartGroup(17)); break;

          default: break;
        }

        store.dispatch(setAppSearchActiveIndex(1));
        store.dispatch(setOrderStepsActiveStep('search'));
      }
    }
  };

  render () {

    var article = null;
    var crosses = null;
    var brand = null;
    if (this.props.location.pathname.indexOf('/search') !== -1) {
      store.dispatch(setTehkomSearchNeedCloseWindow(true));

      this.props.location.search
        .slice(1).split('&')
        .filter(item => item.split('=')[0])
        .forEach(item => {
          crosses = item.split('=')[0] === 'pnumber' ? false : true;
          if (['pnumber', 'tnumber'].indexOf(item.split('=')[0]) !== -1) {
            article = item.split('=')[1].split('%20').join(' ');
          }
          if (item.split('=')[0] === 'brand') {
            brand = item.split('=')[1];
          }
        })
    }

    var uuid = '';
    var email = '';
    if (this.props.location.pathname.indexOf('/order/') !== -1) {
      uuid = this.props.location.pathname.split('/order/')[1].split('/')[0];
      email = this.props.location.pathname.split('/order/')[1].split('/')[1];
    }


    return (
      <div style={style}>

        <div>
          <YMInitializer accounts={[65496250]}
                         options={{
                           webvisor: true,
                           clickmap:true,
                           trackLinks:true,
                           accurateTrackBounce:true,
                         }} />
        </div>

        <AppHeader />

        <App
          brand={
            // eslint-disable-next-line
            brand && brand.toUpperCase() || brand}
          article={
            // eslint-disable-next-line
            article && article.toUpperCase() || article}
          crosses={crosses} uuid={uuid} email={email} />

        <ModalViewer />
        <ModalReview />
        <AppFooter />

        <Modal open={this.state.orientattion_warning} basic size='mini'>
          <Header icon='mobile alternate' content='ПОВЕРНИТЕ ВАШЕ УСТРОЙСТВО' />
          <Modal.Content>
            <Grid>
              <Grid.Row columns={1} >
                <Grid.Column width={12}>
                  <p>
                    Для комфортной работы с сайтом на мобильных устройствах поверните ваш гаджет в горизонтальное положение.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Grid>
              <Grid.Row columns={1} >
                <Grid.Column width={8}>
                  <Button color='green' inverted onClick={() => {this.setState({orientattion_warning: false});}} >
                    <Icon name='checkmark' />
                    {'В магазин'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}


export default withOrientationChange(MainLayout);
