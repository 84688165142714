import React, { Component } from 'react';

import { Button, Icon, Modal } from 'semantic-ui-react';


class ModalViewerView extends Component {
  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content image>
          <iframe
            title={this.props.title}
            src={this.props.link} width="900" height="700"></iframe>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.props.onClose} >
            {'Прочитано'} <Icon name='check' />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
};


export default ModalViewerView;
