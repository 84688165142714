import * as types from '../../../actions/action-types'


export function setAppDimmedState(data) {
  return {
    type: types.SET_APP_DIMMED,
    data
  }
}


export function setAppGlobalMenu(data) {
  return {
    type: types.SET_APP_GLOBAL_MENU,
    data
  }
}


export function setAppGlobalMenuItems(data) {
  return {
    type: types.SET_APP_GLOBAL_MENU_ITEMS,
    data
  }
}


export function setAppAcatExt(data) {
  return {
    type: types.SET_APP_ACAT_EXT,
    data
  }
}


export function setAppSearchActiveIndex(data) {
  return {
    type: types.SET_APP_SEARCH_ACTIVE_INDEX,
    data
  }
}


export function setAppGmenuPopupOpen(data) {
  return {
    type: types.SET_APP_GMENU_POPUP_OPEN,
    data
  }
}


export function setAppHideAttrList(data) {
  return {
    type: types.SET_APP_HIDE_ATTR_LIST,
    data
  }
}


export function setAppHideGroupList(data) {
  return {
    type: types.SET_APP_HIDE_GROUP_LIST,
    data
  }
}
