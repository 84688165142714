import * as types from '../../../actions/action-types'


export function setTehkomSearchIsOpen(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_IS_OPEN,
    data
  }
}


export function setTehkomSearchArticle(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_ARTICLE,
    data
  }
}


export function setTehkomSearchBrand(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_BRAND,
    data
  }
}


export function setTehkomSearchCrosses(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_CROSSES,
    data
  }
}


export function setTehkomSearchState(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_STATE,
    data
  }
}


export function setTehkomSearchResult(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_RESULT,
    data
  }
}


export function setTehkomSearchSimilar(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_SIMILAR,
    data
  }
}


export function setTehkomSearchAdvCrosses(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_ADV_CROSSES,
    data
  }
}


export function setTehkomSearchNeedCloseWindow(data) {
  return {
    type: types.SET_TEHKOM_SEARCH_NEED_CLOSE_WINDOW,
    data
  }
}
