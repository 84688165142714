import React, { Component } from 'react';

import { Icon, Button, Form, Header, Table } from 'semantic-ui-react';
import { Dimmer, Loader } from 'semantic-ui-react';

// import { checkItemInBasket } from '../../../../api/functions';


class ResultTableView extends Component {

  quantityFormatter = row => {
    /*
    var color = 'grey';

    switch (row.quantity) {
      case 1:
        color = 'red';
        break;
      case 2:
        color = 'orange';
        break;
      case 3:
        color = 'teal';
        break;
      default: break;
    }

    return row.quantity ? <Icon color={color} name='signal' /> : <div />
    */
    return row.quantity_real;
  }

  priceFormatter = row => row.price ? row.price.toFixed(2) : '--.--';

  shopFormatter = (row, i, onButtonClick) => {
    return (
      <Button icon onClick={e => {(row.checked < row.quantity_real) && onButtonClick(row, i, this.props.t);}}
              labelPosition={'right'} >
        <Icon name={row.checked ? 'check' : 'shop'} />
        {row.checked}
      </Button>
    )
  }

  _formatName = name => {
    if (this.props.filter_name && this.props.filter_name.length >= 3) {
      const res = [];
      const name_split = name.split(this.props.filter_name);
      name_split
        .forEach((item, i) => {
          if (i === (name_split.length - 1)) {
            res.push(item);
          } else {
            res.push(item);
            res.push(this.props.filter_name);
          }
        });

      return (
        <div>
          {res.map((item, key) => {
            // eslint-disable-next-line
            return item === this.props.filter_name && <span key={key} style={{fontWeight: 'bold', color: 'teal'}}>{item}</span> || <span key={key}>{item}</span>
          })}
        </div>
      );
    } else {
      return name;
    }
  };

  _formatArticle = article => {
    if (this.props.filter_art && this.props.filter_art.length >= 3) {
      const res = [];
      const name_split = article.split(this.props.filter_art);
      name_split
        .forEach((item, i) => {
          if (i === (name_split.length - 1)) {
            res.push(item);
          } else {
            res.push(item);
            res.push(this.props.filter_art);
          }
        });

      return (
        <div>
          {res.map((item, key) => {
            // eslint-disable-next-line
            return item === this.props.filter_art && <span key={key} style={{fontWeight: 'bold', color: 'teal'}}>{item}</span> || <span key={key}>{item}</span>
          })}
        </div>
      );
    } else {
      return article;
    }
  };

  renderRow = (row, i, onClick) => {
    return (
        // eslint-disable-next-line
        <Table.Row key={i} style={{backgroundColor: (this.props.t === 'similar' || row.crosses) && '#fffde7' || (this.props.t === 'result' && (row.just_about && '#fffde7' || '#E0F2F1') || 'white')}} >
          <Table.Cell textAlign={'right'} width={3}>
            <Header as={'h5'}>
              <Header.Content>
                { // eslint-disable-next-line
                  `${row.article} ${row.just_about && '**' || ''}`}
                <Header.Subheader><span style={{color: 'teal'}}>{row.werk_article}</span></Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell textAlign={'left'} width={8} >{this._formatName(row.name)}</Table.Cell>
          <Table.Cell textAlign={'left'}>{row.producer}</Table.Cell>
          <Table.Cell textAlign={'right'}>{this.priceFormatter(row)}</Table.Cell>
          <Table.Cell textAlign={'center'}>{this.quantityFormatter(row)}</Table.Cell>
          <Table.Cell textAlign={'center'}>{row.unit}</Table.Cell>
          <Table.Cell textAlign={'center'}>{this.shopFormatter(row, i, onClick)}</Table.Cell>
        </Table.Row>
    )
  }

  render () {
    const { rows, onClick = () => {} } = this.props;

    return (
      <div>
        <Dimmer active={this.props.dimmer} inverted>
          <Loader inverted>{'Идёт запрос данных...'}</Loader>
        </Dimmer>
      <Table compact size={'small'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign={'center'} width={3} >
              <Header as={'h5'}>
                <Header.Content>
                  {'Арт. OEM'}
                  <Header.Subheader><span style={{color: 'teal'}}>{'Арт. завода'}</span></Header.Subheader>
                </Header.Content>
              </Header>
              <Form>
                <Form.Input
                  fluid icon='filter' placeholder='Фильтр...'
                  value={this.props.filter_art}
                  onChange={this.props.changeFilterArt}
                />
              </Form>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'} width={8}>
              <Header as={'h5'}>
                <Header.Content>
                  {'Наименование'}
                  <Header.Subheader>{'.'}</Header.Subheader>
                </Header.Content>
              </Header>
              <Form>
                <Form.Input
                  fluid icon='filter' placeholder='Фильтр...'
                  value={this.props.filter_name}
                  onChange={this.props.changeFilterName}
                />
              </Form>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>{'Произв.'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>{'Цена'} {<Icon name='ruble'/>} {'*'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>{'Наличие'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>{'Ед.'}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>{''}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map((row, i) => this.renderRow(row, i, onClick))}
        </Table.Body>
      </Table>
      </div>
    )
  }
}


export default ResultTableView;
