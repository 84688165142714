import * as types from '../../actions/action-types';


export function setModalReviewOpen(data) {
  return {
    type: types.SET_MODAL_REVIEW_OPEN,
    data
  };
}


export function setModalReviewState(data) {
  return {
    type: types.SET_MODAL_REVIEW_STATE,
    data
  };
}
