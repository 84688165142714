import React, { Component } from 'react';
import { connect } from 'react-redux';

// import * as ws from '../../api/connections';
import store from '../../store';
import { setRulesOpen } from './actions';

import RulesView from './component';


class Rules extends Component {

  _onClose = () => {
    store.dispatch(setRulesOpen(false));
  };



  render () {
    return (
      <RulesView
        info={this.props.info}

        open={this.props.data.open}
        text={this.props.data.text}
        onClose={this._onClose}

        agreeHandler={this.props.agreeHandler}
        disagreeHandler={this.props.disagreeHandler}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.RulesState.RulesData
  }
}


export default connect(mapStateToProps)(Rules);
