import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import * as ws from '../../api/connections';
import store from '../../../store';
import { setBasketConfirmOpen } from '../actions';
import { setOrderStepsActiveStep } from '../../ordersteps/actions/actions';

import ConfirmView from './component';


class Confirm extends Component {

  state = {
  };

  componentDidMount = () => {
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if ((this.props.data.confirm_state === 'success') && (nextProps.data.confirm_state === 'error')) {
      return false;
    }
    return true;
  };

  onClose = () => {
    store.dispatch(setBasketConfirmOpen(false));
  };

  _stateConfirm = () => {
    if (this.props.data.confirm_state === 'blocked') {
      return {
        header: 'Обработка',
        content: 'Подождите немного, идёт обработка'
      }
    } else if (this.props.data.confirm_state === 'error') {
      return {
        header: 'Ошибка',
        content: 'Извините, произошла ошибка при сохранении заказа. Подождите некоторое время и повторите заказ.'
      }
    } else if (this.props.data.confirm_state === 'success') {
      return {
        header: '',
        content: <div><span>Вашему заказу присвоен номер</span><br /><span>{this.props.data.confirm_order.order}</span><br /><span>он принят в обработку, информация направлена на указаный e-mail. Оплатить заказ можно будет после получения электронного письма "ЗАКАЗ ОЖИДАЕТ ОПЛАТЫ" перейдя по по ссылке "ОПЛАТИТЬ ЗАКАЗ" или проверив его состояние на вкладке "Заказ. Проверка состояния"</span><br /></div>
      }
    } else {
      return {
        header: '', content: ''
      }
    }
  };

  _stateIcon = () => {
    if (this.props.data.confirm_state === 'blocked') {
      return {
        name: 'circle notched',
        loading: true
      }
    } else if (this.props.confirm_state === 'error') {
      return {
        name: 'warning',
        loading: false
      }
    } else if (this.props.confirm_state === 'success') {
      return {
        name: 'info',
        loading: false
      }
    } else {
      return {
        name: 'info',
        loading: false
      }
    }
  };

  _goToOrder = () => {
    this.onClose();
    store.dispatch(setOrderStepsActiveStep('orderstate'));
    this.props.history.push(`/order/${this.props.data.confirm_order.order}/${this.props.data.confirm_order.details.personal.email}`);
  }


  render () {
    return (
      <ConfirmView
        open={this.props.data.confirm_open}
        onClose={this.onClose}

        goToOrder={this._goToOrder}

        blocked={this.props.data.confirm_state === 'blocked'}
        header={this._stateConfirm().header}
        content={this._stateConfirm().content}

        warning={this.props.data.confirm_state === 'blocked'}
        error={this.props.data.confirm_state === 'error'}
        success={this.props.data.confirm_state === 'success'}
        icon_message={this._stateIcon()}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.BasketState.BasketData
  }
}


export default connect(mapStateToProps)(withRouter(Confirm));
