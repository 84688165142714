import React, { Component } from 'react';

import { Form } from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import DatepickerButton from '../../misc/datepickerbutton/container';


class OrdersForm extends Component {

  render () {
    const { startDate, endDate, changeDate = () => {},
            state_options, export_disabled, payment_options } = this.props;

    return (
      <Form>
        <Form.Group widths='equal'>
          <Form.Field width={3} >
            <label>Дата заказа с</label>
              <DatePicker
                customInput={<DatepickerButton />}
                selected={startDate}
                dateFormat='DD MMM, YYYY'
                onChange={changeDate('start')}
                locale={'ru'} />
          </Form.Field>
          <Form.Dropdown
            search selection width={4}
            label={'Фильтр по состоянию'}
            options={state_options}
            value={this.props.current_state_filter}
            onChange={this.props.changeFilter('state')}
          />
          <Form.Dropdown
            search selection width={4}
            label={'Фильтр по точкам'}
            options={this.props.point_options}
            value={this.props.current_point_filter}
            onChange={this.props.changeFilter('point')}
          />

          <Form.Button width={3} disabled={export_disabled}
            label='.'
            color={'teal'}
            icon={'file excel'}
            content={'в Excel'}
            onClick={this.props.onExportClick}
          />

          <Form.Field width={2} ></Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>

          <Form.Field width={3}>
            <label>Дата заказа по</label>
              <DatePicker
                customInput={<DatepickerButton />}
                selected={endDate}
                dateFormat='DD MMM, YYYY'
                onChange={changeDate('end')}
                locale={'ru'} />
          </Form.Field>

          <Form.Input
            width={4} icon={'filter'}
            label={'Фильтр (номер, имя, e-mail, телефон)'}
            value={this.props.text_filter}
            onChange={this.props.changeFilter('common')}
          />

          <Form.Dropdown
            search selection width={4}
            label={'Фильтр по оплате'}
            options={payment_options}
            value={this.props.current_payment_filter}
            onChange={this.props.changeFilter('payment')}
          />

          <Form.Dropdown
            search selection width={4}
            label={'Фильтр по типу доставки'}
            options={this.props.delivery_options}
            value={this.props.current_delivery_filter}
            onChange={this.props.changeFilter('delivery')}
          />

          <Form.Field width={1} ></Form.Field>

        </Form.Group>
      </Form>
    )
  }
}


export default OrdersForm;
