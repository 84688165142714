import React, { Component } from 'react';

import { Container, Form, Grid, Header, Icon, Table } from 'semantic-ui-react'
import { Button, Message, Modal } from 'semantic-ui-react'



class BaseUpdateView extends Component {


  render () {
    const { open_modal, state, onClose } = this.props;

    const style = {
      position: 'absolute',
      top: '30%'
    }

    const m_header = state.state === null
      // eslint-disable-next-line
      && 'Загрузка обновления'
      // eslint-disable-next-line
      || state.state === 'success' && 'Обновление загружено' || 'Ошибка';

    // eslint-disable-next-line
    const m_content = state.state === null && 'Подождите некоторое время, идёт загрузка файла обновления.'
      // eslint-disable-next-line
      || (state.state === 'success' && !state.update_lock) && `Применяется обновление: ${state.quantity} остатков, ${state.groups} групп, ${state.attributes} атрибутов, ${state.vendors} вендоров. Обновление остатков может занять до 15 минут.`
      // eslint-disable-next-line
      || (state.state === 'success' && state.update_lock) && `Система занята обновлением предыдущих данных. Если вы хотите загрузить новые данные, повторите ппоытку через несколько минут.`
      // eslint-disable-next-line
      || 'В процессе обновления возникли ошибки';

    return (
      <Container>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as='h4' color={'grey'} >
                <Icon name='upload' />
                <Header.Content>
                  {'Обновление'}
                  <Header.Subheader>
                    {'Обновление базы'}
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Input type='file' onChange={this.props.selectUploadFile} />
                  <Form.Button
                    color={'teal'}
                    icon={'upload'}
                    content={'Загрузить'}
                    onClick={this.props.uploadFile} />
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Table>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal closeIcon style={style}
               dimmer={'inverted'} size={'tiny'}
               open={open_modal} >
        <Header icon={'upload'} content={'Обновление данных'} />
        <Modal.Content>
          <Message icon
                   success={state.state === 'success'} negative={state.state === 'error'} >
            <Icon name={// eslint-disable-next-line
                        state.state === null && 'spinner' || 'info'}
                  loading={state.state === null} />
              <Message.Content>
                <Message.Header>{m_header}</Message.Header>
                  {m_content}
              </Message.Content>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={onClose}>
            {'Закрыть'}
          </Button>
        </Modal.Actions>
      </Modal>
      </Container>
    )
  }
}


export default BaseUpdateView;
