import React, { Component } from 'react';
import { connect } from 'react-redux';

import SearchResultView from './component';

import store from '../../../../store';
import { setSearchResultFilter } from './actions';


class SearchResult extends Component {

  state = {
    filter_art: '',
    filter_name: '',
    activePage: 1,
    totalPages: -1,
  };

  _changeFilterArt = (e, {value}) => {
    this.setState({filter_art: value.toUpperCase()})
  };

  _changeFilterName = (e, {value}) => {
    this.setState({filter_name: value.toUpperCase()})
  };

  componentDidMount = () => {
    store.dispatch(setSearchResultFilter(''));
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.in_progress && this.props.in_progress === true) {
      this.setState({filter_art: '', filter_name: ''})
    }
  };

  _onPageChange = (e, {activePage}) => {
    // store.dispatch(setSearchResultActivePage(activePage));
    this.setState({activePage})
  };

  _rows = () => {
    const rows = this.props.rows
      .filter(item => !this.state.filter_art || (item.article.indexOf(this.state.filter_art) !== -1 || item.werk_article.indexOf(this.state.filter_art) !== -1))
      .filter(item => !this.state.filter_name || (item.name.indexOf(this.state.filter_name) !== -1));

    if (this.state.totalPages !== Math.ceil(rows.length / 9)) {
      this.setState({activePage: 1, totalPages: Math.ceil(rows.length / 9)});
    }
    return rows.slice((this.state.activePage - 1) * this.props.itemsPerPage, this.state.activePage * this.props.itemsPerPage);;
  };

  render () {
    return (
      <SearchResultView
        rows={this._rows()}
        activePage={this.state.activePage}
        totalPages={this.state.totalPages}

        onFilterChange={this._onFilterChange}
        onPageChange={this._onPageChange}
        height={this.props.height}

        search_disabled={this.props.rows.length === 0}

        title={this.props.search_title}

        filter_art={this.state.filter_art}
        filter_name={this.state.filter_name}

        changeFilterArt={this._changeFilterArt}
        changeFilterName={this._changeFilterName}

        dimmer={this.props.in_progress}
      />
    )
  }
}


const mapStateToProps = store => ({
    rows: store.SearchResultState.SearchResultData.rows,
    in_progress: store.SearchResultState.SearchResultData.in_progress,
    search_title: store.SearchResultState.SearchResultData.search_title,
    filter: store.SearchResultState.SearchResultData.filter,
    activePage: store.SearchResultState.SearchResultData.activePage,
    totalPages: store.SearchResultState.SearchResultData.totalPages,
    itemsPerPage: store.SearchResultState.SearchResultData.itemsPerPage,
})

export default connect(mapStateToProps)(SearchResult);

