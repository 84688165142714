import React, { Component } from 'react';

import { Button, Icon, Input, List, Popup } from 'semantic-ui-react'


class GroupListView extends Component {

  _itemRender = (item, key) => (
    <List.Item key={key} onClick={() => this.props.onItemClick(item)} >
      <Icon
        name={ // eslint-disable-next-line
          item.additional && (item.open && 'folder open' || 'folder') || 'list' }
        color={  // eslint-disable-next-line
          item.additional && 'black' || 'blue'} />
      <List.Content>
        <List.Description  as='a'>
          <span style={{ // eslint-disable-next-line
                        fontSize: item.additional && 16 || 14,
                        // eslint-disable-next-line
                        fontWeight: item.additional && 'bold' || 'normal',
                        // eslint-disable-next-line
                        color: item.additional && 'black' || 'blue'}}>
            {item.group_name}
          </span>
        </List.Description>
      </List.Content>
    </List.Item>
  )

  render () {
    const { items } = this.props;
    const { onFilterChange } = this.props;

    const style = {
      height: '45em',
      overflow: 'auto'
    }

    return (
      <div>
        {this.props.visible
          // eslint-disable-next-line
          &&  <div>
                <Input  fluid icon='filter' placeholder='Фильтр по группам'
                        onChange={onFilterChange} />
                <List animated verticalAlign='middle' style={style} >
                  {items.map((item, key) => this._itemRender(item, key))}
                </List>
              </div>
          // eslint-disable-next-line
          ||  <Popup
                content={'Показать список групп прайса'}
                trigger={<Button icon={'chevron right'} circular onClick={this.props.showList} />}
              />}
      </div>
    )
  }
}


export default GroupListView;
