import * as types from '../../actions/action-types'


export function setRulesOpen(data) {
  return {
    type: types.SET_RULES_OPEN,
    data
  }
}


export function setRulesText(data) {
  return {
    type: types.SET_RULES_TEXT,
    data
  }
}