import * as types from '../../../../actions/action-types'


const initialState = {
  AttributeListData: {
    items: [],
    filter: '',
    start_group: 0,
  }
}


const AttributeListReducer = function(state=initialState, action) {
  switch(action.type) {

    case types.SET_SEARCH_ATTR_LIST_ITEMS:
      return Object.assign({}, state, {
        AttributeListData: Object.assign({}, state.AttributeListData, {
          items: action.data
        })
      });

    case types.SET_SEARCH_ATTR_LIST_FILTER:
      return Object.assign({}, state, {
        AttributeListData: Object.assign({}, state.AttributeListData, {
          filter: action.data
        })
      });

    case types.SET_SEARCH_ATTR_START_GROUP:
      return Object.assign({}, state, {
        AttributeListData: Object.assign({}, state.AttributeListData, {
          start_group: action.data
        })
      });

    default: return state;
  }
}


export default AttributeListReducer;
