import * as types from '../../../../actions/action-types'


export function setSearchAttrListItems(data) {
  return {
    type: types.SET_SEARCH_ATTR_LIST_ITEMS,
    data
  }
}


export function setSearchAttrListFilter(data) {
  return {
    type: types.SET_SEARCH_ATTR_LIST_FILTER,
    data
  }
}


export function setSearchAttrStartGroup(data) {
  return {
    type: types.SET_SEARCH_ATTR_START_GROUP,
    data
  }
}
