import * as types from '../../actions/action-types';


const initialState = {
  ModalReviewData: {
    open: false,
    state: 'fill'
  }
}


const ModalReviewReducer = (state=initialState, action) => {
  switch (action.type) {

    case types.SET_MODAL_REVIEW_OPEN:
      return Object.assign({}, state, {
        ModalReviewData: Object.assign({}, state.ModalReviewData, {
          open: action.data
        })
      });

    case types.SET_MODAL_REVIEW_STATE:
      return Object.assign({}, state, {
        ModalReviewData: Object.assign({}, state.ModalReviewData, {
          state: action.data
        })
      });

    default: return state;
  }
}


export default ModalReviewReducer;
