import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GlobalMenuView from './component';

import * as ws from '../../api/connections';
import store from '../../store';
import { setAppGlobalMenu, setAppAcatExt, setAppSearchActiveIndex,
         setAppGmenuPopupOpen } from '../app/actions/actions';
import { setOrderStepsActiveStep } from '../ordersteps/actions/actions';
import { setSearchAttrStartGroup } from '../search/categorysearch/attributelist/actions';
import { setTehkomSearchIsOpen, setTehkomSearchArticle,
         setTehkomSearchNeedCloseWindow } from '../catalogue/tehkomsearch/actions';


class GlobalMenu extends Component {

  _onClose = () => {
    store.dispatch(setAppGlobalMenu(false));
  };

  _onCardClick = title => () => {

    const titles = ['АКБ - Щётки - Лампы', 'Автокрепёж', 'Масла - Химия - Аксессуары'];

    if (titles.indexOf(title) === -1) {
      return;
    }

    switch (title) {
      case 'АКБ - Щётки - Лампы':
        store.dispatch(setAppSearchActiveIndex(1));
        store.dispatch(setSearchAttrStartGroup(34));
        break;
      case 'Автокрепёж':
        store.dispatch(setAppSearchActiveIndex(1));
        store.dispatch(setSearchAttrStartGroup(33));
        break;

      default:
        store.dispatch(setAppSearchActiveIndex(2));
        break;
    }

    // store.dispatch(setAppSearchActiveIndex(2));
    store.dispatch(setOrderStepsActiveStep('search'))
    this.props.history.push('/');
    this._onClose();

    store.dispatch(setAppGmenuPopupOpen(true));
  };

  _onLeftClick = title => () => {
    switch (title) {
      case 'ВАЗ': store.dispatch(setSearchAttrStartGroup(16)); break;
      case 'ГАЗ': store.dispatch(setSearchAttrStartGroup(26)); break;
      case 'УАЗ': store.dispatch(setSearchAttrStartGroup(27)); break;
      case 'Иномарки': store.dispatch(setSearchAttrStartGroup(17)); break;

      default: break;
    }

    store.dispatch(setAppSearchActiveIndex(1));
    store.dispatch(setOrderStepsActiveStep('search'))
    this.props.history.push('/');
    this._onClose();

    store.dispatch(setAppGmenuPopupOpen(true));
  };

  _onMiddleClick = title => () => {
    store.dispatch(setOrderStepsActiveStep('catalogue2'));
    this.props.history.push('/');
    this._onClose();

    store.dispatch(setAppGmenuPopupOpen(true));
  };

  _onRightClick = title => () => {
    switch (title) {
      case 'ВАЗ': store.dispatch(setAppAcatExt('/catalogs/CARS_NATIVE/VAZ')); break;
      case 'ГАЗ': store.dispatch(setAppAcatExt('/catalogs/CARS_NATIVE/GAZ')); break;
      case 'УАЗ': store.dispatch(setAppAcatExt('/catalogs/CARS_NATIVE/UAZ')); break;
      case 'Иномарки': store.dispatch(setAppAcatExt('/catalogs?type=CARS_FOREIGN')); break;

      default: break;
    }

    store.dispatch(setOrderStepsActiveStep('catalogue1'));
    this.props.history.push('/');
    this._onClose();

    store.dispatch(setAppGmenuPopupOpen(true));
  };

  _onSearchClick = article => () => {
    store.dispatch(setTehkomSearchArticle(article));
    store.dispatch(setTehkomSearchIsOpen(true));
    store.dispatch(setTehkomSearchNeedCloseWindow(false));

    ws.doSend({
      command: 'searchInPriceByArticle',
      params: {
        article,
        brand: '',
        crosses: ''
      }
    })
  };

  render () {
    return (
      <GlobalMenuView
        open={this.props.data.global_menu}
        items={this.props.data.global_menu_items}

        onCardClick={this._onCardClick}
        onLeftClick={this._onLeftClick}
        onMiddleClick={this._onMiddleClick}
        onRightClick={this._onRightClick}

        onClose={this._onClose}

        onSearchClick={this._onSearchClick} />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
  }
}

export default connect(mapStateToProps)(withRouter(GlobalMenu));
