// import React from 'react';

// import * as ws from './connections';
import store from '../store';

import { clearBasket, getItemsForBasket, setItemsToStorage } from './functions';

import {  setTehkomSearchState,
          setTehkomSearchResult,
          setTehkomSearchSimilar,
          setTehkomSearchAdvCrosses } from '../containers/catalogue/tehkomsearch/actions';

import {  setSearchAttrListItems } from '../containers/search/categorysearch/attributelist/actions';
import {  setSearchGroupListItems } from '../containers/search/groupsearch/groupelist/actions';

import {  setSearchResultRows,
          setSearchResultActivePage,
          setSearchResultTotalPages,
          setSearchResultInProgress,
          setSearchQuickSearchOptions } from '../containers/search/categorysearch/searchresult/actions';

import { setBasketConfirmState,
         setBasketItems,
         setBasketConfirmOrder,
         setBasketMapSelectedPoint } from '../containers/basket/actions/actions';

import { setOrderOrder } from '../containers/checkorder/actions';

import { setAdminAppOrders,
         // setAdminAppOrderStates,
         setAdminAppSettingsCategories, setAdminAppSettingsGroups,
         setAdminPriceType, setAdminDeliveryPriceType,
         setAdminAppSettingsAddGroups,
         setAdminParams, setAdminCrosses,
         setAdminOrderXlsxLink, setAdminReportXlsxLink } from '../containers/admin/actions';

import { setAdminUploadState } from '../containers/admin/actions';

import { setAdminAuth, setAdminNeedAuth } from '../containers/admin/actions';

import { setModalReviewState } from '../containers/review/actions';


export function handler(data) {

  switch(data.command) {

    case 'getVersion':
      getVersion(data);
      break;

    case 'searchInPriceByArticle':
        searchInPriceByArticle(data);
        break;

    case 'getDetailAttributes':
      getDetailAttributes(data);
      break;

    case 'getDetailsByAttribute':
      getDetailsByAttribute(data);
      break;
    case 'getDetailsByGroup':
      getDetailsByAttribute(data);
      break;

    case 'getFullSearchByKeys':
      getFullSearchByKeys(data);
      break;

    case 'setNewOrder':
      setNewOrder(data);
      break;

    case 'getOrderState':
      getOrderState(data);
      break;

    case 'getOrdersByDate':
      getOrdersByDate(data);
      break;

    case 'getOrderStates':
      getOrderStates(data);
      break;
    case 'getDetailGroups':
      getDetailGroups(data);
      break;

    case 'getSettingsCategories':
      getSettingsCategories(data);
      break;
    case 'getSettingsGroups':
      getSettingsGroups(data);
      break;
    case 'updateSettingsCategories':
      getSettingsCategories(data);
      break;
    case 'updAdditionalCategory':
      getSettingsCategories(data);
      break;
    case 'updateSettingsGroups':
      getSettingsGroups(data);
      break;
    case 'uploadDatabase':
      uploadDatabase(data);
      break;

    case 'getSettingsPriceType':
      getSettingsPriceType(data);
      break;
    case 'getSettingsDeliveryPriceType':
      getSettingsDeliveryPriceType(data);
      break;
    case 'setSettingsPriceType':
      getSettingsPriceType(data);
      break;
    case 'setSettingsDeliveryPriceType':
      getSettingsDeliveryPriceType(data);
      break;

    case 'vkLogin':
      vkLogin(data);
      break;

    case 'quickSearch':
      quickSearch(data);
      break;
    case 'getAdditionalGroups':
      getAdditionalGroups(data);
      break;
    case 'addAdditionalGroup':
      getAdditionalGroups(data);
      break;
    case 'updateAdditionalGroup':
      getAdditionalGroups(data);
      break;
    case 'delAdditionalGroup':
      getAdditionalGroups(data);
      break;
    case 'updAdditionalGroup':
      getSettingsGroups(data);
      break;

    case 'renewPrice':
      renewPrice(data);
      break;

    case 'getSettingsParamsValue':
      getSettingsParamsValue(data);
      break;

    case 'setSettingsParamValue':
      setSettingsParamValue(data);
      break;

    case 'sendReview':
      sendReview(data);
      break;

    case 'getCustomCrossesByItem':
      getCustomCrossesByItem(data);
      break;

    case 'updateCustomCrosses':
      getCustomCrossesByItem(data);
      break;

    case 'orderToXLSX':
      orderToXLSX(data);
      break;

    case 'saleReport':
      saleReport(data);
      break;

    default: break;
  }
}


function getVersion(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { client } = data.result;
  const { version } = store.getState().AppState.AppData;

  const count_reload = parseInt(localStorage.getItem('tehkom_update') || '0', 10);

  if ((version !== client) && (count_reload < 1)) {
    console.log(`Mismatch version: ${client} != ${version}`);
    localStorage.setItem('tehkom_update', count_reload + 1);
    window.location.reload(true);
  } else {
    localStorage.setItem('tehkom_update', 0);
    console.log(`Actual version: ${version}`);
  }
}


function searchInPriceByArticle(data) {
  if (data.state !== 'ok') {
    return;
  }

  // console.log(data.result)
  const { details = [], similar = [], adv_crosses } = data.result;
  const { state } = store.getState().TehkomSearchState.TehkomSearchData;

  if (details.length) {
    store.dispatch(setTehkomSearchResult(details))
  } else {
    if (state === 'search') {
      store.dispatch(setTehkomSearchState('result'))
    }
  }
  if (similar.length) {
    store.dispatch(setTehkomSearchSimilar(similar))
  }
  store.dispatch(setTehkomSearchAdvCrosses(adv_crosses))
}


function getDetailAttributes(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { attributes } = data.result;
  store.dispatch(setSearchAttrListItems(attributes))
}


function getDetailGroups(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { groups } = data.result;
  store.dispatch(setSearchGroupListItems(groups))
}


function getDetailsByAttribute(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { details = [] } = data.result;

  if (details.length) {
    const { itemsPerPage } = store.getState().SearchResultState.SearchResultData;
    store.dispatch(setSearchResultTotalPages(Math.ceil(details.length / itemsPerPage)));
    store.dispatch(setSearchResultActivePage(1));
  }

  store.dispatch(setSearchResultRows(details));
  store.dispatch(setSearchResultInProgress(false));
}


function getFullSearchByKeys(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { details = [] } = data.result;
  if (details.length) {
    const { itemsPerPage } = store.getState().SearchResultState.SearchResultData;
    store.dispatch(setSearchResultTotalPages(Math.ceil(details.length / itemsPerPage)));
    store.dispatch(setSearchResultActivePage(1));
  } else {
    store.dispatch(setSearchResultTotalPages(1));
    store.dispatch(setSearchResultActivePage(1));
  }

  store.dispatch(setSearchResultRows(details))
  store.dispatch(setSearchResultInProgress(false));
}


function setNewOrder(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { order = [{}] } = data.result;
  if (order[0].uuid) {
    store.dispatch(setBasketConfirmState('success'));
    store.dispatch(setBasketConfirmOrder(order[0]));
    store.dispatch(setBasketItems([]));
    clearBasket();
    store.dispatch(setBasketMapSelectedPoint(0));
  }
}


function getOrderState(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { order = {} } = data.result;
  if (order.length === 1) {
    store.dispatch(setOrderOrder(order[0]));
  }
}


function getOrdersByDate(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { orders = [] } = data.result;
  if (orders.length > 0) {
    store.dispatch(setAdminAppOrders(orders));
  }
}


function getOrderStates(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  /*
  const { order_states = [] } = data.result;
  if (order_states.length > 0) {
    const states = order_states.map(item => ({
      key: item.id,
      text: item.description,
      value: `${item.id}`
    }));
    states.unshift({key: 0, text: 'Все состояния', value: '0'});
    store.dispatch(setAdminAppOrderStates(states));
  }
  */
}


function getSettingsCategories(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { categories = [] } = data.result;
  store.dispatch(setAdminAppSettingsCategories(categories))
}


function getSettingsGroups(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { groups = [] } = data.result;
  store.dispatch(setAdminAppSettingsGroups(groups))
}


function uploadDatabase(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { upload = {} } = data.result;
  if (store.getState().AdminAppState.AdminAppData.state.state === null) {
    store.dispatch(setAdminUploadState(upload))
  }
}


function getSettingsPriceType(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { price_type = '' } = data.result;
  store.dispatch(setAdminPriceType(price_type))
}


function getSettingsDeliveryPriceType(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth_error = false } = data.result;
  if (auth_error) {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }

  const { delivery_price_type = '' } = data.result;
  store.dispatch(setAdminDeliveryPriceType(delivery_price_type))
}

function vkLogin(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { auth = false } = data.result;
  if (auth) {
    store.dispatch(setAdminNeedAuth(false));
  } else {
    store.dispatch(setAdminNeedAuth(true));
    store.dispatch(setAdminAuth({}));
    window.VK.Auth.logout();
  }
}

function quickSearch(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { search = [] } = data.result;
  store.dispatch(setSearchQuickSearchOptions(search.map((item, i) => {
    item.item = item.key;
    item.key = i + 1;
    return item;
  })))
}


function getAdditionalGroups(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { groups = [] } = data.result;
  store.dispatch(setAdminAppSettingsAddGroups(groups))
}


function renewPrice(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { items = [] } = data.result;
  const basket_items = getItemsForBasket();
  basket_items.forEach(item => {
    const new_item = items.filter(it => it.item === item.item);
    item.price = new_item[0].price;
  });

  store.dispatch(setBasketItems(basket_items));
  setItemsToStorage(basket_items);
}


function getSettingsParamsValue(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { params = {} } = data.result;
  const params1 = Object.assign({}, store.getState().AdminAppState.AdminAppData.params, params);
  store.dispatch(setAdminParams(params1))
}


function setSettingsParamValue(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { param = {} } = data.result;
  const params = Object.assign({}, store.getState().AdminAppState.AdminAppData.params, param);
  store.dispatch(setAdminParams(params))
}


function sendReview(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { review = {} } = data.result;

  // eslint-disable-next-line
  store.dispatch(setModalReviewState(review && 'success' || 'error'));
}


function getCustomCrossesByItem(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { crosses = [] } = data.result;
  store.dispatch(setAdminCrosses(crosses));
}


function orderToXLSX(data) {
  if (data.state !== 'ok') {
    return;
  }

  const { link = '' } = data.result;
  store.dispatch(setAdminOrderXlsxLink(link));
}


function saleReport(data) {
  if (data.state !== 'ok') {
    return;
  }

  // const { link = '' } = data.result;
  store.dispatch(setAdminReportXlsxLink(data.result));
}
