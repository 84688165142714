import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OrderStepsView from './component';
import MobileOrderStepsView from './mobile_component';

import store from '../../store';
import { setOrderStepsActiveStep } from './actions/actions';

import { setBasketItemCount } from '../../api/functions';


class OrderSteps extends Component {

  componentDidMount = () => {
    setBasketItemCount();
  };

  handleClick = (e, { step }) => {
    store.dispatch(setOrderStepsActiveStep(step));
    this.props.history.push('/');
  };

  menuClick = action => () => {
    store.dispatch(setOrderStepsActiveStep(action));
    this.props.history.push('/');
  };

  _prepare = items => items.map(item => {
    item.active = this.props.data.active === item.step;
    item.onClick = this.handleClick;
    item.disabled = this.props.data.disabled.indexOf(item.step) !== -1;

    return item;
  });

  render () {
    return (
      this.props.mobile
        // eslint-disable-next-line
        &&  <MobileOrderStepsView menuClick={this.menuClick} items={this._prepare(this.props.data.items)} />
        // eslint-disable-next-line
        ||  <OrderStepsView  items={this._prepare(this.props.data.items)} />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.OrderStepsState.OrderStepsData
  }
}


export default connect(mapStateToProps)(withRouter(OrderSteps));
