import React, { Component, createRef } from 'react';

import { Form, Popup } from 'semantic-ui-react';


class SearchEngineView extends Component {

  contextRef = createRef();

  render () {
    const { popup_open = false } = this.props;

    return (
      <Form>
        <Form.Group widths={10} >
          <Popup
            inverted
            trigger={
              <Form.Dropdown
                search={this.props.customFilterOptions || true} selection
                width={8}
                options={this.props.search_options}
                searchQuery={this.props.value}
                onSearchChange={this.props.onChange}
                onChange={this.props.onItemChange}
                placeholder={'часть артикула или наименования'}
              />}
            context={this.contextRef}
            content='Нажмите для продолжения поиска по всему прайс-листу'
            open={popup_open}
          />

          <Form.Button
            fluid width={2} color={'teal'}
            labelPosition={'right'}
            content={<strong ref={this.contextRef}>Найти</strong>}
            icon={'search'}
            onClick={this.props.searchClick}
          />
        </Form.Group>
      </Form>
    )
  }
}



export default SearchEngineView;
