import React from 'react';

import { Button } from 'semantic-ui-react';

class DatepickerButton extends React.Component {
  render () {
    return (
      <Button
        onClick={this.props.onClick}
        content={this.props.value}
        icon='calendar'
        labelPosition='left'
      />
    )
  }
}


export default DatepickerButton;
