import React, { Component } from 'react';

import App from '../../containers/app/container';
import Header from '../../containers/header/container';
import Footer from '../../containers/footer/container';
import RulesPayment from '../../containers/rules_payment/container';


import store from '../../store';
import { setRulesPaymentOpen } from '../../containers/rules_payment/actions';


class PaymentsLayout extends Component {
  componentDidMount() {
    store.dispatch(setRulesPaymentOpen(true))    ;
  };

  render () {
    return (
      <div>
        <Header />
        <App />
        <RulesPayment started={this.props.started} />
        <Footer />
      </div>
    )
  }
}


export default PaymentsLayout;
