import React, { Component } from 'react';
import { connect } from 'react-redux';

import BaseUpdateView from './component';
import * as ws from '../../../api/connections';
import store from '../../../store';
import { setAdminUploadFile,
         setAdminUploadOpenModal, setAdminUploadState } from '../actions';


class BaseUpdate extends Component {

  componentDidMount = () => {
    store.dispatch(setAdminUploadState({state: null}));
  };

  componentWillUnmount = () => {
    store.dispatch(setAdminUploadState({state: null}));
  };

  componentDidUpdate = (prevProps, prevState) => {
    clearTimeout(this.timer)
  };

  uploadFile = () => {
    var reader = new FileReader();

    reader.onload = evt => {
      ws.doSend({
        command: 'uploadDatabase',
        params: {
          name: this.props.data.upload_file.name,
          data: btoa(evt.target.result)
        }
      });
    };
    reader.readAsBinaryString(this.props.data.upload_file);
    store.dispatch(setAdminUploadState({state: null}));
    store.dispatch(setAdminUploadOpenModal(true));
    this.timer = setTimeout(() => {
      store.dispatch(setAdminUploadState({state: 'error'}));
    }, 180000)
  };

  selectUploadFile = event => {
    store.dispatch(setAdminUploadFile(event.target.files[0]));
  };

  onClose = () => {
    store.dispatch(setAdminUploadOpenModal(false));
    store.dispatch(setAdminUploadState({state: null}));
  }

  render () {
    return (
      <BaseUpdateView
        selectUploadFile={this.selectUploadFile}
        uploadFile={this.uploadFile}
        open_modal={this.props.data.open_modal}
        state={this.props.data.state}
        onClose={this.onClose}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData
  }
}


export default connect(mapStateToProps)(BaseUpdate);
