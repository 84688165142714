import React, { Component } from 'react';

import { Button, Confirm, Checkbox, Form, Grid,
         Header, Icon, List, Segment } from 'semantic-ui-react';


class BasketItems extends Component {

  state = {
    confirm_open: false,
    confirm_open_all: false,
    selected_item: null,
  }

  _priceFormatter = price => price ? price.toFixed(2) : '--.--';
  _quantityFormatter = quantity => quantity ? quantity.toFixed(0) : '--';

  confirmConfirm = () => {
    this.props.deleteItem(this.state.selected_item);
    this.setState({ confirm_open: false, selected_item: null, });
  }
  confirmCancel = () => this.setState({ confirm_open: false })


  render () {

    const { items } = this.props;
    const { checkItem, checkAll, deleteChecked } = this.props;
    const { incCount, decCount, changeCount } = this.props;

    return (
      <div>
        <Header as={'h3'} content={'Состав заказа'} />

        <Grid>
          <Grid.Row columns={3} >
            <Grid.Column width={13} textAlign={'left'} >
              <Icon name={'check square outline'} style={{cursor: 'pointer', marginLeft: '0.5em'}} onClick={checkAll} size={'large'} />
            </Grid.Column>

            <Grid.Column width={1} >
              {items.filter(item => item.checked).length > 0
                &&  <Icon name={'trash'} style={{cursor: 'pointer', marginRight: '0.5em'}} size={'large'} onClick={() => {this.setState({confirm_open_all: true})}} />
              }
            </Grid.Column>

            <Grid.Column width={2} textAlign={'right'} >
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {items.map((item, i) => (
          <Segment key={i} >
            <Grid>

              {/* Кнопка удалить позицию */}
              <Grid.Row columns={3} >
                <Grid.Column verticalAlign={'middle'} width={1}>
                  <Checkbox checked={item.checked} onChange={checkItem(item)} />
                </Grid.Column>

                <Grid.Column width={14} >
                  <Grid relaxed>
                    <Grid.Row stretched columns={5} >
                      <Grid.Column>
                        <List>
                          <List.Item>
                            <List.Content>
                              <List.Header>{'Артикул'}</List.Header>
                              <List.Description>{item.article}</List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Grid.Column>

                      <Grid.Column>
                        <List>
                          <List.Item>
                            <List.Content>
                              <List.Header>{'Код'}</List.Header>
                              <List.Description>{item.item}</List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Grid.Column>

                      <Grid.Column>
                        <List>
                          <List.Item>
                            <List.Content>
                              <List.Header>{'Производитель'}</List.Header>
                              <List.Description>{item.producer}</List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Grid.Column>

                      <Grid.Column>
                        <List>
                          <List.Item>
                            <List.Content>
                              <List.Header>{'Цена, руб.'}</List.Header>
                              <List.Description>{this._priceFormatter(item.price)}</List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Grid.Column>

                      <Grid.Column>
                        <List>
                          <List.Item>
                            <List.Content>
                              <List.Header>{'Ед. изм.'}</List.Header>
                              <List.Description>{item.unit}</List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                  <Grid.Row columns={2} >
                    <Grid.Column verticalAlign={'middle'} width={12}>
                      {item.name}
                    </Grid.Column>

                    <Grid.Column verticalAlign={'middle'} textAlign={'center'} width={4}>
                      <Grid.Row>
                        <Form>
                          <Form.Group widths={'equal'} >
                            <Form.Input fluid size={'mini'} type={'text'} value={item.count} onChange={changeCount(item)} >
                              <Button circular size={'mini'} icon={'minus'} onClick={decCount(item)} />
                              <input style={{marginRight: '0.3em'}} />
                              <Button circular size={'mini'} icon={'plus'} onClick={incCount(item)} />
                            </Form.Input>
                          </Form.Group>
                        </Form>
                      </Grid.Row>

                      <Grid.Row>
                        {`Доступно: ${this._quantityFormatter(item.quantity_real)}`}
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                  </Grid>
                </Grid.Column>

                <Grid.Column verticalAlign={'top'} width={1}>
                  <Icon style={{cursor: 'pointer'}} name='trash alternate outline' onClick={() => this.setState({confirm_open: true, selected_item: item})} />
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Segment>
        ))}

        <Confirm
          dimmer={'inverted'}
          open={this.state.confirm_open}
          header={'Удалить из корзины?'}
          content={this.state.selected_item ? this.state.selected_item.name : ''}
          onCancel={this.confirmCancel}
          onConfirm={this.confirmConfirm}
        />

        <Confirm
          dimmer={'inverted'}
          open={this.state.confirm_open_all}
          header={'Удалить из корзины?'}
          content={`Удалить выбранные позиции (${items.filter(item => item.checked).length}) из корзины`}
          onCancel={() => {this.setState({confirm_open_all: false})}}
          onConfirm={() => {deleteChecked(); this.setState({confirm_open_all: false});}}
        />

      </div>
    );
  }
}


export default BasketItems;
