import { combineReducers } from 'redux';

import AppReducer from '../containers/app/reducers/reducers';
import OrderStepsReducer from '../containers/ordersteps/reducers/reducers';
import TehkomSearchReducer from '../containers/catalogue/tehkomsearch/reducers';
import AttributeListReducer from '../containers/search/categorysearch/attributelist/reducers';
import GroupListReducer from '../containers/search/groupsearch/groupelist/reducers';
import SearchResultReducer from '../containers/search/categorysearch/searchresult/reducers';
import BasketReducer from '../containers/new_basket/reducers';
import RulesReducer from '../containers/rules/reducer';
import RulesPaymentReducer from '../containers/rules_payment/reducer';
import CheckOrderReducer from '../containers/checkorder/reducer';

import AdminAppReducer from '../containers/admin/reducer';

import ModalViewerReducer from '../containers/modal_viewer/reducer';
import ModalReviewReducer from '../containers/review/reducer';


var reducers = combineReducers({
  AppState: AppReducer,

  OrderStepsState: OrderStepsReducer,
  TehkomSearchState: TehkomSearchReducer,
  AttributeListState: AttributeListReducer,
  GroupListState: GroupListReducer,
  SearchResultState: SearchResultReducer,

  BasketState: BasketReducer,

  RulesState: RulesReducer,
  RulesPaymentState: RulesPaymentReducer,
  CheckOrderState: CheckOrderReducer,

  AdminAppState: AdminAppReducer,

  ModalViewerState: ModalViewerReducer,
  ModalReviewState: ModalReviewReducer,
})


export default reducers;
