import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalViewerView from './component';

import store from '../../store';
// import * as ws from '../../api/connections';
import { setModalViewerOpen } from './actions';



class ModalViewer extends Component {

  _onClose = () => {
    store.dispatch(setModalViewerOpen(false));
  }

  render() {
    return (
      <ModalViewerView open={this.props.data.open} onClose={this._onClose}
        title={this.props.data.title} link={this.props.data.file_name} />
    );
  }
};


const mapStateProps = store => ({
  data: store.ModalViewerState.ModalViewerData,
});


export default connect(mapStateProps)(ModalViewer);
