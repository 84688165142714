import * as types from '../../actions/action-types'


const initialState = {
  CheckOrderData: {
    order: {
      details: {
        items: []
      }
    },
    captcha_protect: false
  }
}


const CheckOrderReducer = function(state=initialState, action) {
  switch(action.type) {
    case types.SET_ORDER_ORDER:
      return Object.assign({}, state, {
        CheckOrderData: Object.assign({}, state.CheckOrderData, {
          order: action.data
        })
      });

    default: return state;
  }
}


export default CheckOrderReducer;
