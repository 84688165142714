import * as types from '../../actions/action-types'


const initialState = {
  AdminAppData: {
    auth: {
    },
    need_auth: true,
    orders: [],
    order_states: [
      {key: 0, value: '0', text: 'Все состояния'},
      {key: 1, value: 'created', text: 'Создан'},
      {key: 3, value: 'work', text: 'Обработка'},
      {key: 4, value: 'picked', text: 'Собран'},
      {key: 6, value: 'ready', text: 'Готов к выдаче'},
      {key: 8, value: 'success', text: 'Выдан (завершён)'},
      {key: 7, value: 'cancel', text: 'Отменён магазином'},
      {key: 9, value: 'client_cancel', text: 'Отменён клиентом'},
    ],
    selected_order: {
      state: 1,
      details: {
        items: [],
        personal: {},
        selected_company:{}
      },
    },
    categories: [],
    groups: [],
    upload_file: '',
    state: {state: null},
    open_modal: false,
    price_type: null,
    delivery_price_type: null,
    add_groups: [],
    params: {},
    crosses: [],
    order_xlsx_link: '',
    report_xlsx_link: {},
  }
}


const AdminAppReducer = function(state=initialState, action) {
  switch(action.type) {
    case types.SET_ADMIN_APP_ORDERS:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          orders: action.data
        })
      });

    case types.SET_ADMIN_APP_ORDER_STATES:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          order_states: action.data
        })
      });

    case types.SET_ADMIN_APP_SELECTED_ORDER:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          selected_order: action.data
        })
      });

    case types.SET_ADMIN_APP_SETTINGS_CATEGORIES:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          categories: action.data
        })
      });

    case types.SET_ADMIN_APP_SETTINGS_GROUPS:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          groups: action.data
        })
      });

    case types.SET_ADMIN_UPLOAD_FILE:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          upload_file: action.data
        })
      });

    case types.SET_ADMIN_UPLOAD_OPEN_MODAL:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          open_modal: action.data
        })
      });

    case types.SET_ADMIN_UPLOAD_STATE:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          state: action.data
        })
      });

    case types.SET_ADMIN_PRICE_TYPE:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          price_type: action.data
        })
      });

    case types.SET_ADMIN_DELIVERY_PRICE_TYPE:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          delivery_price_type: action.data
        })
      });

    case types.SET_ADMIN_APP_SETTINGS_ADD_GROUPS:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          add_groups: action.data
        })
      });

    case types.SET_ADMIN_AUTH:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          auth: action.data
        })
      });

    case types.SET_ADMIN_NEED_AUTH:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          need_auth: action.data
        })
      });

    case types.SET_ADMIN_PARAMS:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          params: action.data
        })
      });

    case types.SET_ADMIN_CROSSES:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          crosses: action.data
        })
      });

    case types.SET_ADMIN_ORDER_XLSX_LINK:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          order_xlsx_link: action.data
        })
      });

    case types.SET_ADMIN_REPORT_XLSX_LINK:
      return Object.assign({}, state, {
        AdminAppData: Object.assign({}, state.AdminAppData, {
          report_xlsx_link: action.data
        })
      });

    default: return state;
  }
}


export default AdminAppReducer;
