import React from 'react';

import * as types from '../../actions/action-types'


const initialState = {
  RulesData: {
    open: false,
    text: (
      <div>
        <span>
          1.1. Настоящий Договор-оферта регулирует взаимоотношения Продавца и Покупателя.
        </span><br />
        <span>1.2. Продавец сохраняет за собой право изменять настоящий Договор.</span><br />
        <span>1.3. Адрес действующей редакции настоящего Договора в сети интернет: shop.tehkom-avto.ru</span><br />
        <span style={{color: 'blue'}}>1.4. <b>Цена покупки в интернет магазине является окончательной. Скидки по дисконтным картам на интернет покупки не осуществляются.</b></span><br />
        <span>{''}</span><br />
        <span>ПРЕДМЕТ ДОГОВОРА</span><br />
        <span>2.1. Продавец обязуется передать в собственность Покупателю товар, а Покупатель обязуется принять и оплатить товар на условиях настоящего Договора.</span><br />
        <span>{''}</span><br />
        <span>СТАТУС ИНТЕРНЕТ-МАГАЗИНА </span><br />
        <span>3.1. Продавцом товаров, реализуемых через интернет-магазин shop.tehkom-avto.ru, является  ООО «Автозапчасти». Товары через интернет-магазин реализуются дистанционным способом, и путем самовывоза из точек выдачи указанных в интернет-магазине.</span><br />
        <span>3.2. Продавец не требует от Покупателя специальных действий для использования интернет-магазина, таких как регистрация или заключение договора на пользование интернет-магазином.</span><br />
        <span>3.3. Настоящий Договор является официальной офертой. Акцентом настоящей оферты является оформление и подтверждение заказа Покупателем товара. Договор купли-продажи считается заключенным в момент оплаты за товар.</span><br />
        <span>3.4. Информация, размещенная на сайте shop.tehkom-avto.ru, является общедоступной, если иное не установлено настоящим Договором.</span><br />
        <span>3.5. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.</span><br />
        <span>{''}</span><br />
        <span>СТАТУС ПОКУПАТЕЛЯ</span><br />
        <span>4.1. Покупателем является лицо, самостоятельно оформившее заказ в интернет-магазине shop.tehkom-avto.ru на условиях настоящего Договора.</span><br />
        <span>4.2. Покупатель несет ответственность за достоверность предоставленной при оформлении заказа информации.</span><br />
        <span>4.3. Оформление Покупателем заказа в интернет-магазинe shop.tehkom-avto.ru означает согласие Покупателя с условиями настоящего Договора.</span><br />
        <span>4.4. Оформление Покупателем заказа осуществляется посредством нажатия кнопки «Оформить заказ».</span><br />
        <span>{''}</span><br />
        <span>ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА</span><br />
        <span>5.1. При оформлении заказа в интернет-магазине Покупатель обязан предоставить о себе информацию: фамилию, имя, номер телефона, адрес электронной почты, реквизиты для доставки.</span><br />
        <span>5.2. Продавец не редактирует информацию о Покупателе.</span><br />
        <span>5.3. После завершения процесса оформления заказа ему автоматически присваивается идентификационный номер («№ заказа»). После оформления заказа менеджер Продавца связывается с Покупателем для уточнения условий заказа и доставки.</span><br />
        <span>5.4. Способ оплаты и доставки Покупатель выбирает при оформлении заказа самостоятельно из предложенных Продавцом. Срок доставки согласовывается сторонами при звонке менеджера Продавца Покупателю. Покупатель обязан оплатить товар в соответствии с выбранным способом.</span><br />
        <span>5.5. Покупатель обязуется проверить комплектность и внешний вид товара в момент его получения на почте, в транспортной компании, или курьерской службе. Любые претензии по некомплекту или несоответствию состава заказа принимаются исключительно в случае сообщения об этом в момент получения заказа в ТК или у курьера. При обнаружении повреждений возникших в процессе доставки, покупатель заполняет соответствующий акт о повреждении посылки на бланке компании осуществляющей доставку, или в свободной форме. Акт должен быть подписан представителем компании осуществляющей доставку, и отправлен на email интернет-магазина shop.tehkom-avto.ru. В иных случаях претензии о повреждении груза в процессе доставки не принимаются. Решение о замене, возврате, или возмещении стоимости поврежденного товара принимается администрацией интернет-магазина в течение 7 рабочих дней.</span><br />
        <span>{''}</span><br />
        <span>ОБМЕН И ВОЗВРАТ ТОВАРА.</span><br />
        <span>6.1.Возврат товара</span><br />
        <span>6.1.1. Покупатель вправе отказаться от товара надлежащего качества в течение 15 дней с даты покупки, указанной на кассовом чеке, в том случае, если товар не был в употреблении, сохранены упаковка, товарный вид, потребительские свойства, пломбы, фабричные ярлыки.</span><br />
        <span>6.1.2. Для возврата товара надлежащего качества Покупатель направляет заявление с копиями документов, подтверждающих факт покупки, по электронной почте tehkom-avto@yandex.ru. Продавец принимает заявление к рассмотрению в течение 5 рабочих дней согласовывает с Покупателем условия возврата товара. Продавец вправе отказать в возврате товара, если выяснится, что товар был в эксплуатации, и/или имеет механические повреждения (трещины, царапины, сколы).</span><br />
        <span>6.1.3. При возврате товара надлежащего качества Покупатель обязан компенсировать продавцу все расходы, связанные с доставкой и возвратом товара.</span><br />
        <span>6.1.4. Продавец возвращает уплаченные за товар денежные средства в течение 10 рабочих дней с момента получения возвращенного товара.</span><br />
        <br /><span>РЕКВИЗИТЫ ПРОДАВЦА</span><br /><br />
        <table>
          <tbody>
          <tr>
            <td><span>Полное и сокращенное наименование фирмы в соответствии с учредительными документами - Уставом ООО «Автозапчасти»</span></td>
            <td><span>Общество с ограниченной ответственностью  «Автозапчасти» (ООО «Автозапчасти»</span></td>
          </tr>
          <tr>
            <td><span>Идентификационный номер (ИНН)</span></td>
            <td><span>7130010203</span></td>
          </tr>
          <tr>
            <td><span></span>Код причины постановки на учет (КПП)</td>
            <td><span></span>710501001</td>
          </tr>
          <tr>
            <td><span>Код предприятия по ОКПО</span></td>
            <td><span>34421457</span></td>
          </tr>
          <tr>
            <td><span>Код по ОКВЭД</span></td>
            <td><span>45.31</span></td>
          </tr>
          <tr>
            <td><span>ОГРН</span></td>
            <td><span>1027100687613</span></td>
          </tr>
          <tr>
            <td><span>Учетная карточка участника внешнеэкономической деятельности (ВЭД)</span></td>
            <td><span>-</span></td>
          </tr>
          <tr>
            <td><span>Юридический адрес</span></td>
            <td><span>300001, г. Тула, ул. Карла Маркса, д.54-а</span></td>
          </tr>
          <tr>
            <td><span>Почтовый адрес</span></td>
            <td><span>300001, г. Тула, ул. Карла Маркса, д.54-а</span></td>
          </tr>
          <tr>
            <td><span>Адрес электронной почты (E-mail)</span></td>
            <td><span>texkomplus83@mail.ru</span></td>
          </tr>
          <tr>
            <td><span>Телефон/факс</span></td>
            <td><span>(4872) 40-54-50 <br /> факс (4872) 40-50-70</span></td>
          </tr>
          <tr>
            <td><span>Номер расчетного счета</span></td>
            <td><span>40702810466060101909 ТУЛЬСКОЕ ОТДЕЛЕНИЕ N8604 ПАО СБЕРБАНК</span></td>
          </tr>
          <tr>
            <td><span>Номер корреспондентского счета</span></td>
            <td><span>30101810300000000608</span></td>
          </tr>
          <tr>
            <td><span>БИК</span></td>
            <td><span>047003608</span></td>
          </tr>
          <tr>
            <td><span>Код по ОКАТО</span></td>
            <td><span>70401000000</span></td>
          </tr>
          <tr>
            <td><span>Код по ОКТМО</span></td>
            <td><span>70701000</span></td>
          </tr>
          <tr>
            <td><span>Руководитель - генеральный директор</span></td>
            <td><span>Королева Елена Ивановна, действующая на основании Устава</span></td>
          </tr>
          <tr>
            <td><span>Главный бухгалтер</span></td>
            <td><span>Волкова Елена Викторовна</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    ),
  }
}


const RulesReducer = function(state=initialState, action) {
  switch(action.type) {

    case types.SET_RULES_OPEN:
      return Object.assign({}, state, {
        RulesData: Object.assign({}, state.RulesData, {
          open: action.data
        })
      });


    case types.SET_RULES_TEXT:
      return Object.assign({}, state, {
        RulesData: Object.assign({}, state.RulesData, {
          text: action.data
        })
      });


    default: return state;
  }
}


export default RulesReducer;
