import ym from 'react-yandex-metrika';

import XLSX from 'xlsx';

import store from '../store';

import { setOrderStepsItems,
         setOrderStepsDisabled } from '../containers/ordersteps/actions/actions';
import { setSearchResultTotalPages } from '../containers/search/categorysearch/searchresult/actions';
import {  setTehkomSearchResult,
          setTehkomSearchSimilar } from '../containers/catalogue/tehkomsearch/actions';



export const setBasketItemCount = () => {
  const length = itemsInBasket();
  const { items, disabled } = store.getState().OrderStepsState.OrderStepsData;
  store.dispatch(setOrderStepsItems(items.map((item, i) => {
    if (item.key === 3) {
      item.description = `${length} поз.`;
    }

    if ((length) && (item.key === 3)) {
      item.disabled = false;
      store.dispatch(setOrderStepsDisabled(
        disabled.filter(item => item !== 'basket')
      ));
    } else {
      if (disabled.indexOf('basket') === -1) {
        disabled.push('basket');
        store.dispatch(setOrderStepsDisabled(disabled));
      }
    }
    return item;
  })));
}


export const storeItemToBasket = (item, i, t) => {
  ym('reachGoal','add_to_card');

  if (item.checked) {
    const storage = JSON.parse(localStorage.getItem('tehkom'));
    storage.basket[item.item].count = storage.basket[item.item].count + 1;
    localStorage.setItem('tehkom', JSON.stringify(storage));
    if (t) {
      if (t === 'result') {
        const { result } = store.getState().TehkomSearchState.TehkomSearchData;
        result[i].checked = storage.basket[item.item].count;
        store.dispatch(setTehkomSearchResult(result));
      } else if (t === 'similar') {
        const { similar } = store.getState().TehkomSearchState.TehkomSearchData;
        similar[i].checked = storage.basket[item.item].count;
        store.dispatch(setTehkomSearchSimilar(similar));
      }
    } else {
      const { totalPages } = store.getState().SearchResultState.SearchResultData;
      store.dispatch(setSearchResultTotalPages(totalPages + 1));
      store.dispatch(setSearchResultTotalPages(totalPages));
    }
  } else {
    // console.log('Добавлен')
    const storage = JSON.parse(localStorage.getItem('tehkom'));
    item.count = 1;
    item.checked = false;
    storage.basket[item.item] = item;
    localStorage.setItem('tehkom', JSON.stringify(storage));
    setBasketItemCount();

    if (t) {
      if (t === 'result') {
        const { result } = store.getState().TehkomSearchState.TehkomSearchData;
        result[i].checked = true;
        store.dispatch(setTehkomSearchResult(result));
      } else if (t === 'similar') {
        const { similar } = store.getState().TehkomSearchState.TehkomSearchData;
        similar[i].checked = true;
        store.dispatch(setTehkomSearchSimilar(similar));
      }
    } else {
      const { totalPages } = store.getState().SearchResultState.SearchResultData;
      store.dispatch(setSearchResultTotalPages(totalPages + 1));
      store.dispatch(setSearchResultTotalPages(totalPages));
    }
  }
}


export const checkItemInBasket = item => {
  const storage = JSON.parse(localStorage.getItem('tehkom'));
  // eslint-disable-next-line
  return storage.basket[item] && storage.basket[item].count || 0;
  // return Object.keys(storage.basket).indexOf(item) !== -1
};


export const deleteItemFromBasket = item => {
  const storage = JSON.parse(localStorage.getItem('tehkom'));
  // storage.basket = storage.basket.filter(it => it.item !== item.item);
  delete storage.basket[item.item];
  localStorage.setItem('tehkom', JSON.stringify(storage));
  setBasketItemCount();
}


export const updateItemInBasket = item => {
  const storage = JSON.parse(localStorage.getItem('tehkom'));
  storage.basket = storage.basket.map(it => {
    return it.item === item.item ? item : it;
  });
  localStorage.setItem('tehkom', JSON.stringify(storage));
  setBasketItemCount();
}


export const clearBasket = () => {
  localStorage.setItem('tehkom', JSON.stringify({basket: {}}));
  setBasketItemCount();
}


export const itemsInBasket = () => {
  const storage = JSON.parse(localStorage.getItem('tehkom'));
  return Object.keys(storage.basket).length;
};


export const getItemsForBasket = () => {
  const storage = JSON.parse(localStorage.getItem('tehkom')) || {};
  if (Array.isArray(storage.basket)) {
    clearBasket();
    return [];
  }
  return Object.keys(storage.basket).map(item => storage.basket[item]);
}


export const setItemsToStorage = items => {
  const storage = JSON.parse(localStorage.getItem('tehkom')) || {};
  items.forEach(item => {
    storage.basket[item.item] = item;
  });
  localStorage.setItem('tehkom', JSON.stringify(storage));
}

export const checkLocalStorage = () => {
  const storage = JSON.parse(localStorage.getItem('tehkom')) || {};
  if (!storage.basket) {
    storage.basket = {};
    localStorage.setItem('tehkom', JSON.stringify(storage));
  }
}


export const init_focus_event = () => {
    var param_name, event_name;
    // Универсальный обработчик
    if (typeof document.hidden !== 'undefined') {
        param_name='hidden';
        event_name='visibilitychange';
    }
    // Mozilla-based браузеры
    else if (typeof document.mozHidden !== 'undefined') {
        param_name='mozHidden';
        event_name='mozvisibilitychange';
    }
    // IE-based браузеры
    else if (typeof document.msHidden !== 'undefined') {
        param_name='msHidden';
        event_name='msvisibilitychange';
    }
    // WebKit-based браузеры
    else if (typeof document.webkitHidden !== 'undefined') {
        param_name='webkitHidden';
        event_name='webkitvisibilitychange';
    }
    // Браузер не поддерживает Page Visibility API
    else {
        param_name=false;
        window.addEventListener('focus', get_focus, false);
        window.addEventListener('blur', lost_focus, false);
    }

    // Установить обработчик события, если оно поддерживается
    if (param_name) {
        document.addEventListener(event_name, function() {
            if (document[param_name]) {
                lost_focus();
            }
            else {
                get_focus();
            }
        }, false);
    }
}

const  get_focus = () => {
  console.log('Get focus')
  setBasketItemCount();
}

const lost_focus = () => {
  console.log('Lost focus')
}


const delivery_point = point => {
  const { map_objects } = store.getState().BasketState.BasketData;
  if (map_objects.filter(item => item.key === point)[0]) {
    return map_objects.filter(item => item.key === point)[0].properties.hintContent
  } else {
    return ''
  }
};


const itemState = state => {
  const options = store.getState().AdminAppState.AdminAppData.order_states.filter(item => item.value === `${state}`);
  if (options.length === 1) {
    return options[0].text;
  } else {
    return '';
  }
}


export const itemToExcel = order => () => {
  var data = [{
    name: 'Дата заказа',
    value: order.created
  }, {
    name: 'Номер заказа',
    value: order.details.order
  }, {
    name: 'UUID заказа',
    value: order.order_uuid
  }, {
    name: 'Клиент',
    value: order.details.personal.name
  }, {
    name: 'e-mail',
    value: order.details.personal.email
  }, {
    name: 'Телефон',
    value: order.details.personal.phone
  }, {
    name: 'Точка выдачи',
    value: order.details.delivery_point ? delivery_point(order.details.delivery_point) : order.details.delivery_address,
  }, {
    name: 'Состояние',
    value: itemState(order.state)
  }]
  var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
  data = order.details.items.map((item, i) => ({
    '№ пп': i + 1,
    'Артикул': item.article,
    'Наименование': item.name,
    'Производитель': item.producer,
    'Количество': item.count,
    'Цена': item.price,
    'Сумма': item.price * item.count,
    // eslint-disable-next-line
    'В наличии': item.checked && '✓' || '',
    // eslint-disable-next-line
    'Отсутствует': item.trashed && '✓' || '',
  }));
  XLSX.utils.sheet_add_json(ws, data, {origin: 'A9'});
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Заказ');

  XLSX.writeFile(wb, 'order.xlsx')
};


export const tableToExcel = orders => {
  var data = [{
    name: 'Начало отчета',
    value: orders[orders.length - 1].created
  }, {
    name: 'Окончание отчета',
    value: orders[0].created
  }, ]
  var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
  data = orders.map((item, i) => ({
    '№ пп': i + 1,
    'Дата заказа': item.created,
    'Номер заказа': item.details.order,
    'UUID заказа': item.order_uuid,
    'Клиент': item.details.personal.name,
    'E-mail': item.details.personal.email,
    'Телефон': item.details.personal.phone,
    'Сумма': item.details.items.reduce((a, c) => a + c.price * c.count, 0),
    'Точка выдачи': item.details.delivery_point ? delivery_point(item.details.delivery_point) : item.details.delivery_address,
    'Состояние': itemState(item.state)
  }));
  XLSX.utils.sheet_add_json(ws, data, {origin: 'A4'});
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Заказы');

  XLSX.writeFile(wb, 'orders.xlsx')
};
