import React, { Component } from 'react';

import { Button, Modal } from 'semantic-ui-react';


class RulesView extends Component {

  render () {
    const { info, agreeHandler, disagreeHandler } = this.props;

    return (
      <Modal size={'large'} open={this.props.open} onClose={disagreeHandler || this.props.onClose} dimmer={'inverted'} closeIcon>
        <Modal.Header>{'Договор-оферта'}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {this.props.text}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={
            // eslint-disable-next-line
            disagreeHandler || this.props.onClose}>
            {
                // eslint-disable-next-line
                info && 'Закрыть' || 'Я, не согласен'}</Button>
          {!info
            &&  <Button primary icon='checkmark' labelPosition='right' content='Я, прочитал и согласен' onClick={agreeHandler} />}
        </Modal.Actions>
      </Modal>
    )
  }
}


export default RulesView;
