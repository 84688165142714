// App
export const SET_APP_DIMMED = 'SET_APP_DIMMED';
export const SET_APP_STEP = 'SET_APP_STEP';
export const SET_APP_GLOBAL_MENU = 'SET_APP_GLOBAL_MENU';
export const SET_APP_GLOBAL_MENU_ITEMS = 'SET_APP_GLOBAL_MENU_ITEMS';
export const SET_APP_ACAT_EXT = 'SET_APP_ACAT_EXT';
export const SET_APP_SEARCH_ACTIVE_INDEX = 'SET_APP_SEARCH_ACTIVE_INDEX';
export const SET_APP_GMENU_POPUP_OPEN = 'SET_APP_GMENU_POPUP_OPEN';
export const SET_APP_HIDE_ATTR_LIST = 'SET_APP_HIDE_ATTR_LIST';
export const SET_APP_HIDE_GROUP_LIST = 'SET_APP_HIDE_GROUP_LIST';

// OrderSteps
export const SET_ORDERSTEPS_ACTIVE_STEP = 'SET_ORDERSTEPS_ACTIVE_STEP';
export const SET_ORDERSTEPS_DISABLE_ORDER = 'SET_ORDERSTEPS_DISABLE_ORDER';
export const SET_ORDERSTEPS_ITEMS = 'SET_ORDERSTEPS_ITEMS';
export const SET_ORDERSTEPS_DISABLED = 'SET_ORDERSTEPS_DISABLED';

// Tehkom search
export const SET_TEHKOM_SEARCH_IS_OPEN = 'SET_TEHKOM_SEARCH_IS_OPEN';
export const SET_TEHKOM_SEARCH_ARTICLE = 'SET_TEHKOM_SEARCH_ARTICLE';
export const SET_TEHKOM_SEARCH_BRAND = 'SET_TEHKOM_SEARCH_BRAND';
export const SET_TEHKOM_SEARCH_CROSSES = 'SET_TEHKOM_SEARCH_CROSSES';
export const SET_TEHKOM_SEARCH_STATE = 'SET_TEHKOM_SEARCH_STATE';
export const SET_TEHKOM_SEARCH_RESULT = 'SET_TEHKOM_SEARCH_RESULT';
export const SET_TEHKOM_SEARCH_SIMILAR = 'SET_TEHKOM_SEARCH_SIMILAR';
export const SET_TEHKOM_SEARCH_ADV_CROSSES = 'SET_TEHKOM_SEARCH_ADV_CROSSES';
export const SET_TEHKOM_SEARCH_NEED_CLOSE_WINDOW = 'SET_TEHKOM_SEARCH_NEED_CLOSE_WINDOW';

// Seacrh
export const SET_SEARCH_ATTR_LIST_ITEMS = 'SET_SEARCH_ATTR_LIST_ITEMS';
export const SET_SEARCH_ATTR_LIST_FILTER = 'SET_SEARCH_ATTR_LIST_FILTER';
export const SET_SEARCH_ATTR_START_GROUP = 'SET_SEARCH_ATTR_START_GROUP';

export const SET_SEARCH_GROUP_LIST_ITEMS = 'SET_SEARCH_GROUP_LIST_ITEMS';
export const SET_SEARCH_GROUP_LIST_FILTER = 'SET_SEARCH_GROUP_LIST_FILTER';
export const SET_SEARCH_RESULT_ROWS = 'SET_SEARCH_RESULT_ROWS';
export const SET_SEARCH_RESULT_ACTIVE_PAGE = 'SET_SEARCH_RESULT_ACTIVE_PAGE';
export const SET_SEARCH_RESULT_TOTAL_PAGES = 'SET_SEARCH_RESULT_TOTAL_PAGES';
export const SET_SEARCH_RESULT_FILTER = 'SET_SEARCH_RESULT_FILTER';
export const SET_SEARCH_RESULT_TITLE = 'SET_SEARCH_RESULT_TITLE';
export const SET_SEARCH_RESULT_IN_PROGRESS = 'SET_SEARCH_RESULT_IN_PROGRESS';

export const SET_SEARCH_QUICK_SEARCH_OPTIONS = 'SET_SEARCH_QUICK_SEARCH_OPTIONS';

// Basket
export const SET_BASKET_ITEMS = 'SET_BASKET_ITEMS';
export const SET_BASKET_PAYMENT = 'SET_BASKET_PAYMENT';
export const SET_BASKET_DELIVERY = 'SET_BASKET_DELIVERY';
export const SET_BASKET_MAP_OPEN = 'SET_BASKET_MAP_OPEN';
export const SET_BASKET_MAP_OBJECTS = 'SET_BASKET_MAP_OBJECTS';
export const SET_BASKET_MAP_SELECT_POINT = 'SET_BASKET_MAP_SELECT_POINT';
export const SET_BASKET_CONFIRM_OPEN = 'SET_BASKET_CONFIRM_OPEN';
export const SET_BASKET_CONFIRM_STATE = 'SET_BASKET_CONFIRM_STATE';
export const SET_BASKET_CONFIRM_ORDER = 'SET_BASKET_CONFIRM_ORDER';
export const SET_BASKET_DELIVERY_ADDRESS = 'SET_BASKET_DELIVERY_ADDRESS';


// Rules
export const SET_RULES_OPEN = 'SET_RULES_OPEN';
export const SET_RULES_TEXT = 'SET_RULES_TEXT';

// Rules payment
export const SET_RULES_PAYMENT_OPEN = 'SET_RULES_PAYMENT_OPEN';
export const SET_RULES_PAYMENT_TEXT = 'SET_RULES_PAYMENT_TEXT';

// Check order
export const SET_ORDER_ORDER = 'SET_ORDER_ORDER';


// Admin
export const SET_ADMIN_APP_ORDERS = 'SET_ADMIN_APP_ORDERS';
export const SET_ADMIN_APP_ORDER_STATES = 'SET_ADMIN_APP_ORDER_STATES';
export const SET_ADMIN_APP_SELECTED_ORDER = 'SET_ADMIN_APP_SELECTED_ORDER';

export const SET_ADMIN_APP_SETTINGS_GROUPS = 'SET_ADMIN_APP_SETTINGS_GROUPS';
export const SET_ADMIN_APP_SETTINGS_CATEGORIES = 'SET_ADMIN_APP_SETTINGS_CATEGORIES';
export const SET_ADMIN_APP_SETTINGS_ADD_GROUPS = 'SET_ADMIN_APP_SETTINGS_ADD_GROUPS';

export const SET_ADMIN_UPLOAD_FILE = 'SET_ADMIN_UPLOAD_FILE';
export const SET_ADMIN_UPLOAD_OPEN_MODAL = 'SET_ADMIN_UPLOAD_OPEN_MODAL';
export const SET_ADMIN_UPLOAD_STATE = 'SET_ADMIN_UPLOAD_STATE';

export const SET_ADMIN_PRICE_TYPE = 'SET_ADMIN_PRICE_TYPE';
export const SET_ADMIN_DELIVERY_PRICE_TYPE = 'SET_ADMIN_DELIVERY_PRICE_TYPE';

export const SET_ADMIN_AUTH = 'SET_ADMIN_AUTH';
export const SET_ADMIN_NEED_AUTH = 'SET_ADMIN_NEED_AUTH';

export const SET_ADMIN_PARAMS = 'SET_ADMIN_PARAMS';

export const SET_ADMIN_CROSSES = 'SET_ADMIN_CROSSES';

export const SET_ADMIN_ORDER_XLSX_LINK = 'SET_ADMIN_ORDER_XLSX_LINK';
export const SET_ADMIN_REPORT_XLSX_LINK = 'SET_ADMIN_REPORT_XLSX_LINK';

// Документ viewer
export const SET_MODAL_VIEWER_OPEN = 'SET_MODAL_VIEWER_OPEN';
export const SET_MODAL_VIEWER_FILE_NAME = 'SET_MODAL_VIEWER_FILE_NAME';
export const SET_MODAL_VIEWER_TITLE = 'SET_MODAL_VIEWER_TITLE';

// Review form
export const SET_MODAL_REVIEW_OPEN = 'SET_MODAL_REVIEW_OPEN';
export const SET_MODAL_REVIEW_STATE = 'SET_MODAL_REVIEW_STATE';
