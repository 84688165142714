import React, { Component } from 'react';


class CatalogsParts extends Component {
  render () {
    return (
      <div>
        <iframe id="cp_frame" name="cp_frame" title='catalogue_parts'
                src="https://to.catalogs-parts.com/#{client:gmujwr;page:manufacturer;lang:ru}"
                onload="FrameManager.registerFrame(this)"
                width='100%' height='740px'
                scrolling='auto' frameborder="0" > </iframe>
      </div>
    )
  }
}


export default CatalogsParts;
