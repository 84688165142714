import React, { Component } from 'react';
import { connect } from 'react-redux';

import Moment from 'moment';

import ReportsView from './component';

import * as ws from '../../../api/connections';
import store from '../../../store';
import { setAdminReportXlsxLink } from '../actions';


class Reports extends Component {

  state = {
    startDate: Moment(),
    endDate: Moment(),
  }

  reports = () => {
    const { report, link } = this.props.data.report_xlsx_link;

    return [
      { alias: 'saleReport',
        header: 'Продажи',
        meta: 'Отчёт по позициям',
        desc: 'Товарные позиции, проданные за период',
        image: 'images/score.png',
        link: '',
        onClick: () => {this.saleReport()}, }, ]
      .map(item => {
        if (item.alias === report) {
          item.link = link;
        }
        return item;
      });
  }

  saleReport = () => {
    if (this.props.data.order_xlsx_link !== 'calc') {
      store.dispatch(setAdminReportXlsxLink({
        report: 'saleReport',
        link: 'calc'
      }));

      setTimeout(() => {
        store.dispatch(setAdminReportXlsxLink({
          report: 'saleReport',
          link: '',
        }));
      }, 10000)

      ws.doSend({
        command: 'saleReport',
        params: {
          'startDate': this.state.startDate.format('YYYY-MM-DD 00:00:00'),
          'endDate': this.state.endDate.format('YYYY-MM-DD 23:59:59'), }
      });
    }
  }

  changeDate = name => date => {
    if (name === 'start') {
      if (this.state.startDate !== date) {
        this.setState({startDate: date});
      }
    }
    if (name === 'end') {
      if (this.state.endDate !== date) {
        this.setState({endDate: date});
      }
    }
  };

  componentDidUpdate(prevProps) {

    const { report_xlsx_link } = this.props.data;
    const { link } = report_xlsx_link;
    const prev_link = prevProps.data.report_xlsx_link.link;

    if (link !== prev_link && link !== '' && link !== 'calc') {
      this._download_report(link)
    }

  }

  _download_report = report => {
    var link = document.createElement('a');
    link.download = '';
    link.href = `/report/${report}`;
    link.click();
  }

  render () {
    return (
      <ReportsView
        items={this.reports()}
        startDate={this.state.startDate} endDate={this.state.endDate}
        changeDate={this.changeDate} />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData,
  }
}


export default connect(mapStateToProps)(Reports);

