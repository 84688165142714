import React, { Component } from 'react';
import { connect } from 'react-redux';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Moment from 'moment';

import OrdersView from './component';
import * as ws from '../../../api/connections';
// import { itemToExcel, tableToExcel } from '../../../api/functions';
import store from '../../../store';
import { setAdminAppOrders, setAdminAppSelectedOrder } from '../actions';


class Orders extends Component {

  state = {
    startDate: Moment(),
    endDate: Moment(),
    rowPerPage: 10,
    activePage: 1,
    open_editor: false,
    selected: {},

    current_state_filter: '0',
    text_filter: '',
    current_point_filter: '0',
    current_payment_filter: '0',
    current_delivery_filter: '0',

    selected_payment_value: null,
    selected_delivery_value: null,
    selected_delivery_address: null,
    selected_cdek: null,
    selected_russianpost: null,
    selected_check_delivery_payment: null,
    delivery_payment: null,
    track_number: null,

  }

  getOrders = () => {
    store.dispatch(setAdminAppOrders([]));
    this.setState({activePage: 1})
    ws.doSend({
      command: 'getOrdersByDate',
      params: {
        start: this.state.startDate.format('YYYY-MM-DD 00:00:00'),
        end: this.state.endDate.format('YYYY-MM-DD 23:59:59')
      }
    })
  };

  componentDidMount() {
    this.getOrders();
  };

  changeDate = name => date => {
    if (name === 'start') {
      if (this.state.startDate !== date) {
        this.setState({startDate: date});
        setTimeout(() => {this.getOrders()}, 500)
      }
    }
    if (name === 'end') {
      if (this.state.endDate !== date) {
        this.setState({endDate: date});
        setTimeout(() => {this.getOrders()}, 500)
      }
    }
  };

  changePage = (e, {activePage}) => {
    this.setState({activePage: activePage})
  };

  delivery_point = point => {
    const { map_objects } = store.getState().BasketState.BasketData;
    if (map_objects.filter(item => item.key === point)[0]) {
      return map_objects.filter(item => item.key === point)[0].properties.hintContent
    } else {
      return ''
    }
  };

  onOrderClick = item => () => {
    const orders = this.props.data.orders.filter(it => it.id === item.id);
    if (orders.length === 1) {
      store.dispatch(setAdminAppSelectedOrder(Object.assign({}, orders[0])));
      this.setState({
        open_editor: true,
      });
    }
  };

  onCloseEditor = action => {
    if (action === 'save') {
      const { selected_order, orders } = this.props.data;
      selected_order.details.items = selected_order.details.items.map(item => {
        if (this.state.selected[`${item.article}~${item.item}`]) {
          item.checked = this.state.selected[`${item.article}~${item.item}`].checked;
          item.trashed = this.state.selected[`${item.article}~${item.item}`].trashed;
        }
        return item;
      });

      if (this.state.selected_payment_value !== null) {
        selected_order.details.selected_payment = this.state.selected_payment_value;
      }

      if (this.state.selected_delivery_value !== null) {
        selected_order.details.selected_delivery = this.state.selected_delivery_value;
      }

      if (this.state.selected_delivery_address !== null) {
        selected_order.details.delivery_address = this.state.selected_delivery_address;
      }

      if (this.state.selected_cdek !== null && selected_order.details.selected_delivery === 'company') {
        selected_order.details.selected_company.cdek = this.state.selected_cdek;
      }

      if (this.state.selected_russianpost !== null && selected_order.details.selected_delivery === 'company') {
        selected_order.details.selected_company.russianpost = this.state.selected_russianpost;
      }

      if (this.state.track_number !== null) {
        selected_order.details.track_number = this.state.track_number;
      }

      if (this.state.selected_check_delivery_payment !== null) {
        selected_order.details.delivery_payment_in_order = this.state.selected_check_delivery_payment;
      }

      if (this.state.delivery_payment !== null) {
        selected_order.details.delivery_payment = this.state.delivery_payment;
      }

      ws.doSend({
        command: 'updateOrder',
        params: {
          order: selected_order.details,
          state: selected_order.state,
          id: selected_order.id
        }
      });
      store.dispatch(setAdminAppOrders(orders.map(item => {
        if (item.id === selected_order.id) {
          return selected_order;
        }
        return item;
      })))
      store.dispatch(setAdminAppSelectedOrder({state: 1, details: {items: [], personal: {}, selected_company: {}}}));
      this.setState({
        open_editor: false,
        selected: {},
      });
    } else if (action === 'cancel') {
      store.dispatch(setAdminAppSelectedOrder({state: 1, details: {items: [], personal: {}}}));
      this.setState({
        open_editor: false,
        selected: {},
        selected_payment_value: null,
        selected_delivery_value: null,
        selected_delivery_address: null,
        selected_cdek: null,
        selected_russianpost: null,
        selected_check_delivery_payment: null,
        delivery_payment: null,
        track_number: null,
      });
    }
  };

  itemChecked = (action, article) => () => {

    if (this.props.data.selected_order.state >= 4) {
      return;
    }

    const selected = Object.assign({}, this.state.selected);

    if (action === 'checked') {
      if (selected[article]) {
        selected[article] = {
          checked: !selected[article].checked,
          trashed: false
        };
      } else {
        const items = this.props.data.selected_order.details.items.filter(item => `${item.article}~${item.item}` === article);
        if (items.length === 1) {
          selected[article] = {
            checked: !items[0].checked,
            trashed: false
          };
        }
      }
    }

    if (action === 'trashed') {
      if (selected[article]) {
        selected[article] = {
          trashed: !selected[article].trashed,
          checked: false
        };
      } else {
        const items = this.props.data.selected_order.details.items.filter(item => `${item.article}~${item.item}` === article);
        if (items.length === 1) {
          selected[article] = {
            trashed: !items[0].trashed,
            checked: false
          };
        }
      }
    }

    this.setState({selected});
  };

  stateChange = (e, {value}) => {
    const {selected_order} = this.props.data;
    selected_order.state = value;
    store.dispatch(setAdminAppSelectedOrder(selected_order))
  };

  changeFilter = name => (e, {value}) => {
    if (name === 'state') {
      this.setState({current_state_filter: value})
    } else if (name === 'common') {
      this.setState({text_filter: value})
    } else if (name === 'point') {
      this.setState({current_point_filter: value})
    } else if (name === 'payment') {
      this.setState({current_payment_filter: value})
    }  else if (name === 'delivery') {
      this.setState({current_delivery_filter: value})
    }
  };

  _commonFilter = item => (
    item.order_uuid.indexOf(this.state.text_filter) !== -1 ||
    item.details.personal.name.indexOf(this.state.text_filter) !== -1 ||
    item.details.personal.email.indexOf(this.state.text_filter) !== -1 ||
    item.details.personal.phone.indexOf(this.state.text_filter) !== -1
  );

  _pointOptions = () => {
    const options = this.props.data_basket.map_objects.map(item => ({
      key: item.key,
      text: item.properties.iconContent,
      value: `${item.key}`
    }));
    options.unshift({key: 0, text: 'Все точки', value: '0'});
    return options;
  };

  _paymentOptions = () => [
    {key: 0, value: '0', text: 'Все типы оплаты'},
    {key: 1, value: '1', text: 'При получении'},
    {key: 2, value: '2', text: 'Предоплата (оплачен)'},
    {key: 3, value: '3', text: 'Предоплата (неоплачен)'},
  ];

  _payment_filter = (state, item) => {
    if (state === '1') {
      return (item.details.selected_payment !== 'online') ? true : false;
    } else if (state === '2') {
      return (item.details.selected_payment === 'online' && item.payment_state === 1) ? true : false;
    } else if (state === '3') {
      return (item.details.selected_payment === 'online' && item.payment_state !== 1) ? true : false;
    } else {
      return false;
    }
  }

  _ordersFilter = () => this.props.data.orders
    .filter(item => this.state.current_state_filter === '0' || this.state.current_state_filter === `${item.state}`)
    .filter(item => this.state.text_filter === '' || this._commonFilter(item))
    .filter(item => this.state.current_point_filter === '0' || this.state.current_point_filter === `${item.details.delivery_point}`)
    .filter(item => this.state.current_payment_filter === '0' || this._payment_filter(this.state.current_payment_filter, item))
    .filter(item => this.state.current_delivery_filter === '0' || this._delivery_filter(this.state.current_delivery_filter, item));


  // _onTableExportClick = () => {
  //   tableToExcel(this._ordersFilter());
  // };

  onPrintClick = () => {
    html2canvas(document.querySelector('#orderForPrint'))
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage({imageData: imgData, x: 5, y: 5, w: 200, h: 80});
        pdf.save("print.pdf");
    });
  };

  deliveryPoint = point => {
    if (point) {
      const t = this.props.data_basket.map_objects.filter(item => point === item.key);
      if (t.length === 1) {
        return t[0].properties.hintContent;
      }
    }
    return '';
  };

  _deliveryOptions = () => [
    {key: 0, value: '0', text: 'Все типы доставки'},
    {key: 1, value: 'pickup', text: 'Самовывоз'},
    {key: 2, value: 'delivery', text: 'Доставка до двери по Туле'},
    {key: 3, value: 'company', text: 'Транспортная компания'},
  ];

  _delivery_filter = (state, item) => {
    return (state === item.details.selected_delivery);
  }

  selectedChangeOptions = action => (e, {value}) => {
    if (action === 'payment') {
      this.setState({selected_payment_value: value});
    }

    if (action === 'delivery') {
      if (value === 'company') {
        if (this.state.selected_check_delivery_payment) {
          this._addDeliveryPayment(this.state.delivery_payment);
        }
      } else {
        this._delDeliveryPayment();
      }
      this.setState({selected_delivery_value: value});
    }

    if (action === 'address') {
      this.setState({selected_delivery_address: value});
    }

    if (action === 'track_number') {
      this.setState({track_number: value});
    }

    if (action === 'delivery_payment') {
      this._addDeliveryPayment(parseFloat(value) || 0);
      this.setState({delivery_payment: value});
    }
  }

  _addDeliveryPayment = value => {
    const { selected_order } = this.props.data;
    const { items } = this.props.data.selected_order.details;
    const t_d = items.filter(item => item.is_delivery);
    if (t_d.length) {
      items.forEach(item => {
        if (item.is_delivery) {
          item.price = parseFloat(value) || 0.00;
        }
      })
    } else {
      items.push({
        article: '',
        autor_kod: 0,
        checked: false,
        count: 1,
        item: '',
        name: 'Обработка и упаковка заказа',
        price: parseFloat(value) || 0.00,
        producer: '',
        quantity: 1,
        quantity_real: 1,
        trashed: false,
        unit: 'ШТ',
        werk_article: '',
        is_delivery: true,
      });
    }
    this.props.data.selected_order.details.items = items;
    store.dispatch(setAdminAppSelectedOrder(selected_order))
  }

  _delDeliveryPayment = () => {
    const { selected_order } = this.props.data;
    const { items } = this.props.data.selected_order.details;
    this.props.data.selected_order.details.items = items.filter(item => !item.is_delivery);
    store.dispatch(setAdminAppSelectedOrder(selected_order));
    this.setState({delivery_payment: '0'});
  }

  _companyChange = action => (e, {checked}) => {
    if (action === 'cdek') {
      this.setState({selected_cdek: checked})
    }

    if (action === 'russianpost') {
      this.setState({selected_russianpost: checked})
    }

    if (action === 'in_order') {
      console.log(this.props.data.selected_order.details.items);
      if (checked) {
        this._addDeliveryPayment(this.state.delivery_payment);
      } else {
        this._delDeliveryPayment();
      }
      this.setState({selected_check_delivery_payment: checked})
    }
  }


  render () {
    // const { orders } = this.props.data;

    return (
      <OrdersView
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        changeDate={this.changeDate}

        orders={this._ordersFilter().slice((this.state.activePage - 1) * this.state.rowPerPage, this.state.activePage * this.state.rowPerPage)}
        activePage={this.state.activePage}
        perPage={this.state.rowPerPage}
        totalPages={Math.ceil(this._ordersFilter().length / this.state.rowPerPage)}
        changePage={this.changePage}

        delivery_point={this.delivery_point}

        onTableExportClick={this._onTableExportClick}
        onPrintClick={this.onPrintClick}

        onOrderClick={this.onOrderClick}
        onCloseEditor={this.onCloseEditor}
        open_editor={this.state.open_editor}
        selected_order={this.props.data.selected_order}
        itemChecked={this.itemChecked}
        selected={this.state.selected}

        state_options={this.props.data.order_states}
        stateChange={this.stateChange}

        current_state_filter={this.state.current_state_filter}
        text_filter={this.state.text_filter}
        current_point_filter={this.state.current_point_filter}
        changeFilter={this.changeFilter}

        point_options={this._pointOptions()}

        payment_options={this._paymentOptions()}
        current_payment_filter={this.state.current_payment_filter}

        delivery_options={this._deliveryOptions()}
        current_delivery_filter={this.state.current_delivery_filter}

        deliveryPoint={this.deliveryPoint}

        selected_payment_value={this.state.selected_payment_value}
        selected_delivery_value={this.state.selected_delivery_value}
        selected_delivery_address={this.state.selected_delivery_address}
        selected_cdek={this.state.selected_cdek}
        selected_russianpost={this.state.selected_russianpost}
        selected_check_delivery_payment={this.state.selected_check_delivery_payment}
        delivery_payment={this.state.delivery_payment}
        track_number={this.state.track_number}

        selectedChangeOptions={this.selectedChangeOptions}
        companyChange={this._companyChange}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData,
    data_basket: store.BasketState.BasketData,
  }
}


export default connect(mapStateToProps)(Orders);
