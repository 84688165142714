import React, { Component } from 'react';


class GetCatNet extends Component {
  render () {
    return (
      <div>
        <iframe
          title='catalogue_getcatnet'
          src="https://getcat.net/view.php?cat=maintenance&amp;ach=74f3bc3a9cc23f5b1fcf5f924daf4d64"
          width='100%' height='740px'
          scrolling='auto' frameborder="0" >
        </iframe>
      </div>
    )
  }
}


export default GetCatNet;
