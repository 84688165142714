import React, { Component } from 'react';

import { Button, Container, Form, Header,
         Icon, Message } from 'semantic-ui-react'


class CrossesView extends Component {

  state = {
    item: '',
    cross: '',
    crosses: [],
  }

  componentDidUpdate(prevProps) {
    if (this.props.item !== null) {
      if (this.props.item.crosses !== null && (prevProps.item === null || this.props.item.crosses !== prevProps.item.crosses) ) {
        this.setState({
          crosses: this.props.item.crosses ? this.props.item.crosses.split(',') : [],
        });
      }
    }
  };

  _onSeacrhClick = () => {
    this.setState({item: '', cross: '', crosses: []});
    this.props.onSearchClick(this.state.item);
  };

  _onSaveClick = () => {
    this.props.onSaveCrosses(this.state.crosses);
  };

  _makeOptions = () => this.state.crosses.map((item, i) => ({
    key: i,
    value: item,
    text: item,
  }));

  _makeValue = () => this.state.crosses;

  _addCross = () => {
    const { crosses, cross } = this.state;
    if (crosses.indexOf(cross) === -1) {
      crosses.push(cross);
      this.setState({
        crosses,
        cross: '',
      })
    }
  };

  _changeCrosses = (e, {value}) => {
    this.setState({crosses: value});
  };

  render () {
    const { item } = this.props;

    return (
      <Container>
        <Header as='h4' color={'grey'} >
          <Icon name='edit' />
          <Header.Content>
            {'Кросс-артикулы'}
            <Header.Subheader>
              {'Пересекающиеся артикулы'}
            </Header.Subheader>
          </Header.Content>
        </Header>

        <Form>
          <Form.Input
            width={6}
            label={' '}
            icon={<Icon name='search' inverted circular link onClick={this._onSeacrhClick} />}
            placeholder={'Поиск по коду...'}
            value={this.state.item}
            onChange={(e, {value}) => {this.setState({item: value})}}
          />

          {item
            // eslint-disable-next-line
            &&  <div>
                <Form.Group>
                  <Form.Input
                    width={2}
                    label={'Код'}
                    value={item.item}
                  />

                  <Form.Input
                    width={4}
                    label={'Артикул (оригинальный)'}
                    value={item.original_article}
                  />

                  <Form.Input  fluid
                    width={10}
                    label={'Наименование'}
                    value={item.name}
                  />
                </Form.Group>

                <Form.Group widths='equal'>
                  <Form.Input
                    width={6}
                    label={'Добавить кросс'}
                    icon={<Icon name='plus' inverted circular link onClick={this._addCross} />}
                    placeholder='Введите кросс...'
                    value={this.state.cross}
                    onChange={(e, {value}) => {this.setState({cross: value})}}
                  />
                  <Form.Dropdown fluid label='Пересекающиеся артикулы'
                    multiple selection options={this._makeOptions()}
                    value={this._makeValue()}
                    onChange={this._changeCrosses}
                  />
                </Form.Group>

                <Button primary content='Сохранить' icon='save' labelPosition='right'
                  onClick={this._onSaveClick} />

                </div>
            // eslint-disable-next-line
            ||  <Message info
                  icon={'info'}
                  header='Введите код'
                  content='Укажите код товарной позиции'
                />}
        </Form>
      </Container>
    )
  }
}


export default CrossesView;
