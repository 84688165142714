import React, { Component } from 'react';

import { Button, Container, Grid, Header, Icon, Label, Message, Tab } from 'semantic-ui-react'


class AdminAppView extends Component {

  render () {
    return (
        <Container style={{paddingTop: '1em'}}>
          {this.props.need_auth
            // eslint-disable-next-line
            &&  <div>
                  <style>{`
                    body > div,
                    body > div > div,
                    body > div > div > div.login-form {
                      height: 100%;
                    }
                  `}</style>
                  <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Row>
                      <Grid.Column style={{ maxWidth: 450 }} textAlign='left'>
                        <Message
                          info
                          icon='info circle'
                          header='Закрытая часть сайта'
                          content={'Для доступа авторизуйтесь через VK'}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <Button color='vk' onClick={this.props.vkLogin}>
                          <Icon name='vk' /> VK
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
            // eslint-disable-next-line
            ||  <div>
                  <Grid>
                    <Grid.Row columns={2} >
                      <Grid.Column width={6}>
                        <Header as='h2' color={'grey'} >
                          <Icon name='cog' />
                          <Header.Content>
                            {'Админка'}
                            <Header.Subheader>
                              {'Панель администрирования'}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={4} >
                        <Label>{`${this.props.user.first_name} ${this.props.user.last_name}`}</Label>
                        <Button color='vk' onClick={this.props.vkLogout}>
                          <Icon name='vk' /> выйти из админки
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Tab menu={{ secondary: true, pointing: true }} panes={this.props.panes} />
                </div>
          }

        </Container>
    )
  }
}


export default AdminAppView;
