import React, { Component } from 'react';

import { Button, Dimmer, Form, Icon, Loader, Message, Modal, Segment } from 'semantic-ui-react';

import Recaptcha from 'react-recaptcha';


// eslint-disable-next-line
let recaptchaInstance;

const list = [
  'поля помеченные * обязательны для заполнения;',
  'укажите своё имя и действующие контактные данные для обратной связи, выберите тему обращения, кратко опишите суть запроса;',
  'нажмите кнопку "отправить" и дождитесь уведомления об обработке вашего запроса.',
];

const options = [
  {key: '1', value: '1', text: 'Отзыв или предложение'},
  {key: '2', value: '2', text: 'Заявка на возврат'},
];

const validateEmail = email => {
  // eslint-disable-next-line
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}


class ModalReviewView extends Component {
  state = {
    verified: false,
    form: {
      name: '',
      email: '',
      phone: '+7',
      theme: '1',
      order: '',
      review: '',
    },
  }

  _onInit = () => {
    this.setState({
      form: {
        name: '',
        email: '',
        phone: '+7',
        theme: '1',
        order: '',
        review: '',
      },
      verified: false,
    });
  }

  _close = () => {
    this._onInit();
    this.props.onClose();
  };

  _send = () => {
    const { form } = Object.assign({}, this.state);
    const theme = options.filter(item => item.value === form.theme);
    if (theme.length) {
      form.theme = theme[0].text;
    } else {
      form.theme = '';
    }

    this.props.onSend(form);
  };

  _onChange = action => (e, {value}) => {
    const { form } = Object.assign({}, this.state);

    if (action === 'review') {
      if (value.length <= 1000) {
        form.review = value;
      }
    } else if (action === 'phone') {
      if (value.substring(0, 1) === '+' ) {
        var temp = value.slice(1);
      } else {
        temp = value;
      }

      temp = temp.split(' ').join('');
      temp = temp.split('-').join('');
      temp = [ temp.substring(0, 1), temp.substring(1, 4),
               temp.substring(4, 7),
               temp.substring(7, 9), temp.substring(9, 11)]

      if (parseInt(temp, 10)) {
        form.phone = '+' + temp.filter(item => item.length > 0).join(' ');
      }
    } else {
      form[action] = value;
    }

    this.setState({form});
  };

  _sendDisabled = () => {
    if (!this.state.verified) {
      return true;
    }

    if ((this.props.state === 'fill') || (this.props.state === 'error')) {
      if ( (this.state.form.name.length > 3) && (validateEmail(this.state.form.email)) && (this.state.form.theme) && (this.state.form.review.length > 5) ) {
        return false;
      }
    }

    return true;
  };

  _form = () => (
  <Form>

    <Form.Group widths='equal'>
      <Form.Input
        label=<span style={{color: this.state.form.name.length > 3 ? 'teal' : 'red', fontWeight: 'bold'}} >{'Имя *'}</span>
        placeholder='Имя'
        value={this.state.form.name} onChange={this._onChange('name')} />

      <Form.Input
        label=<span style={{color: validateEmail(this.state.form.email) ? 'teal' : 'red', fontWeight: 'bold'}} >{'E-mail *'}</span>
        placeholder='Адрес электронной почты'
        value={this.state.form.email} onChange={this._onChange('email')} />

      <Form.Input
        label=<span style={{color: 'teal', fontWeight: 'bold'}} >{'Номер телефона'}</span>
        value={this.state.form.phone} onChange={this._onChange('phone')} />
    </Form.Group>

    <Form.Group widths='equal'>

      <Form.Select
        label=<span style={{color: this.state.form.theme ? 'teal' : 'red', fontWeight: 'bold'}} >{'Тема обращения *'}</span>
        placeholder='Выберите тему обращения'
        options={options}
        value={this.state.form.theme} onChange={this._onChange('theme')} />

      <Form.Input
        label=<span style={{color: 'teal', fontWeight: 'bold'}} >{'Номер заказа'}</span>
        placeholder='Номер заказа'
        value={this.state.form.order} onChange={this._onChange('order')} />
    </Form.Group>

    <Form.Group widths='equal'>
      <Form.TextArea
        fluid
        label=<span style={{color: this.state.form.review.length > 5 ? 'teal' : 'red', fontWeight: 'bold'}} >{`Текст запроса * (${1000 - this.state.form.review.length} знаков осталось)`}</span>
        placeholder='Напишите о том, что вас интересует...'
        value={this.state.form.review} onChange={this._onChange('review')} />
      <Form.Field width={6}>
        <label>&nbsp;</label>
        <Recaptcha
            hl='ru'
            ref={e => recaptchaInstance = e}
            render='explicit'
            sitekey="6Ldul88UAAAAAEr_L-qhyOo5abS3VFgmk6pWJo30"
            onloadCallback={() => {}}
            verifyCallback={this._verifyCallback}
          />
      </Form.Field>
    </Form.Group>

  </Form>);


  _verifyCallback = () => {
    this.setState({verified: true})
  };


  render() {
    return (
      <Modal open={this.props.open} onClose={this._close}>
        <Modal.Header>{'Отзывы и предложения'}</Modal.Header>
        <Modal.Content>
          <Segment>
            <Dimmer active={this.props.state === 'send'} inverted>
              <Loader inverted>{'Отправка сообщения...'}</Loader>
            </Dimmer>

            { this.props.state === 'fill'
                &&  <Message positive
                      icon='info'
                      header={'Как оставить отзыв или предложение:'}
                      list={list} /> }

            { this.props.state === 'error'
                &&  <Message negative
                      icon='warning'
                      header={'Ошибка отправки сообщения'}
                      content={'Проверьте правильность заполненной информации и повторите отправку через какое-то время.'} /> }

            { this.props.state === 'success'
                // eslint-disable-next-line
                &&  <Message positive
                      icon='check'
                      header={'Сообщение отправлено'}
                      content={'Ваше сообщение отправлено и будет рассмотрено. Если сообщение требует обратной связи, с вами свяжется наш сотрудник по указанным в сообщении реквизитам.'} />
                // eslint-disable-next-line
                || this._form() }

          </Segment>

        </Modal.Content>
        <Modal.Actions>
          <Button icon onClick={this._close} labelPosition='left'
                  disabled={this.props.state === 'send'} >
            <Icon name='close' />
            {'Закрыть'}
          </Button>
          <Button icon color={'teal'} onClick={this._send} labelPosition='left'
                  disabled={this._sendDisabled()} >
            <Icon name='check' />
            {'Отправить'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
};


export default ModalReviewView;
