import React, { Component } from 'react';

import {isMobile} from 'react-device-detect';

import { Grid, Icon, } from 'semantic-ui-react';
import { Message, Modal } from 'semantic-ui-react';

import Rules from '../rules/container';

import OrderRegistration from './order_registration';
import OrderRegistrationHorizontal from './order_registration_horizontal';
import BasketItems from './items';
import DeliveryMap from './map';
import Confirm from './confirm/container';


class BasketView extends Component {

  render () {

    const { activePayment, activeDelivery, paymentValue, deliveryValue } = this.props;
    const { paymentChange, deliveryChange, paymentClick, deliveryClick,
            currentDelivery } = this.props;
    const { delivery_items, payment_items } = this.props;
    const { for_payment } = this.props;

    const { items, price_renew_alert } = this.props;

    const { deleteItem, checkItem, checkAll, deleteChecked } = this.props;
    const { incCount, decCount, changeCount } = this.props;

    const { confirm_rules, changeConfirmRules,
            iAgreeRules, iDisagreeRules } = this.props;

    const { name, phone, email, comment, delivery_point, delivery_address,
            post_index } = this.props;

    const { map_open, selected_point, openMap, closeMap } = this.props;
    const { points, map_center, map_zoom,
            mapObjectClick, mapListItemClick } = this.props;
    const { onContactInfoChange } = this.props;

    const { name_error, email_error, phone_error,
            post_index_error } = this.props;

    const { valid_recipient, valid_address } = this.props;
    const { setDeliveryAddressFromMap, cdek_check, russianpost_check, companyChange } = this.props;

    const { releaseOrder } = this.props;

    return (
      <div style={{marginBottom: '3em'}}>
        <div style={{height: '1em'}}/>

        {!isMobile
          // eslint-disable-next-line
          &&  <Grid>
                <Grid.Row columns={2} >
                  <Grid.Column width={11} >
                    <BasketItems
                      items={items}
                      deleteItem={deleteItem}
                      checkItem={checkItem}
                      checkAll={checkAll}
                      deleteChecked={deleteChecked}

                      incCount={incCount}
                      decCount={decCount}
                      changeCount={changeCount}
                    />
                  </Grid.Column>

                  <Grid.Column width={5} >
                    <OrderRegistration

                      activeDelivery={activeDelivery}
                      activePayment={activePayment}
                      deliveryValue={deliveryValue}
                      paymentValue={paymentValue}

                      paymentChange={paymentChange}
                      deliveryChange={deliveryChange}
                      paymentClick={paymentClick}
                      deliveryClick={deliveryClick}
                      currentDelivery={currentDelivery}

                      delivery_items={delivery_items}
                      payment_items={payment_items}

                      for_payment={for_payment}

                      confirm_rules={confirm_rules}
                      changeConfirmRules={changeConfirmRules}

                      name={name}
                      email={email}
                      phone={phone}
                      comment={comment}
                      delivery_point={delivery_point}
                      delivery_address={delivery_address}
                      post_index={post_index}

                      openMap={openMap}

                      valid_recipient={valid_recipient}
                      valid_address={valid_address}

                      map_open={map_open}
                      price_renew_alert={price_renew_alert}

                      releaseOrder={releaseOrder}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
          // eslint-disable-next-line
          ||  <Grid>

                <Grid.Row columns={1} >
                  <Grid.Column width={16} >
                    <BasketItems
                      items={items}
                      deleteItem={deleteItem}
                      checkItem={checkItem}
                      checkAll={checkAll}
                      deleteChecked={deleteChecked}

                      incCount={incCount}
                      decCount={decCount}
                      changeCount={changeCount}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1} >
                  <OrderRegistrationHorizontal
                    activeDelivery={activeDelivery}
                    activePayment={activePayment}
                    deliveryValue={deliveryValue}
                    paymentValue={paymentValue}

                    paymentChange={paymentChange}
                    deliveryChange={deliveryChange}
                    paymentClick={paymentClick}
                    deliveryClick={deliveryClick}
                    currentDelivery={currentDelivery}

                    delivery_items={delivery_items}
                    payment_items={payment_items}

                    for_payment={for_payment}

                    confirm_rules={confirm_rules}
                    changeConfirmRules={changeConfirmRules}

                    name={name}
                    email={email}
                    phone={phone}
                    comment={comment}
                    delivery_point={delivery_point}
                    delivery_address={delivery_address}
                    post_index={post_index}

                    openMap={openMap}

                    valid_recipient={valid_recipient}
                    valid_address={valid_address}

                    map_open={map_open}
                    price_renew_alert={price_renew_alert}

                    releaseOrder={releaseOrder}
                  />
                </Grid.Row>

              </Grid>
        }

        <Modal open={price_renew_alert} basic size='small' dimmer={'inverted'} >
          <Modal.Content>
            <Message icon info>
              <Icon name='circle notched' loading />
              <Message.Content>
                <Message.Header>{'Несколько секунд...'}</Message.Header>
                {'Подождите, мы проверим цены!'}
              </Message.Content>
            </Message>
          </Modal.Content>
        </Modal>

        <DeliveryMap map_open={map_open}
          selected_point={selected_point}
          points={points}
          map_center={map_center} map_zoom={map_zoom}
          mapObjectClick={mapObjectClick} mapListItemClick={mapListItemClick}
          onClose={closeMap}

          name={name}
          phone={phone}
          email={email}
          comment={comment}
          post_index={post_index}

          delivery_value={deliveryValue}
          delivery_address={delivery_address}

          onContactInfoChange={onContactInfoChange}

          name_error={name_error}
          email_error={email_error}
          phone_error={phone_error}
          post_index_error={post_index_error}

          setDeliveryAddressFromMap={setDeliveryAddressFromMap}
          cdek_check={cdek_check}
          russianpost_check={russianpost_check}
          companyChange={companyChange}
        />

        <Rules agreeHandler={iAgreeRules} disagreeHandler={iDisagreeRules} />
        <Confirm />
      </div>
    )
  }
}


export default BasketView;
