import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Dropdown, Form, Grid,
         Header, Icon, List, Modal } from 'semantic-ui-react'

import OrderItemsTable from '../tables/orderitems';

import * as ws from '../../../api/connections';
import store from '../../../store';
import { setAdminOrderXlsxLink } from '../actions';


const payment_options = [
  {key: '0', value: 'online', text: 'Предоплата-онлайн'},
  {key: '1', value: 'offline', text: 'При получении'}
]

const delivery_options = [
  {key: 1, value: 'pickup', text: 'Самовывоз'},
  {key: 2, value: 'delivery', text: 'Доставка до двери по Туле'},
  {key: 3, value: 'company', text: 'Транспортная компания'},
]


const OrderStates = props => {
  return (
    <Dropdown fluid search selection options={props.options}
              value={props.value} onChange={props.onChange} />
  );
}


class ModalOrderEditor extends Component {

  componentDidUpdate(prevProps) {

    const { order_xlsx_link } = this.props.data;
    const prev_order_xlsx_link = prevProps.data.order_xlsx_link;

    if (order_xlsx_link !== prev_order_xlsx_link && order_xlsx_link !== '' && order_xlsx_link !== 'calc') {
      this._download_report(order_xlsx_link)
    }

  }

  _payment_type = details => details.selected_payment && details.selected_payment === 'online'
      // eslint-disable-next-line
      && 'Предоплата (онлайн)'
      // eslint-disable-next-line
      || 'При получении';

  _payment_state = state => {
    switch (state) {
      case 0: return 'Ожидает';
      case 1: return 'Оплачено';
      case 2: return 'Возврат';

      default: return 'Ожидает';
    }
  };

  _paymentIcon = state => {
    switch (state) {
      case 0: return 'wait';
      case 1: return 'check';
      case 2: return 'stop circle';

      default: return 'wait';
    }
  };

  _items_is_checked = () => {
    if (Object.keys(this.props.selected).length > 0) {
      return Object.keys(this.props.selected).filter(item => this.props.selected[item].checked || this.props.selected[item].trashed).length > 0;
    } else {
      return this.props.item.details.items.filter(item => item.checked).length > 0;
    }
  }

  _getReport = item => () => {
    if (this.props.data.order_xlsx_link !== 'calc') {
      store.dispatch(setAdminOrderXlsxLink('calc'));
      ws.doSend({
        command: 'orderToXLSX',
        params: {
          order: item.order_uuid,
        }
      })
    }
  }

  _download_report = report => {
    var link = document.createElement('a');
    link.download = '';
    link.href = `/report/${report}`;
    link.click();
  }

  _onClose = action => () => {
    store.dispatch(setAdminOrderXlsxLink(''));
    this.props.onClose(action);
  }


  render () {

    const { selectedChangeOptions, companyChange,
            selected_payment_value, selected_delivery_value, selected_delivery_address,
            selected_check_delivery_payment, delivery_payment, track_number,
            selected_cdek, selected_russianpost, } = this.props;

    const { order_xlsx_link } = this.props.data;

    return (
      <Modal  closeIcon
              dimmer={'inverted'} size={'large'}
              open={this.props.open} onClose={this._onClose('cancel')} >
        <Modal.Header>
          <Header as='h5'>
            <Icon name='edit' />
            <Header.Content>
              {'Редактирование заказа'}
              <Header.Subheader>
                {'Изменение статуса заказа'}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Grid id='orderForPrint'>
            <Grid.Row columns={5} >

              <Grid.Column>
                <List>
                  <List.Item
                    icon='calendar'
                    header={'Дата и время заказа'}
                    content={this.props.item.created} />
                </List>
              </Grid.Column>

              <Grid.Column>
                <List>
                  <List.Item
                    icon='check'
                    header={'Номер заказа'}
                    content={this.props.item.details.order ? this.props.item.details.order : ''} />
                </List>
              </Grid.Column>

              <Grid.Column>
                <List>
                  <List.Item
                    icon='user'
                    header={'Получатель'}
                    content={this.props.item.details.personal.name} />
                </List>
              </Grid.Column>

              <Grid.Column>
                <List>
                  <List.Item
                    icon='envelope'
                    header={'E-mail'}
                    content={this.props.item.details.personal.email} />
                </List>
              </Grid.Column>

              <Grid.Column>
                <List>
                  <List.Item
                    icon='phone'
                    header={'Телефон'}
                    content={this.props.item.details.personal.phone} />
                </List>
              </Grid.Column>

            </Grid.Row>

            <Grid.Row columns={3} >

              <Grid.Column width={4} >
                <Header as={'h5'} content={'Состояние заказа'} />
                <OrderStates options={this._items_is_checked() ? this.props.state_options.slice(1) : this.props.state_options.slice(1, 2)}
                                 value={`${this.props.item.state}`}
                                 onChange={this.props.stateChange} />
                <Header as={'h5'} content={'Оплата'} />
                <Dropdown selection
                  options={payment_options}
                  disabled={this.props.item.details.selected_payment === 'offline'}
                  value={selected_payment_value || this.props.item.details.selected_payment}
                  onChange={selectedChangeOptions('payment')}
                />
                {(this.props.item.details.selected_payment === 'online')
                  &&  <Icon style={{marginLeft: '0.5em'}} name={this._paymentIcon(this.props.item.payment_state)} size={'large'} color={this.props.item.payment_state === 1 ? 'green' : 'red'} />}
              </Grid.Column>

              <Grid.Column width={12}>

                <Form>

                  <Form.Group widths={'equal'} >
                    <Form.Dropdown selection label={'Доставка'} width={6}
                      options={delivery_options}
                      value={selected_delivery_value || this.props.item.details.selected_delivery}
                      onChange={selectedChangeOptions('delivery')}
                    />

                    <Form.Input style={{marginTop: '0.5em'}} fluid label={'Адрес'}
                      value={selected_delivery_address || this.props.item.details.delivery_address}
                      onChange={selectedChangeOptions('address')}
                    />
                  </Form.Group>

                  {((selected_delivery_value || this.props.item.details.selected_delivery) === 'company')
                    &&  <Form.Group widths={'equal'} >

                          <Form.Checkbox width={2} style={{marginTop: '1em'}}
                            label={'СДЕК'}
                            checked={selected_cdek !== null ? selected_cdek : this.props.item.details.selected_company.cdek}
                            onChange={companyChange('cdek')}
                          />

                          <Form.Checkbox width={2} style={{marginTop: '1em'}}
                            label={'Почта'}
                            checked={selected_russianpost !== null ? selected_russianpost : this.props.item.details.selected_company.russianpost}
                            onChange={companyChange('russianpost')}
                          />

                          <Form.Input float={'right'} width={5} label={'Трек-номер'}
                            placeholder={'Трек-номер'}
                            value={track_number || this.props.item.details.track_number}
                            onChange={selectedChangeOptions('track_number')}
                          />

                          <Form.Field width={1} />

                          <Form.Checkbox width={2} style={{marginTop: '1em'}}
                            label={'оплата'}
                            checked={selected_check_delivery_payment !== null ? selected_check_delivery_payment : this.props.item.details.delivery_payment_in_order}
                            onChange={companyChange('in_order')}
                          />

                          {(selected_check_delivery_payment === null ? this.props.item.details.delivery_payment_in_order : selected_check_delivery_payment)
                            // eslint-disable-next-line
                            &&  <Form.Input float={'right'} width={4} label={'Сумма доставки'}
                                  placeholder={'Трек-номер'}
                                  value={delivery_payment || this.props.item.details.delivery_payment}
                                  onChange={selectedChangeOptions('delivery_payment')}
                                />
                            // eslint-disable-next-line
                            || <Form.Field width={4} />

                          }

                        </Form.Group>

                  }





                </Form>
              </Grid.Column>

            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <OrderItemsTable
                  rows={this.props.item.details.items}
                  selected={this.props.selected}
                  itemChecked={this.props.itemChecked}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this._onClose('cancel')} content={'Закрыть'} />
          <Button primary onClick={this._onClose('save')} content={'Сохранить'} />
          <Button disabled={order_xlsx_link === 'calc'}
            loading={order_xlsx_link === 'calc'}
            color={'teal'}
            onClick={this._getReport(this.props.item)}
            content={'в Excel'}
            icon={'download'}
          />
          <Button color={'teal'} onClick={this.props.onPrintClick} content={'печать'} icon={'print'} />
        </Modal.Actions>
      </Modal>
    );
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData,
  }
}

export default connect(mapStateToProps)(ModalOrderEditor);
