import React, { Component } from 'react';
import { connect } from 'react-redux';

// import * as ws from '../../api/connections';
// import store from '../../../store';

import OrderTableView from './component';


class OrderTable extends Component {

  _stateOrder = order => {
    const t = this.props.state_options.filter(item => item.value === `${order.state}`);
    if (t.length === 1) {
      return t[0]
    } else {
      return {key: -9, value: 'unknown', text: 'не определён'}
    }
  };

  _selectedPoint = order => {
    if (!order.details) {
      return 'не определена'
    }
    const t = this.props.data_basket.map_objects.filter(item => item.key === order.details.delivery_point);
    if (t.length === 1) {
      return t[0].properties.hintContent
    } else {
      return order.details.delivery_address
    }
  };


  render () {
    return (
      <OrderTableView rows={this.props.data.order.details.items || []}
        order={this.props.data.order}
        order_uuid={this.props.data.order.order_uuid}
        order_number={this.props.data.order.details.order}
        order_state={this._stateOrder(this.props.data.order)}
        point={this._selectedPoint(this.props.data.order)}
        created={this.props.data.order.created}

        payment_state={this.props.data.order.payment_state}
        payment_type={
          (this.props.data.order.details.selected_payment && this.props.data.order.details.selected_payment === 'online')
          // eslint-disable-next-line
          && 'Предоплата (онлайн)'
          // eslint-disable-next-line
          || 'Оплата при получении'
        }
        payment_url={this.props.data.order.payment_url}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.CheckOrderState.CheckOrderData,
    data_basket: store.BasketState.BasketData,
  }
}


export default connect(mapStateToProps)(OrderTable);
