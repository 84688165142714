import React from 'react';

import { Tab } from 'semantic-ui-react';


class SearchView extends React.Component {

  render() {
    return (
      <Tab
        activeIndex={this.props.activeIndex}
        menu={{ secondary: true, pointing: true }}
        panes={this.props.panes}
        onTabChange={this.props.onTabChange}
      />
    );
  }
}


export default SearchView;
