import React from 'react';

import { Layout } from 'antd';
import 'antd/dist/antd.css';

import OzonAdminPanel from '../../containers/ozon/container';


const OzonLayout = () => {
  return (
    <Layout>
      <OzonAdminPanel />
    </Layout>
  )
}


export default OzonLayout;
