import * as types from '../../../../actions/action-types'


export function setSearchResultRows(data) {
  return {
    type: types.SET_SEARCH_RESULT_ROWS,
    data
  }
}


export function setSearchResultTitle(data) {
  return {
    type: types.SET_SEARCH_RESULT_TITLE,
    data
  }
}


export function setSearchResultFilter(data) {
  return {
    type: types.SET_SEARCH_RESULT_FILTER,
    data
  }
}


export function setSearchResultActivePage(data) {
  return {
    type: types.SET_SEARCH_RESULT_ACTIVE_PAGE,
    data
  }
}


export function setSearchResultTotalPages(data) {
  return {
    type: types.SET_SEARCH_RESULT_TOTAL_PAGES,
    data
  }
}
