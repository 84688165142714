import React, { Component } from 'react';

import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';

// import store from '../../../store';
// import { setBasketDelivery } from '../actions/actions';


class ConfirmView extends Component {

  render () {
    const { open, blocked = true, onClose, goToOrder, header, content } = this.props;
    const { warning, error, success } = this.props;
    const { icon_message } = this.props;

    const style = {
      position: 'absolute',
      top: '30%'
    }

    return (
      <Modal closeIcon style={style}
             dimmer={'inverted'} size={'tiny'}
             open={open} >
        <Header icon={'upload'} content={'Сохранение заказа'} />
        <Modal.Content>
          <Message icon
                   success={success} negative={error} warning={warning} >
            <Icon name={icon_message.name} loading={icon_message.loading} />
              <Message.Content>
                <Message.Header>{header}</Message.Header>
                  {content}
              </Message.Content>
            </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={goToOrder} disabled={blocked} >
            {'Перейти к заказу'}
          </Button>
          <Button secondary onClick={onClose} disabled={blocked} >
            {'Закрыть'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}


export default ConfirmView;
