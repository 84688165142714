import React, { Component } from 'react';

import ResultTableView from './component';

import { storeItemToBasket, checkItemInBasket } from '../../../../api/functions';


class ResultTable extends Component {

  _prepare = () => this.props.rows
    .map((item, i) => {
      item.checked = checkItemInBasket(item.item);
      return item;
    })

  render () {
    return (
      <ResultTableView rows={this._prepare()}
                       onClick={storeItemToBasket}
                       t={this.props.t}

                       filter_art={this.props.filter_art}
                       filter_name={this.props.filter_name}

                       changeFilterArt={this.props.changeFilterArt}
                       changeFilterName={this.props.changeFilterName}
                       dimmer={this.props.dimmer} />
    )
  }
}


export default ResultTable;

