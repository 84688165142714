import React, { Component } from 'react';

import {
  Divider, Grid, Header, Image, List, Segment,
  Button, Icon
} from 'semantic-ui-react'


// import Rules from '../rules/container';

const style = {
  background: 'url(/images/footer_bg.png)',
  backgroundRepeat: 'repeatX',
  backgroundSize: 'auto 100%',
}


class FooterView extends Component {
  render () {
    return (
      <Segment vertical inverted style={style} >
          <Grid divided stackable>
            <Grid.Row columns={3} >
              <Grid.Column width={6} >
                <List>
                  <List.Item>
                    <List.Icon name='phone' />
                    <List.Content><div>{'Телефон: '}<a href={'+7 (910) 164-46-56'}>{'+7 (910) 164-46-56'}</a></div></List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='envelope' />
                    <List.Content><div>{'E-mail: '}<a href={'mailto:texkomplus71@yandex.ru'}>{'texkomplus71@yandex.ru'}</a></div></List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='map marker' />
                    <List.Content color={'grey'}>{'Адрес офиса: 300001, г.Тула, ул. Карла Маркса, д.54а'}</List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4} >
                <Header as='h2' inverted >
                  <Header.Content>
                    {'«Техком+» '}
                    <Header.Subheader color={'grey'}>
                      {'для ТЕХКОМу дорог автомобиль'}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' inverted style={{marginTop: '-0.5em'}}>
                  <Header.Content>
                    {'Обработка заказов'}
                    <Header.Subheader color={'grey'}>
                      {'ежедневно с 9:00 до 20:00'}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' inverted style={{marginTop: '-0.5em'}}>
                  <Header.Content>
                    {'Доставка заказов'}
                    <Header.Subheader color={'grey'}>
                      {'по рабочим дням с 10:00 до 17:00'}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={3} >
                <Header as='h5' inverted >
                  <Header.Content>
                    {'Мы принимаем к оплате:'}
                    <Header.Subheader>
                      <Image src='/images/pay.png' />
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Divider />
                <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={this.props.onInfoClick('payments')}>{'Способы оплаты'}</span><br />
                <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={this.props.onInfoClick('delivery')}>{'Доставка'}</span><br />
                <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={this.props.onReviewClick}>{'Отзывы и предложения'}</span>
              </Grid.Column>

              <Grid.Column width={3} >
                <Button icon onClick={this.props.onHelpClick} labelPosition='left' >
                  <div style={{width: '6em'}}>{'Помощь'}</div>
                  <Icon name='help circle' />
                </Button>
                <Divider />
                <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={this.props.onInfoClick('oferta')}>{'Договор-оферта'}</span><br />
                <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={this.props.onInfoClick('safety')}>{'Конфиденциальность'}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
      </Segment>
    )
  }
}


export default FooterView;
