import * as types from '../../actions/action-types';


const initialState = {
  ModalViewerData: {
    open: false,
    title: 'Помощь покупателю',
    file_name: 'https://docs.google.com/viewer?url=https://shop.tehkom-avto.ru/docs/help.doc&embedded=true'
  }
}


const ModalViewerReducer = (state=initialState, action) => {
  switch (action.type) {

    case types.SET_MODAL_VIEWER_OPEN:
      return Object.assign({}, state, {
        ModalViewerData: Object.assign({}, state.ModalViewerData, {
          open: action.data
        })
      });

    case types.SET_MODAL_VIEWER_FILE_NAME:
      return Object.assign({}, state, {
        ModalViewerData: Object.assign({}, state.ModalViewerData, {
          file_name: action.data
        })
      });

    case types.SET_MODAL_VIEWER_TITLE:
      return Object.assign({}, state, {
        ModalViewerData: Object.assign({}, state.ModalViewerData, {
          title: action.data
        })
      });

    default: return state;
  }
}


export default ModalViewerReducer;
