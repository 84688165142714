import React, { Component } from 'react';

import AdminApp from '../../containers/admin/container';


class AdminLayout extends Component {

  componentDidMount = () => {
    if (document.getElementById('vk-jssdk')) {
      console.log('VK API already included')
      return;
    }

    this.loadVkSdk();
    this.asyncInit();
  };

  asyncInit = () => {
    console.log('Init VK API')
    window.vkAsyncInit = () => {
      window.VK.init({ apiId: 6915090  });
    };
  };

  loadVkSdk = () => {
    console.log('Load VK API')
    var el = document.createElement("script");
    el.type = "text/javascript";
    el.src = "https://vk.com/js/api/openapi.js?167";
    el.async = true;
    el.id = 'vk-jssdk';
    document.getElementById("vk_api_transport").appendChild(el);
  }

  render () {
    return (
      <div>
        <div id="vk_api_transport" />
        <AdminApp />
      </div>
    )
  }
}


export default AdminLayout;
