import React, { Component } from 'react';

import { Grid, Header, Icon, Pagination } from 'semantic-ui-react'

import  ResultTable from '../../../catalogue/tehkomsearch/resulttable/container';

class SearchResultView extends Component {
  render () {
    const { rows, totalPages, activePage } = this.props;
    const { onPageChange = () => {} } = this.props;
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
          </Grid.Column>
          <Grid.Column width={8} textAlign={'center'} verticalAlign={'middle'}>
            <Header as='h3'>{this.props.title}</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
              <ResultTable
                rows={rows}
                filter_art={this.props.filter_art}
                filter_name={this.props.filter_name}

                changeFilterArt={this.props.changeFilterArt}
                changeFilterName={this.props.changeFilterName}
                dimmer={this.props.dimmer}
              />

            <div style={{display: rows.length ? 'block' : 'None'}}>
            <Pagination
              activePage={activePage}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              totalPages={totalPages}
              onPageChange = {onPageChange}
            />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}


export default SearchResultView;
