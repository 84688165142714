import * as types from '../../actions/action-types'


const initialState = {
  BasketData: {
    items: [],

    payment_items: [
      {
        key: 'offline',
        header: 'оплата при получении',
        disabled: true,
      },
      {
        key: 'online',
        header: 'онлайн-предоплата, оплатить заказ можно будет на странице "Заказ. Проверка состояния" после того, как заказ будет проверен и собран',
        disabled: true,
      },
    ],

    delivery_items: [
      {
        key: 'pickup',
        header: 'самовывоз из магазина',
        disabled: true,
      },
      {
        key: 'delivery',
        header: 'доставка до двери в пределах г. Тулы при предоплате и сумме заказа не менее 1000 руб.',
        disabled: true,
      },
      {
        key: 'company',
        header: 'доставка транспортной компанией, услуги транспортной компани оплачиваются отдельно (стоимость доставки от 300 руб.)',
        disabled: true,
      },
    ],

    selected_payment: 'offline',
    selected_delivery: 'pickup',
    map_open: false,
    selected_point: 0,
    delivery_address: 'г. Тула',
    map_objects: [
      {
        key: 1,
        geometry: {type: 'Point', coordinates: [54.197420, 37.634793]},
        properties: {
          iconContent: 'Карла Маркса',
          hintContent: 'г.Тула, ул. Карла Маркса, д.54а'
        },
        options: {preset: 'islands#blueStretchyIcon'},
      },
      {
        key: 2,
        geometry: { type: 'Point', coordinates: [54.017876, 37.519656]},
        properties: {
          iconContent: 'Щёкино, Московская',
          hintContent: 'г. Щекино, ул. Московская, д.1Б(на кольце)'
        },
        options: {preset: 'islands#blueStretchyIcon'},
      },
      {
        key: 3,
        geometry: { type: 'Point', coordinates: [54.144129, 37.583293]},
        properties: {
          iconContent: 'Скуратовская',
          hintContent: 'г.Тула, ул. Скуратовская, д.124'
        },
        options: {preset: 'islands#blueStretchyIcon'},
      },
      {
        key: 4,
        geometry: { type: 'Point', coordinates: [54.220579, 37.623654]},
        properties: {
          iconContent: 'Октябрьская',
          hintContent: 'г.Тула, ул. Октябрьская, д.82'
        },
        options: {preset: 'islands#blueStretchyIcon'},
      },
      {
        key: 5,
        geometry: { type: 'Point', coordinates: [54.207722, 37.574786]},
        properties: {
          iconContent: 'Павшинский мост',
          hintContent: 'г.Тула, ул. Павшинский мост, д.3'
        },
        options: {preset: 'islands#blueStretchyIcon'},
      },
      {
        key: 6,
        geometry: { type: 'Point', coordinates: [54.220221, 37.686716]},
        properties: {
          iconContent: 'Щегловская засека',
          hintContent: 'г. Тула, ул. Щегловская засека, д.14  (напротив комбайнового завода)'
        },
        options: {preset: 'islands#blueStretchyIcon'},
      }
    ],
    confirm_open: false,
    confirm_state: '',
    confirm_order: '',
    captcha_protect: false
  }
}


const BasketReducer = function(state=initialState, action) {
  switch(action.type) {
    case types.SET_BASKET_ITEMS:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          items: action.data
        })
      });

    case types.SET_BASKET_PAYMENT:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          selected_payment: action.data
        })
      });

    case types.SET_BASKET_DELIVERY:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          selected_delivery: action.data
        })
      });

    case types.SET_BASKET_MAP_OPEN:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          map_open: action.data
        })
      });

    case types.SET_BASKET_MAP_OBJECTS:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          map_objects: action.data
        })
      });

    case types.SET_BASKET_MAP_SELECT_POINT:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          selected_point: action.data
        })
      });

    case types.SET_BASKET_CONFIRM_OPEN:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          confirm_open: action.data
        })
      });

    case types.SET_BASKET_CONFIRM_STATE:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          confirm_state: action.data
        })
      });

    case types.SET_BASKET_CONFIRM_ORDER:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          confirm_order: action.data
        })
      });

    case types.SET_BASKET_DELIVERY_ADDRESS:
      return Object.assign({}, state, {
        BasketData: Object.assign({}, state.BasketData, {
          delivery_address: action.data
        })
      });

    default: return state;
  }
}


export default BasketReducer;
