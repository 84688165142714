import React, { Component } from 'react';
import { connect } from 'react-redux';

import AttributeListView from './component';

import store from '../../../../store';
import * as ws from '../../../../api/connections';
import {  setSearchAttrListFilter, setSearchAttrStartGroup } from './actions';
import {  setSearchResultRows, setSearchResultTitle,
          setSearchResultFilter, setSearchResultInProgress } from '../searchresult/actions';
import { setAdminAppSettingsAddGroups } from '../../../admin/actions';
import { setAppHideAttrList } from '../../../app/actions/actions';


class AttributeList extends Component {

  componentDidMount() {
  };

  componentWillUnmount() {
    store.dispatch(setAppHideAttrList(false));
  };

  componentWillUnmount = () => {
    store.dispatch(setSearchAttrListFilter(''))
    store.dispatch(setSearchResultTitle(''));
  };

  componentDidUpdate() {
    if (this.props.data.start_group && this.props.add_data.add_groups.length) {
      store.dispatch(setAdminAppSettingsAddGroups(
        this.props.add_data.add_groups.map(item => {
          if (item.id === this.props.data.start_group) {
            item.open = true
          } else {
            item.open = false
          }
          return item;
        })
      ));
      store.dispatch(setSearchAttrStartGroup(0));
    }
  };

  _onItemClick = ({id, name, additional}) => {
    store.dispatch(setSearchResultRows([]));
    store.dispatch(setSearchResultTitle(name));
    store.dispatch(setSearchResultFilter(''));

    if (additional) {
      store.dispatch(setAdminAppSettingsAddGroups(
        this.props.add_data.add_groups.map(item => {
          if (item.id === id) {
            item.open = !item.open
          }
          return item;
        })
      ));
    } else {
      store.dispatch(setAppHideAttrList(true));
      store.dispatch(setSearchResultInProgress(true));
      setTimeout(() => {setSearchResultInProgress(false)}, 30000);
      ws.doSend({
        command: 'getDetailsByAttribute',
        params: {
          attribute: id
        }
      });
    }
  };

  _showList = () => {
    store.dispatch(setAppHideAttrList(false));
  };

  _filter = () => {
    const group = this.props.add_data.add_groups
      .filter(item => item.is_model)
      .map(item => ({
        id: item.id,
        name: item.name,
        additional: true,
        open: item.open,
        add_category: 0,
      }));

    var items = [];
    group.forEach(group => {
      items.push(group);
      if (group.open) {
        items = [...items,
                 ...this.props.data.items.filter(item => item.add_category === group.id)]
      }
    });
    return [
      ...items,
      ...this.props.data.items.filter(item => item.add_category === 0)
    ].filter(item => this.props.data.filter === '' || item.name.toUpperCase().indexOf(this.props.data.filter) !== -1);
  };

  onFilterChange = (e, {value}) => {
    store.dispatch(setSearchAttrListFilter(value.toUpperCase()))
  };

  render () {
    return (
      <AttributeListView
        items={this._filter()}

        onFilterChange={this.onFilterChange}
        onItemClick={this._onItemClick}
        visible={this.props.visible}

        showList={this._showList}
      />
    )
  }
}


const mapStateToProps = store => ({
    data: store.AttributeListState.AttributeListData,
    // items: store.AttributeListState.AttributeListData.items,
    // filter: store.AttributeListState.AttributeListData.filter,
    add_data: store.AdminAppState.AdminAppData
})

export default connect(mapStateToProps)(AttributeList);

