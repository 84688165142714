import React, { Component } from 'react';

import { Button, Card, Checkbox, Container, Divider,
         Form, Header, TextArea } from 'semantic-ui-react'




class ServicesView extends Component {

  state = {
    mailing_list: '',
  }

  render () {
    const {params, onServiceChange, saveMailingList} = this.props;

    return (
      <Container>
        <Card.Group>
          <Card>
            <Card.Content header='E-mail уведомления' />
            <Card.Content>
              <Card.Description>
                <Checkbox toggle color={'teal'} label={<label>{'включить уведомления'}</label>}
                  disabled={params.email_notify === undefined}
                  checked={params.email_notify === 'true'}
                  onChange={onServiceChange('email')}
                />
                <Divider />
                <Form>
                  <Form.Field
                    id='form-textarea-control-opinion'
                    control={TextArea}
                    label={'Список рассылки (e-mail через запятую)'}
                    placeholder='Список рассылки (адреса e-mail через запятую)'
                    disabled={params.mailing_list === undefined}
                    value={this.state.mailing_list || params.mailing_list}
                    onChange={(e, {value}) => {this.setState({mailing_list: value})}}
                  />
                  <Form.Field
                    id='form-button-control-public'
                    control={Button}
                    content='Сохранить'
                    onClick={saveMailingList(this.state.mailing_list)}
                  />
                </Form>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Header as={'h3'}>{'Параметры оплаты'}</Header>
              <Divider />
              <Card.Description>
                <Checkbox toggle color={'teal'} label={<label>{'online-оплата'}</label>}
                  disabled={params.payment_online === undefined}
                  checked={params.payment_online === 'true'}
                  onChange={onServiceChange('payment')}
                />
                <Divider />
                <Checkbox toggle color={'teal'} label={<label>{'оплата при получении'}</label>}
                  disabled={params.payment_offline === undefined}
                  checked={params.payment_offline === 'true'}
                  onChange={onServiceChange('payment_offline')}
                />
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Header as={'h3'}>{'Параметры доставки'}</Header>
              <Divider />
              <Card.Description>
                <Checkbox toggle color={'teal'} label={<label>{'самовывоз из ПВЗ'}</label>}
                  disabled={params.delivery_pickup === undefined}
                  checked={params.delivery_pickup === 'true'}
                  onChange={onServiceChange('delivery_pickup')}
                />
                <Divider />
                <Checkbox toggle color={'teal'} label={<label>{'доставка "до двери"'}</label>}
                  disabled={params.delivery_delivery === undefined}
                  checked={params.delivery_delivery === 'true'}
                  onChange={onServiceChange('delivery_delivery')}
                />
                <Divider />
                <Checkbox toggle color={'teal'} label={<label>{'доставка ТК'}</label>}
                  disabled={params.delivery_company === undefined}
                  checked={params.delivery_company === 'true'}
                  onChange={onServiceChange('delivery_company')}
                />
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Header as={'h3'}>{'Доп. настройки'}</Header>
              <Divider />
              <Card.Description>
                <Checkbox toggle color={'teal'} label={<label>{'jivo-chat'}</label>}
                  disabled={params.chat_enable === undefined}
                  checked={params.chat_enable === 'true'}
                  onChange={onServiceChange('chat_enable')}
                />
              </Card.Description>
            </Card.Content>
          </Card>

        </Card.Group>
      </Container>
    )
  }
}


export default ServicesView;
