import React, { Component } from 'react';

import { Button, Modal, Tab } from 'semantic-ui-react';


class RulesPaymentView extends Component {

  render () {
    const { panes } = this.props;

    return (
      <Modal size={'large'} open={this.props.open} onClose={this.props.onClose} dimmer={'inverted'} closeIcon>
        <Modal.Header>{'Информация для покупателя'}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes}
                 defaultActiveIndex={this.props.started}  />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose}>
            {'Закрыть'}</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}


export default RulesPaymentView;
