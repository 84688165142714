import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import MainLayout from './layouts/main';
import PaymentsLayout from './layouts/payments';
import AdminLayout from './layouts/admin';
import OzonLayout from './layouts/ozon';


export default (
  <HashRouter>
    <Switch>
      <Route path='/admin' component={AdminLayout} />
      <Route path='/ozon' component={OzonLayout} />
      <Route path='/oferta' component={() => <PaymentsLayout started={0} />} />
      <Route path='/payments' component={() => <PaymentsLayout started={1} />} />
      <Route path='/delivery' component={() => <PaymentsLayout started={2} />} />
      <Route path='/safety' component={() => <PaymentsLayout started={3} />} />
      <Route path='/direct' component={MainLayout} />
      <Route path='/' component={MainLayout} />
    </Switch>
  </HashRouter>
)
