import React, { Component } from 'react';

import {
  Container, Grid, Image, List, Responsive, Segment
} from 'semantic-ui-react'

import OrderSteps from '../ordersteps/container';


class HeaderView extends Component {
  render () {
    return (
      <div>
        <Responsive as={Segment} minWidth={1200} inverted vertical>
          <Container>
            <Grid centered verticalAlign={'middle'} >
              <Grid.Row columns={2}>
                <Grid.Column width={2}>
                  <Image style={{cursor: 'pointer'}} centered verticalAlign={'middle'} src='/images/logo_.png' onClick={this.props.onButtonClick} />
                </Grid.Column>
                <Grid.Column width={14}>
                  <OrderSteps />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{marginTop: '-1.5em'}} columns={4}>
                <Grid.Column>
                  <List>
                    <List.Item>
                      <List.Icon name='phone' />
                      <List.Content><div>{'Телефон: '}<a href={'+7 (910) 164-46-56'}>{'+7 (910) 164-46-56'}</a></div></List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List>
                    <List.Item>
                    <List.Icon name='envelope' />
                    <List.Content><div>{'E-mail: '}<a href={'mailto:texkomplus71@yandex.ru'}>{'texkomplus71@yandex.ru'}</a></div></List.Content>
                  </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Responsive>

        <Responsive as={Segment} maxWidth={1200}>
          <OrderSteps mobile />
        </Responsive>
      </div>
    )
  }
}


export default HeaderView;
