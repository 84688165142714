import * as types from '../../../../actions/action-types'


export function setSearchGroupListItems(data) {
  return {
    type: types.SET_SEARCH_GROUP_LIST_ITEMS,
    data
  }
}


export function setSearchGroupListFilter(data) {
  return {
    type: types.SET_SEARCH_GROUP_LIST_FILTER,
    data
  }
}
