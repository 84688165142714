import React, { Component } from 'react';

import { Button, Card, Grid, Icon, Input, Image, Responsive } from 'semantic-ui-react'

import TehkomSearch from '../catalogue/tehkomsearch/container';


class GlobalMenuView extends Component {

  state = {
    article: ''
  }

  _itemImage = title => {
    switch (title) {
      case 'ВАЗ': return ['https://storage.yandexcloud.net/acat/main/marks/40.png'];
      case 'ГАЗ': return ['https://storage.yandexcloud.net/acat/main/marks/37.png'];
      case 'УАЗ': return ['https://storage.yandexcloud.net/acat/main/marks/36.png'];
      case 'Иномарки': return ['https://storage.yandexcloud.net/acat/main/marks/3.png',
                               'https://storage.yandexcloud.net/acat/main/marks/60.png',
                               'https://storage.yandexcloud.net/acat/main/marks/22.png' ]

      default: return [];
    }
  };


  _menu_card = (item, i) => {

    return (
      <Card key={i} color={'teal'} onClick={this.props.onCardClick(item.title)}>

        <Card.Content>
          { this._itemImage(item.title).map((it, i) => <Image key={i} src={it} floated={'right'} />) }
          <Card.Header>{item.title}</Card.Header>
        </Card.Content>

        {item.buttons
          &&  <Card.Content extra>
                <Button.Group>
                  <Button color={'teal'} onClick={this.props.onLeftClick(item.title)} >{'Модель'}</Button>
                  <Button.Or text={''} color={'teal'} />

                  {item.title === 'Иномарки'
                    &&  <Button color={'teal'} onClick={this.props.onMiddleClick(item.title)} >{'ТО'}</Button> }
                  {item.title === 'Иномарки'
                    &&  <Button.Or text={''} /> }

                  <Button color={'teal'} onClick={this.props.onRightClick(item.title)}>{'Каталог'}</Button>
                </Button.Group>
              </Card.Content>}

      </Card>
    );
  };

  _onChangeArticle = (e, {value}) => {
    this.setState({article: value})
  };

  _onInputKeyDown = event => {
    if ((event.key === 'Enter') && this.state.article.length) {
      this.props.onSearchClick(this.state.article)();
    }
  };

  render () {
    return (
      <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'space-around',
              textAlign: 'center',
              minHeight: '40vh',
              marginTop: '2em'
            }}>

        <Responsive minWidth={1002}>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={10}>
                <Input fluid action type={'text'} placeholder='Поиск по номеру детали'
                       value={this.state.article}
                       onChange={this._onChangeArticle}
                       onKeyDown={this._onInputKeyDown} >
                  <input />
                  <Button icon labelPosition='right' onClick={this.props.onSearchClick(this.state.article)} >
                    {'Поиск'}
                    <Icon name='search' />
                  </Button>
                </Input>
              </Grid.Column>

              <Grid.Column>
              </Grid.Column>

              <Grid.Column>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3} >
              <Grid.Column stretched>
                {this.props.items.slice(0, 2).map(this._menu_card)}
              </Grid.Column>

              <Grid.Column stretched>
                {this.props.items.slice(2, 4).map(this._menu_card)}
              </Grid.Column>

              <Grid.Column stretched>
                {this.props.items.slice(4, 7).map(this._menu_card)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>

        <Responsive maxWidth={1002}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Input fluid action type={'text'} placeholder='Поиск по номеру детали'
                       value={this.state.article}
                       onChange={this._onChangeArticle}
                       onKeyDown={this._onInputKeyDown} >
                  <input />
                  <Button icon labelPosition='right' onClick={this.props.onSearchClick(this.state.article)} >
                    {'Поиск'}
                    <Icon name='search' />
                  </Button>
                </Input>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} >
              <Grid.Column stretched>
                {this.props.items.slice(0, 1).map(this._menu_card)}
              </Grid.Column>

              <Grid.Column stretched>
                {this.props.items.slice(1, 2).map(this._menu_card)}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} >
              <Grid.Column stretched>
                {this.props.items.slice(2, 3).map(this._menu_card)}
              </Grid.Column>

              <Grid.Column stretched>
                {this.props.items.slice(3, 4).map(this._menu_card)}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3} >
              <Grid.Column stretched>
                {this.props.items.slice(4, 5).map(this._menu_card)}
              </Grid.Column>

              <Grid.Column stretched>
                {this.props.items.slice(5, 6).map(this._menu_card)}
              </Grid.Column>

              <Grid.Column stretched>
                {this.props.items.slice(6, 7).map(this._menu_card)}
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Responsive>

        <TehkomSearch />
      </div>
    )
  }
}


export default GlobalMenuView;
