import React, { Component } from 'react';

import { Button, Divider, Checkbox, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { Accordion, Form, Label, List, Radio } from 'semantic-ui-react';


class OrderRegistration extends Component {
  render () {

    const { activeDelivery, deliveryValue, paymentValue } = this.props;
    const { paymentChange, deliveryChange, deliveryClick,
            currentDelivery } = this.props;
    const { delivery_items, payment_items } = this.props;
    const { for_payment } = this.props;
    const { confirm_rules, changeConfirmRules } = this.props;
    const { name, phone, email, delivery_address,
            post_index } = this.props;

    const { openMap } = this.props;

    const { valid_recipient, valid_address } = this.props;
    const { releaseOrder } = this.props;

    const address_title = deliveryValue === 'pickup' ? 'Адрес ПВЗ магазина' : 'Адрес доставки';

    return (
      <Segment>
        <Header as={'h3'} content={'Детали заказа'} />
        <Divider />

        <Grid>

          {/* Способ оплаты */}
          <Grid.Row columns={1} >
            <Grid.Column textAlign={'left'} >
              <Header as={'h4'} content={`Способ оплаты`} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{marginTop: '-2em'}} columns={1} >
            <Grid.Column textAlign={'left'} >
              <Form>
                {payment_items.map((item, i) => (
                  <Form.Field key={i}>
                    <Radio
                      label={item.header}
                      name={'payment'}
                      value={item.key}
                      checked={paymentValue === item.key}
                      onChange={paymentChange}
                      disabled={item.disabled}
                    />
                  </Form.Field>
                ))}
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          {/* Способ доставки */}
          <Grid.Row columns={1} >
            <Grid.Column textAlign={'left'} >
              <Header as={'h4'} content={`Способ доставки`} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{marginTop: '-2em'}} columns={1} >
            <Grid.Column textAlign={'left'} >
              <Accordion>
                <Accordion.Title
                    active={activeDelivery === 0}
                    index={0}
                    onClick={deliveryClick} >
                  {currentDelivery()}
                  <Label as='a' style={{marginLeft: '1em'}} basic color={'blue'} >
                    <Icon name='dropdown' />
                    {activeDelivery === 0 ? 'свернуть' : 'изменить'}
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeDelivery === 0}>
                  <Form>
                    {delivery_items.map((item, i) => (
                      <Form.Field key={i}>
                        <Radio
                          label={item.header}
                          name={'delivery'}
                          value={item.key}
                          checked={deliveryValue === item.key}
                          onChange={deliveryChange}
                          disabled={item.disabled}
                        />
                      </Form.Field>
                    ))}
                  </Form>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>

          </Grid.Row>

          <Divider />

          {/* Адрес доставки */}
          <Grid.Row columns={1} >
            <Grid.Column textAlign={'left'} >
              <Header as={'h4'} content={`Получатель и адрес доставки`} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1} >
            <Grid.Column textAlign={'left'} >

              <List>
                <List.Item>
                  <List.Icon  style={{cursor: 'pointer'}} name={'edit'} size={'large'} color={'blue'}
                    onClick={openMap}
                  />
                  <List.Content>
                    <List.Header>
                      <span style={{color: valid_recipient ? 'black' : 'red'}}>
                        {valid_recipient ? 'Получатель' : 'Получатель (необходимо указать данные)'}
                      </span>
                    </List.Header>
                    <List.Description>
                      {valid_recipient ? [name, phone, email].join(', ') : ''}
                    </List.Description>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon  style={{cursor: 'pointer'}} name={'edit'} size={'large'} color={'blue'}
                    onClick={openMap}
                  />
                  <List.Content>
                    <List.Header>
                      <span style={{color: valid_address ? 'black' : 'red'}}>
                        {valid_address ? address_title : `${address_title} (необходимо указать данные)`}
                      </span>
                    </List.Header>
                    <List.Description>
                      {valid_address ? `${post_index} ${delivery_address}` : ''}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>

            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row style={{marginBottom: '1em'}} columns={2} >
            <Grid.Column textAlign={'left'} >
              <Header as={'h5'} content={'К оплате'} />
            </Grid.Column>

            <Grid.Column textAlign={'right'} >
              <Header as={'h3'} content={`${parseFloat(for_payment).toFixed(2)} руб.`} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Grid.Row style={{marginBottom: '1em'}} columns={1} >
          <Grid.Column textAlign={'center'} >
            <Checkbox label={'я прочитал Правила и согласен с ними'} checked={confirm_rules} onChange={changeConfirmRules} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2} >
          <Grid.Column textAlign={'left'} >
            <Button primary disabled={!(valid_recipient && valid_address && confirm_rules)}
              content={'Оформить заказ'}
              onClick={releaseOrder} />
          </Grid.Column>

        </Grid.Row>

      </Segment>
    );
  }
}


export default OrderRegistration;
