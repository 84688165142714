import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TehkomSearchView from './component';

import store from '../../../store';
import * as ws from '../../../api/connections';
import {  setTehkomSearchIsOpen,
          setTehkomSearchState,
          setTehkomSearchArticle, setTehkomSearchCrosses,
          setTehkomSearchResult,
          setTehkomSearchSimilar,
          setTehkomSearchNeedCloseWindow, } from './actions';
import { setOrderStepsActiveStep } from '../../ordersteps/actions/actions';


class TehkomSearch extends Component {

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if ((this.props.article) && (this.props.article !== prevProps.article)) {
      // console.log('новый артикул')
      store.dispatch(setTehkomSearchIsOpen(true));
      store.dispatch(setTehkomSearchState('search'));

      // console.log(this.props.crosses);
      ws.doSend({
        command: 'searchInPriceByArticle',
        params: {
          article: this.props.article,
          brand: this.props.brand,
          crosses: this.props.crosses,
        }
      });

      this.timer = setTimeout(() => {
        if (this.props.state === 'search') {
          store.dispatch(setTehkomSearchState('error'));
        }
      }, 30000)
    }
  }

  onClickClose = () => {
    store.dispatch(setTehkomSearchIsOpen(false));
    store.dispatch(setTehkomSearchState(''));
    store.dispatch(setTehkomSearchArticle(''));
    store.dispatch(setTehkomSearchCrosses(false));
    store.dispatch(setTehkomSearchResult([]));
    store.dispatch(setTehkomSearchSimilar([]));

    if (this.props.need_close_window) {
      store.dispatch(setTehkomSearchNeedCloseWindow(false));
      console.log('Close window');
      window.open('','_parent','').close();
    }
  }

  goToBasket = () => {
    store.dispatch(setTehkomSearchIsOpen(false));
    store.dispatch(setTehkomSearchNeedCloseWindow(false));
    store.dispatch(setTehkomSearchState(''));
    store.dispatch(setTehkomSearchArticle(''));
    store.dispatch(setTehkomSearchCrosses(false));
    store.dispatch(setTehkomSearchResult([]));
    store.dispatch(setTehkomSearchSimilar([]));

    store.dispatch(setOrderStepsActiveStep('basket'))
    this.props.history.push('/');
  };

  render () {

    const { article, is_open, state, result, similar } = this.props;

    // console.log(Object.keys(this.props))
    // console.log(this.props)
    return (
      <TehkomSearchView
        article={article}
        is_open={is_open}
        state={state}
        result={result}
        similar={similar}
        crosses={this.props.crosses}
        adv_crosses={this.props.adv_crosses}

        onClickClose={this.onClickClose}
        goToBasket={this.goToBasket}  />
    )
  }
}

const mapStateToProps = store => ({
    data: store.TehkomSearchState.TehkomSearchData,
    article: store.TehkomSearchState.TehkomSearchData.article,
    brand: store.TehkomSearchState.TehkomSearchData.brand,
    crosses: store.TehkomSearchState.TehkomSearchData.crosses,
    is_open: store.TehkomSearchState.TehkomSearchData.is_open,
    need_close_window: store.TehkomSearchState.TehkomSearchData.need_close_window,
    state: store.TehkomSearchState.TehkomSearchData.state,
    result: store.TehkomSearchState.TehkomSearchData.result,
    similar: store.TehkomSearchState.TehkomSearchData.similar,
    adv_crosses: store.TehkomSearchState.TehkomSearchData.adv_crosses,
})

export default connect(mapStateToProps)(withRouter(TehkomSearch));

