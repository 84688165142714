import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderView from './component';

import store from '../../store';
import { setAppGmenuPopupOpen } from '../app/actions/actions';
import { setOrderStepsActiveStep } from '../ordersteps/actions/actions';


class AppHeader extends Component {

  _onButtonClick = () => {
    store.dispatch(setOrderStepsActiveStep('menu'));
    // store.dispatch(setAppGlobalMenu(true));
  };

  _onPopupOpen = () => {
    setTimeout(() => {
        store.dispatch(setAppGmenuPopupOpen(false));
    }, 10000)
  };

  render () {
    return (
      <HeaderView onButtonClick={this._onButtonClick}
        globalMenuPopupOpen={this.props.data.global_menu_popup_open}
        onPopupOpen={this._onPopupOpen} />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
  }
}

export default connect(mapStateToProps)(AppHeader);
