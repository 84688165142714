import * as types from '../../../actions/action-types'


const initialState = {
  TehkomSearchData: {
    panes: [
      {menuItem: 'Архив КХ', render: () => {}},
      {menuItem: 'Ввод КХ', render: () => {}},
    ],
    is_open: false,
    need_close_window: false,
    article: '',
    brand: '',
    crosses: false,
    state: '',
    result: [],
    similar: [],
    adv_crosses: false,
  }
}


const TehkomSearchReducer = function(state=initialState, action) {
  switch(action.type) {

    case types.SET_TEHKOM_SEARCH_IS_OPEN:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          is_open: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_ARTICLE:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          article: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_BRAND:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          brand: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_CROSSES:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          crosses: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_STATE:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          state: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_RESULT:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          result: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_SIMILAR:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          similar: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_ADV_CROSSES:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          adv_crosses: action.data
        })
      });

    case types.SET_TEHKOM_SEARCH_NEED_CLOSE_WINDOW:
      return Object.assign({}, state, {
        TehkomSearchData: Object.assign({}, state.TehkomSearchData, {
          need_close_window: action.data
        })
      });

    default: return state;
  }
}


export default TehkomSearchReducer;
