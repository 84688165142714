import React from 'react';

import { Grid, Image } from 'semantic-ui-react';

import * as types from '../../actions/action-types'


const initialState = {
  RulesPaymentData: {
    open: false,
    oferta: (
      <div>
        <span>
          1.1. Настоящий Договор-оферта регулирует взаимоотношения Продавца и Покупателя.
        </span><br />
        <span>1.2. Продавец сохраняет за собой право изменять настоящий Договор.</span><br />
        <span>1.3. Адрес действующей редакции настоящего Договора в сети интернет: shop.tehkom-avto.ru</span><br />
        <span style={{color: 'blue'}}>1.4. <b>Цена покупки в интернет магазине является окончательной. Скидки по дисконтным картам на интернет покупки не осуществляются.</b></span><br />
        <span>{''}</span><br />
        <span>ПРЕДМЕТ ДОГОВОРА</span><br />
        <span>2.1. Продавец обязуется передать в собственность Покупателю товар, а Покупатель обязуется принять и оплатить товар на условиях настоящего Договора.</span><br />
        <span>{''}</span><br />
        <span>СТАТУС ИНТЕРНЕТ-МАГАЗИНА </span><br />
        <span>3.1. Продавцом товаров, реализуемых через интернет-магазин shop.tehkom-avto.ru, является  ООО «Автозапчасти». Товары через интернет-магазин реализуются дистанционным способом, и путем самовывоза из точек выдачи указанных в интернет-магазине.</span><br />
        <span>3.2. Продавец не требует от Покупателя специальных действий для использования интернет-магазина, таких как регистрация или заключение договора на пользование интернет-магазином.</span><br />
        <span>3.3. Настоящий Договор является официальной офертой. Акцентом настоящей оферты является оформление и подтверждение заказа Покупателем товара. Договор купли-продажи считается заключенным в момент оплаты за товар.</span><br />
        <span>3.4. Информация, размещенная на сайте shop.tehkom-avto.ru, является общедоступной, если иное не установлено настоящим Договором.</span><br />
        <span>3.5. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.</span><br />
        <span>{''}</span><br />
        <span>СТАТУС ПОКУПАТЕЛЯ</span><br />
        <span>4.1. Покупателем является лицо, самостоятельно оформившее заказ в интернет-магазине shop.tehkom-avto.ru на условиях настоящего Договора.</span><br />
        <span>4.2. Покупатель несет ответственность за достоверность предоставленной при оформлении заказа информации.</span><br />
        <span>4.3. Оформление Покупателем заказа в интернет-магазинe shop.tehkom-avto.ru означает согласие Покупателя с условиями настоящего Договора.</span><br />
        <span>4.4. Оформление Покупателем заказа осуществляется посредством нажатия кнопки «Оформить заказ».</span><br />
        <span>{''}</span><br />
        <span>ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА</span><br />
        <span>5.1. При оформлении заказа в интернет-магазине Покупатель обязан предоставить о себе информацию: фамилию, имя, номер телефона, адрес электронной почты, реквизиты для доставки.</span><br />
        <span>5.2. Продавец не редактирует информацию о Покупателе.</span><br />
        <span>5.3. После завершения процесса оформления заказа ему автоматически присваивается идентификационный номер («№ заказа»). После оформления заказа менеджер Продавца связывается с Покупателем для уточнения условий заказа и доставки.</span><br />
        <span>5.4. Способ оплаты и доставки Покупатель выбирает при оформлении заказа самостоятельно из предложенных Продавцом. Срок доставки согласовывается сторонами при звонке менеджера Продавца Покупателю. Покупатель обязан оплатить товар в соответствии с выбранным способом.</span><br />
        <span>5.5. Покупатель обязуется проверить комплектность и внешний вид товара в момент его получения на почте, в транспортной компании, или курьерской службе. Любые претензии по некомплекту или несоответствию состава заказа принимаются исключительно в случае сообщения об этом в момент получения заказа в ТК или у курьера. При обнаружении повреждений возникших в процессе доставки, покупатель заполняет соответствующий акт о повреждении посылки на бланке компании осуществляющей доставку, или в свободной форме. Акт должен быть подписан представителем компании осуществляющей доставку, и отправлен на email интернет-магазина shop.tehkom-avto.ru. В иных случаях претензии о повреждении груза в процессе доставки не принимаются. Решение о замене, возврате, или возмещении стоимости поврежденного товара принимается администрацией интернет-магазина в течение 7 рабочих дней.</span><br />
        <span>{''}</span><br />
        <span>ОБМЕН И ВОЗВРАТ ТОВАРА.</span><br />
        <span>6.1.Возврат товара</span><br />
        <span>6.1.1. Покупатель вправе отказаться от товара надлежащего качества в течение 15 дней с даты покупки, указанной на кассовом чеке, в том случае, если товар не был в употреблении, сохранены упаковка, товарный вид, потребительские свойства, пломбы, фабричные ярлыки.</span><br />
        <span>6.1.2. Для возврата товара надлежащего качества Покупатель направляет заявление с копиями документов, подтверждающих факт покупки, по электронной почте tehkom-avto@yandex.ru. Продавец принимает заявление к рассмотрению в течение 5 рабочих дней согласовывает с Покупателем условия возврата товара. Продавец вправе отказать в возврате товара, если выяснится, что товар был в эксплуатации, и/или имеет механические повреждения (трещины, царапины, сколы).</span><br />
        <span>6.1.3. При возврате товара надлежащего качества Покупатель обязан компенсировать продавцу все расходы, связанные с доставкой и возвратом товара.</span><br />
        <span>6.1.4. Продавец возвращает уплаченные за товар денежные средства в течение 10 рабочих дней с момента получения возвращенного товара.</span><br />
        <br /><span>РЕКВИЗИТЫ ПРОДАВЦА</span><br /><br />
        <table>
          <tbody>
          <tr>
            <td><span>Полное и сокращенное наименование фирмы в соответствии с учредительными документами - Уставом ООО «Автозапчасти»</span></td>
            <td><span>Общество с ограниченной ответственностью  «Автозапчасти» (ООО «Автозапчасти»</span></td>
          </tr>
          <tr>
            <td><span>Идентификационный номер (ИНН)</span></td>
            <td><span>7130010203</span></td>
          </tr>
          <tr>
            <td><span></span>Код причины постановки на учет (КПП)</td>
            <td><span></span>710501001</td>
          </tr>
          <tr>
            <td><span>Код предприятия по ОКПО</span></td>
            <td><span>34421457</span></td>
          </tr>
          <tr>
            <td><span>Код по ОКВЭД</span></td>
            <td><span>45.31</span></td>
          </tr>
          <tr>
            <td><span>ОГРН</span></td>
            <td><span>1027100687613</span></td>
          </tr>
          <tr>
            <td><span>Учетная карточка участника внешнеэкономической деятельности (ВЭД)</span></td>
            <td><span>-</span></td>
          </tr>
          <tr>
            <td><span>Юридический адрес</span></td>
            <td><span>300001, г. Тула, ул. Карла Маркса, д.54-а</span></td>
          </tr>
          <tr>
            <td><span>Почтовый адрес</span></td>
            <td><span>300001, г. Тула, ул. Карла Маркса, д.54-а</span></td>
          </tr>
          <tr>
            <td><span>Адрес электронной почты (E-mail)</span></td>
            <td><span>texkomplus83@mail.ru</span></td>
          </tr>
          <tr>
            <td><span>Телефон/факс</span></td>
            <td><span>(4872) 40-54-50 <br /> факс (4872) 40-50-70</span></td>
          </tr>
          <tr>
            <td><span>Номер расчетного счета</span></td>
            <td><span>40702810466060101909 ТУЛЬСКОЕ ОТДЕЛЕНИЕ N8604 ПАО СБЕРБАНК</span></td>
          </tr>
          <tr>
            <td><span>Номер корреспондентского счета</span></td>
            <td><span>30101810300000000608</span></td>
          </tr>
          <tr>
            <td><span>БИК</span></td>
            <td><span>047003608</span></td>
          </tr>
          <tr>
            <td><span>Код по ОКАТО</span></td>
            <td><span>70401000000</span></td>
          </tr>
          <tr>
            <td><span>Код по ОКТМО</span></td>
            <td><span>70701000</span></td>
          </tr>
          <tr>
            <td><span>Руководитель - генеральный директор</span></td>
            <td><span>Королева Елена Ивановна, действующая на основании Устава</span></td>
          </tr>
          <tr>
            <td><span>Главный бухгалтер</span></td>
            <td><span>Волкова Елена Викторовна</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    ),
    payments: (
      <div>
        <h3><span>Наличный расчет:</span></h3>
        <p><span>Оплатить наличными можно при получении товара в точке выдачи или
        курьеру при доставке товара до адреса покупателя.</span><br />
        <span>При получении товара необходимо проверить внешний вид, комплектацию товара, наличие чека, гарантийного талона на технически сложные товары.</span></p>

        <h3><span>Банковской картой:</span></h3>
        <p><span>Для выбора оплаты товара с помощью банковской карты на соответствующей странице (в корзине) при формировании заказа необходимо выбрать пункт «полная предоплата онлайн»,
 оплатить можно будет на странице «заказ проверка состояния» после получения уведомления что заказ проверен и собран, перейдя по ссылке «оплатить заказ».</span><br />
        <span>Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платежных систем:</span><br />
        <Grid centered>
          <Grid.Row centered columns={4}>

            <Grid.Column textAlign={'center'} >
              <Grid.Row>
              </Grid.Row>
                <Image src='images/visa.jpg' />
              <Grid.Row>
                {'VISA'}
              </Grid.Row>
            </Grid.Column>

            <Grid.Column textAlign={'center'} >
              <Grid.Row>
              </Grid.Row>
                <Image src='images/mastercard.png' />
              <Grid.Row>
                {'Mastercard'}
              </Grid.Row>
            </Grid.Column>

            <Grid.Column textAlign={'center'} >
              <Grid.Row>
              </Grid.Row>
                <Image src='images/mir.jpg' />
              <Grid.Row>
                {'МИР'}
              </Grid.Row>
            </Grid.Column>

            <Grid.Column textAlign={'center'} >
              <Grid.Row>
              </Grid.Row>
                <Image src='images/jcb.png' />
              <Grid.Row>
                {'JCB'}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </p>

        <h3><span>Возврат товара:</span></h3>
        <p><span>Срок возврата товара надлежащего качества составляет 14 дней с момента получения товара. Транспортные расходы за доставку не понадобившегося товара продавцу оплачиваются за счет покупателя.</span><br />
        <span>Возврат переведенных средств, производится на ваш банковский счет в течении 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).</span></p>
      </div>
    ),
    delivery: (
      <div>
        <h3>1. Бесплатная курьерская доставка</h3>
          <span>Бесплатная курьерская доставка в пределах г Тула осуществляется собственной службой доставки при сумме заказа от 1000 руб.</span><br />
          <span>Доставка осуществляется по адресу клиента без подъема на этаж.</span><br />
          <span>Доставка происходит в течение 1-2 рабочих дней с момента поступления заказа.</span><br />
          <span>Время доставки с 10:00 до 17:00 по рабочим дням. Служба доставки свяжется с вами по телефону для согласования даты и времени получения заказа. Мы постараемся организовать доставку в удобное для вас время.</span><br />
          <span>Для получения оплаченного заказа необходимо предъявить документ, удостоверяющий личность получателя. </span><br />
          <span>За дополнительной информацией вы можете обратиться по телефону +7 (910) 164-46-56.</span><br />
        <h3>2. Самовывоз из точек выдачи в Туле и Щекино</h3>
          <span>Для получения оплаченного заказа необходимо предъявить документ, удостоверяющий личность получателя и указать номер заказа. </span><br />
          <span>За дополнительной информацией по доставке вы можете обратиться по телефону +7 (910) 164-46-56.</span><br />
        <h3>3. Получение в пунктах выдачи заказов "ТК СДЭК" доступно в 270 городах</h3>
          <span>Время и дни работы пунктов выдачи указаны в контактах ТК СДЭК.</span><br />
          <span>Для получения оплаченного заказа необходимо предъявить документ, удостоверяющий личность получателя.</span><br />
          <span>За дополнительной информацией по доставке вы можете обратиться к менеджеру по телефону +7 (910) 164-46-56.</span><br />
        <h3>4. Доставка заказа транспортными компаниями: «Энергия», «Байкал Сервис», «КИТ» и др.</h3>
          <span>В этом случае мы свяжемся с вами для подтверждения стоимости доставки.</span><br />
          <span>Оплата доставки производиться в транспортной компании при получении заказа.</span><br />
          <span>Мы бесплатно доставляем заказ до терминала транспортной компании.</span><br />
        <h3>5. Экспресс-доставка EMS почтой России</h3>
          <span>Отправка заказа производится «EMS Russian Post». Курьер «EMS Russian Post» самостоятельно заберет Ваше отправление со склада нашей компании. Стоимость доставки рассчитывается по тарифам компании «EMS Russian Post» и доступна на сайте http://www.emspost.ru/. Существуют ограничения по товарам отправляемым «EMS Russian Post», ознакомиться с ними вы сможете сайте http://www.emspost.ru/. Для отслеживания вашего отправления используйте сайт http://www.emspost.ru/, и номер вашего отправления из оповещений.</span><br />
          <span>При выборе способа доставки EMS почтой России, сообщите об этом нашему менеджеру, когда он свяжется с Вами для подтверждения доставки.</span><br />
        <h3>6. Отправка заказа Почтой России.</h3>
          <span>Оплата доставки происходит до отправки заказа, по тарифам Почты России. Для отслеживания вашего отправления используйте сайт http://www.pochta.ru/, и номер вашего отправления из оповещений.</span><br />
          <span>При выборе способа доставки Почтой России, сообщите об этом нашему менеджеру, когда он свяжется с Вами для подтверждения доставки.</span><br />
      </div>
    ),
    safety: (
      <div>
        <h2>Конфиденциальность и безопасность</h2>
          <h3>Политика обеспечения защиты данных</h3>
            <span>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</span><br />
            <span>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</span><br />
          <h3>Политика обеспечения конфиденциальности информации</h3>
            <span>Мы заинтересованы в обеспечении конфиденциальности информации, полученной от Вас.</span><br />
            <span>Мы с большой ответственностью и осторожностью обращаемся с Вашей личной информацией, которую Вы доверили нам, и предпринимаем необходимые шаги, чтобы гарантировать ее использование только для законных целей.</span><br />
            <span>Мы будем использовать информацию, которую мы получаем от Вас с Вашего согласия, только для того, чтобы обработать Ваш заказ или сообщить Вам о наших услугах. </span><br />
            <span>Мы никогда не будем преднамеренно раскрывать личную информацию о Вас любому третьему лицу, кроме случаев, когда мы обязаны это сделать по закону.</span><br />
      </div>
    ),
  }
}


const RulesPaymentReducer = function(state=initialState, action) {
  switch(action.type) {

    case types.SET_RULES_PAYMENT_OPEN:
      return Object.assign({}, state, {
        RulesPaymentData: Object.assign({}, state.RulesPaymentData, {
          open: action.data
        })
      });


    case types.SET_RULES_PAYMENT_TEXT:
      return Object.assign({}, state, {
        RulesPaymentData: Object.assign({}, state.RulesPaymentData, {
          text: action.data
        })
      });


    default: return state;
  }
}


export default RulesPaymentReducer;
