import * as types from '../../actions/action-types'


export function setAdminAppOrders(data) {
  return {
    type: types.SET_ADMIN_APP_ORDERS,
    data
  }
}


export function setAdminAppOrderStates(data) {
  return {
    type: types.SET_ADMIN_APP_ORDER_STATES,
    data
  }
}


export function setAdminAppSelectedOrder(data) {
  return {
    type: types.SET_ADMIN_APP_SELECTED_ORDER,
    data
  }
}


export function setAdminAppSettingsCategories(data) {
  return {
    type: types.SET_ADMIN_APP_SETTINGS_CATEGORIES,
    data
  }
}


export function setAdminAppSettingsGroups(data) {
  return {
    type: types.SET_ADMIN_APP_SETTINGS_GROUPS,
    data
  }
}



export function setAdminUploadFile(data) {
  return {
    type: types.SET_ADMIN_UPLOAD_FILE,
    data
  }
}


export function setAdminUploadOpenModal(data) {
  return {
    type: types.SET_ADMIN_UPLOAD_OPEN_MODAL,
    data
  }
}


export function setAdminUploadState(data) {
  return {
    type: types.SET_ADMIN_UPLOAD_STATE,
    data
  }
}


export function setAdminPriceType(data) {
  return {
    type: types.SET_ADMIN_PRICE_TYPE,
    data
  }
}


export function setAdminDeliveryPriceType(data) {
  return {
    type: types.SET_ADMIN_DELIVERY_PRICE_TYPE,
    data
  }
}


export function setAdminAppSettingsAddGroups(data) {
  return {
    type: types.SET_ADMIN_APP_SETTINGS_ADD_GROUPS,
    data
  }
}


export function setAdminAuth(data) {
  return {
    type: types.SET_ADMIN_AUTH,
    data
  }
}


export function setAdminNeedAuth(data) {
  return {
    type: types.SET_ADMIN_NEED_AUTH,
    data
  }
}


export function setAdminParams(data) {
  return {
    type: types.SET_ADMIN_PARAMS,
    data
  }
}


export function setAdminCrosses(data) {
  return {
    type: types.SET_ADMIN_CROSSES,
    data
  }
}


export function setAdminOrderXlsxLink(data) {
  return {
    type: types.SET_ADMIN_ORDER_XLSX_LINK,
    data
  }
}


export function setAdminReportXlsxLink(data) {
  return {
    type: types.SET_ADMIN_REPORT_XLSX_LINK,
    data
  }
}
