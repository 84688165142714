import React, { Component } from 'react';

import Recaptcha from 'react-recaptcha';

import { Container, Form, Grid, Header, Icon } from 'semantic-ui-react'

import OrderTable from './ordertable/container';

let recaptchaInstance;


class CheckOrderView extends Component {

  render () {
    const { captcha_protect,
            disable_del, disable_print } = this.props;

    return (
      <Container>
        <Header as='h2' color={'grey'} style={{marginTop: '1em'}}>
                <Icon name='cart' />
                <Header.Content>
                  {'Проверка состояния заказа'}
                </Header.Content>
              </Header>
        <Grid>

          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Input fluid label='Номер заказа' placeholder='Номер заказа для поиска'
                    width={5}
                    value={this.props.order}
                    onChange={this.props.onOrderChange('order')}
                  />
                  <Form.Input fluid label='E-mail или номер телефона' placeholder='E-mail указанный в заказе'
                    width={3}
                    value={this.props.email}
                    onChange={this.props.onOrderChange('email')}
                  />
                  <Form.Button fluid label='&nbsp;' color={'teal'}
                    width={3}
                    labelPosition={'right'} icon={'cart'}
                    content={'Проверить заказ'} disabled={!(this.props.order && this.props.email)}
                    onClick={() => {recaptchaInstance.reset(); this.props.onActionClick();}}
                  />
                  <Form.Button style={{display: disable_del ? 'none' : 'block'}}
                    fluid label='&nbsp;' color={'teal'}
                    width={3}
                    labelPosition={'right'} icon={'trash'}
                    content={'Отменить заказ'}
                    onClick={this.props.disableOrder}
                  />
                  <Form.Button style={{display: disable_print ? 'none' : 'block'}}
                    fluid label='&nbsp;' color={'teal'}
                    width={2}
                    labelPosition={'right'} icon={'print'}
                    content={'Печать'}
                    onClick={this.props.printOrder}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column width={3} style={{display: captcha_protect ? 'block' : 'None'}}>
                <Recaptcha
                  hl='ru'
                  ref={e => recaptchaInstance = e}
                  render='explicit'
                  sitekey="6Lde-3YUAAAAAJTN2vAXBN7_fNd9jkNPMpcAdYEZ"
                  onloadCallback={() => {}}
                  verifyCallback={this.props.verifyCallback}
                />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <OrderTable state_options={this.props.state_options} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Container>
    )
  }
}


export default CheckOrderView;
