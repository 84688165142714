import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tab } from 'semantic-ui-react'
import AdminAppView from './component';
import * as ws from '../../api/connections';
import store from '../../store';
import { setAdminAuth, setAdminNeedAuth } from './actions';

import Orders from './orders/container';
import BaseUpdate from './update/container';
import Settings from './settings/container';
import Services from './services/container';
import Crosses from './crosses/container';
import Reports from './reports/container';


const panes = [
  { menuItem: {key: 'orders', content: 'Заказы', icon: 'clipboard', },
    render: () => <Tab.Pane attached={false}><Orders /></Tab.Pane> },
  { menuItem: {key: 'update', content: 'Обновление', icon: 'upload',},
    render: () => <Tab.Pane attached={false}><BaseUpdate /></Tab.Pane> },
  { menuItem: {key: 'settings', content: 'Настройки', icon: 'settings',},
    render: () => <Tab.Pane attached={false}><Settings /></Tab.Pane> },
  { menuItem: {key: 'service', content: 'Сервисы', icon: 'toggle on',},
    render: () => <Tab.Pane attached={false}><Services /></Tab.Pane> },
  { menuItem: {key: 'crosses', content: 'Доп. кроссы', icon: 'edit',},
    render: () => <Tab.Pane attached={false}><Crosses /></Tab.Pane> },
  { menuItem: {key: 'reports', content: 'Отчёты', icon: 'clipboard',},
    render: () => <Tab.Pane attached={false}><Reports /></Tab.Pane> },
]


class AdminApp extends Component {

  componentDidMount() {
    setTimeout(() => {this.vkLogin()}, 1000);
    ws.doSend({
      command: 'getOrderStates',
      params: {}
    });
  };

  checkLoginState = response => {
    if (response.status === 'connected') {
      store.dispatch(setAdminAuth(response))
      ws.doSend({
        command: 'vkLogin',
        params: {response}
      })
    }
  };

  vkLogout = () => {
    store.dispatch(setAdminAuth({}));
    store.dispatch(setAdminNeedAuth(true))
    window.VK.Auth.logout();
  };

  vkLogin = () => {
    window.VK.Auth.login(this.checkLoginState);
  };

  render () {
    return (
      <AdminAppView panes={panes}
        need_auth={this.props.data.need_auth}
        vkLogin={this.vkLogin}
        vkLogout={this.vkLogout}
        // eslint-disable-next-line
        user={this.props.data.auth.session && this.props.data.auth.session.user || {}} />

    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AdminAppState.AdminAppData
  }
}


export default connect(mapStateToProps)(AdminApp);
