import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid } from 'semantic-ui-react'

import AttributeList from './attributelist/container';
import SearchResult from './searchresult/container';

import store from '../../../store';
import * as ws from '../../../api/connections';
import {  setSearchResultRows,
          setSearchResultTotalPages,
          setSearchResultActivePage } from '../categorysearch/searchresult/actions';


class CategorySearch extends Component {

  componentDidMount = () => {
    ws.doSend({
      command: 'getAdditionalGroups',
      params: {}
    });
    ws.doSend({command: 'getDetailAttributes'});
    store.dispatch(setSearchResultRows([]));
    store.dispatch(setSearchResultTotalPages(1));
    store.dispatch(setSearchResultActivePage(1));
  }

  render () {
    return (
      <div style={{height: this.props.height}}>
        <Grid divided>
          <Grid.Row>
            <Grid.Column
              width={// eslint-disable-next-line
                this.props.data.hide_attribute_list && 1 || 4}>
              <AttributeList visible={!this.props.data.hide_attribute_list} />
            </Grid.Column>
            <Grid.Column
              width={// eslint-disable-next-line
                this.props.data.hide_attribute_list && 15 || 12}>
              <SearchResult height={38} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.AppState.AppData,
  }
}

export default connect(mapStateToProps)(CategorySearch);
