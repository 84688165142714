import React, { Component } from 'react';
import { connect } from 'react-redux';

import SearchEngineView from './component';

import store from '../../../../store';
import * as ws from '../../../../api/connections';
import { setSearchResultRows, setSearchQuickSearchOptions,
         setSearchResultInProgress, setSearchResultFilter } from '../../categorysearch/searchresult/actions';

class SearchEngine extends Component {
  state = {
      popup_open: false,
      valueInput: ''
  };

  _onChange = (e, {searchQuery}) => {
    store.dispatch(setSearchResultRows([]));
    this.setState({
      valueInput: searchQuery,
      popup_open: searchQuery.length > 0 ? true : false})

    if (searchQuery.split(' ').length === 1 && searchQuery.length >= 3 && isNaN(searchQuery)) {
      ws.doSend({
        command: 'quickSearch',
        params: {
          key: searchQuery
        }
      })
    }

    if (searchQuery.length < 3) {
      store.dispatch(setSearchQuickSearchOptions([]));
    }
  };

  _onItemChange = (e, {value}) => {
    store.dispatch(setSearchQuickSearchOptions([]));
    this.setState({valueInput: value, popup_open: true})
  };


  _searchClick = () => {
    this.setState({popup_open: false});
    store.dispatch(setSearchResultFilter(''))
    store.dispatch(setSearchQuickSearchOptions([]));

    store.dispatch(setSearchResultInProgress(true));
    setTimeout(() => {setSearchResultInProgress(false)}, 30000);

    ws.doSend({
      command: 'getFullSearchByKeys',
      params: {
        keys: this.state.valueInput.split(' ')
      }
    });
  };

  _formatText = (text, keys) => {
    const new_keys = [...keys];
    const key = new_keys.shift();
    return (
      <span>
        {text.split(key).map((item, i) => (
          <span>
            { // eslint-disable-next-line
              new_keys.length && this._formatText(item, new_keys) || item}
            {i < (text.split(key).length - 1)
              &&  <span style={{color: 'teal'}}>
                    {key}
                  </span>
            }
          </span>
        ))}
      </span>)
  };

  _customFilterOptions = (options, query) => options
    .filter(item => item.text.indexOf(query.toUpperCase().split(' ')[0] !== -1));

  _searchOptions = () => {
    var { valueInput } = this.state;

    valueInput = valueInput.toUpperCase().split(' ').filter(item => item);

    if (valueInput.length > 0) {
      var items = this.props.data.search_options
        .filter(item => item.text.indexOf(valueInput[0]) !== -1)
        .filter(item => !valueInput[1] || item.text.indexOf(valueInput[1]) !== -1)
        .map(item => {
          item.content = this._formatText(item.text, valueInput);
          return item;
        });
    } else {
      items = this.props.data.search_options;
    }

    return this.state.valueInput
      // eslint-disable-next-line
      &&  [...[{key: '0', value: this.state.valueInput, text: this.state.valueInput}],
           ...items]
      // eslint-disable-next-line
      || items;
  };

  render () {
    return (
      <SearchEngineView
        value={this.state.valueInput}

        onChange={this._onChange}
        onItemChange={this._onItemChange}
        searchClick={this._searchClick}

        popup_open={this.state.popup_open}

        customFilterOptions={this._customFilterOptions}
        search_options={this._searchOptions()} />
    )
  }
}

const mapStateToProps = function(store) {
  return {
    data: store.SearchResultState.SearchResultData
  }
}


export default connect(mapStateToProps)(SearchEngine);
