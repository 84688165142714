import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tab } from 'semantic-ui-react';

// import * as ws from '../../api/connections';
import store from '../../store';
import { setRulesPaymentOpen } from './actions';

import RulesPaymentView from './component';


class RulesPayment extends Component {

  _panes =
    [
      {
        menuItem: { key: '', icon: 'hand point right', content: 'Договор-оферта' },
        render: () => <Tab.Pane attached={false}>{this.props.data.oferta}</Tab.Pane>,
      },
      {
        menuItem: { key: 'payments', icon: 'money', content: 'Способы оплаты' },
        render: () => <Tab.Pane attached={false}>{this.props.data.payments}</Tab.Pane>,
      },
      {
        menuItem: { key: 'delivery', icon: 'truck', content: 'Доставка' },
        render: () => <Tab.Pane attached={false}>{this.props.data.delivery}</Tab.Pane>,
      },
      {
        menuItem: { key: 'safety', icon: 'lock', content: 'Конфиденциальность' },
        render: () => <Tab.Pane attached={false}>{this.props.data.safety}</Tab.Pane>,
      }, ];


  _onClose = () => {
    store.dispatch(setRulesPaymentOpen(false));
  };



  render () {
    return (
      <RulesPaymentView
        open={this.props.data.open}
        text={this.props.data.text}
        onClose={this._onClose}

        panes={this._panes}
        started={this.props.started}
      />
    )
  }
}


const mapStateToProps = function(store) {
  return {
    data: store.RulesPaymentState.RulesPaymentData
  }
}


export default connect(mapStateToProps)(RulesPayment);
