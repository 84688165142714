import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalReviewView from './component';

import store from '../../store';
import * as ws from '../../api/connections';
import { setModalReviewOpen, setModalReviewState } from './actions';


class ModalReview extends Component {

  _onClose = () => {
    store.dispatch(setModalReviewOpen(false));
    store.dispatch(setModalReviewState('fill'));
  };

  _onSend = review => {
    ws.doSend({
      command: 'sendReview',
      params: {
        review,
      }
    });

    store.dispatch(setModalReviewState('send'));

    setTimeout(() => {
      if (this.props.data.state === 'send') {
        store.dispatch(setModalReviewState('error'));
      }
    }, 30000)
  };

  render() {
    return (
      <ModalReviewView open={this.props.data.open} state={this.props.data.state}
        onClose={this._onClose}
        onSend={this._onSend} />
    );
  }
};


const mapStateProps = store => ({
  data: store.ModalReviewState.ModalReviewData,
});


export default connect(mapStateProps)(ModalReview);
